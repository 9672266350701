import React, { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	Box,
	List,
	Typography,
	Avatar,
	ListItem,
	FormControlLabel,
	Checkbox,
	TableContainer,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Tooltip,
	Link,
	Badge,
	Chip,
	Switch,
	TextField,
	InputAdornment,
	Popper,
	Paper,
	ClickAwayListener,
	MenuList,
	MenuItem,
	ListItemAvatar,
	ListItemText,
	Grow,
	Button,
} from '@material-ui/core';
import { getMonthDate, getMonthDateYear, getUserDetails, getUserName } from '../../../config/utils';
import { getLocalStorageItem } from '../../../services/StorageService';
import {
	CrossIcon,
	DownloadIcon,
	NewFilterIcon,
	Perform360Icon,
	RecommendedIcon,
	ReportsTooltipIcon,
	SearchIcon,
	SortingIcon,
	TickIcon,
} from '../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../Common/OKRButton';
import { useTranslation } from 'react-i18next';
import { ConversationIcon } from '../../../config/svg/CommonSvg';
import { getOkrMasterData } from '../../../action/myGoal';
import { getTeamReviewUserList, getGoalAlertConversation } from '../../../action/adminSettings';
import { Enums } from '../../../config/enums';
import { TeamReviewStats } from './TeamReviewStats';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import YetToBeginImage from '../../../images/assessment-yet-to-begin.svg';
import { NoRecord } from '../../Admin/CommonComponent/NoRecord';
import NoDataImg from '../../../images/my-goal-bg.svg';
import LinearLoader from '../../Common/Loader';
import { ReviewConversationsSettings } from '../ReviewConversationsSettings';
import { HeaderTooltip } from '../../Reports/HeaderTooltip';
import { TeamReviewData } from './TeamReviewData';
import { BorderCheckboxIcon, CheckedIcon } from '../../../config/svg/formElementIcons';
import { DownArrowIcon, UpArrowIcon } from '../../../config/svg/ArrowSvg';
import { CancelIcon } from '../../../config/svg/Action';
import { TeamReviewFilterList } from '../../../config/constant';

export const TeamReview = (props: any) => {
	const { setImageList, imageList, uploadCase, activeStep, cycleData, drUserData, fromTeamDetails } = props;
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	let userDetail = getUserDetails();
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const { currentGoalReviewCycle } = useSelector((state: any) => state.adminSettingReducer);
	const [teamReviewUserListResponse, setTeamReviewUserListResponse] = useState<any>({});
	const [teamReviewUserList, setTeamReviewUserList] = useState<any>([]);
	const [reviewWorkFlowApprovalList, setReviewWorkFlowApprovalList] = useState<any>([]);
	const [teamsReviewHeaderResponse, setTeamsReviewHeaderResponse] = useState<any>(null);
	const [loader, setLoader] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [searchTextArray, setSearchTextArray] = useState<string>('');
	const [isMyActionable, setIsMyActionable] = useState<boolean>(false);
	const [isPublished, setIsPublished] = useState<boolean>(false);
	const [performanceGoalReviewWorkFlowTypeId, setPerformanceGoalReviewWorkFlowTypeId] = useState<any>(0);
	const [sortingData, setSortingData] = useState<any>({ column: 'firstName', order: 'asc' });
	const [performanceGoalReviewCycleId, setPerformanceGoalReviewCycleId] = useState<number>(0);
	const [categorySettingsOpen, setCategorySettingsOpen] = useState<any>({ open: false });
	const [currentTab, setCurrentTab] = useState<string>('');
	const [openReviewApproval, setOpenReviewApproval] = useState<boolean>(false);
	const [anchorElReviewApproval, setAnchorElReviewApproval] = useState<HTMLElement | null>(null);

	const [openFilter, setOpenFilter] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [appliedFilter, setAppliedFilter] = useState<boolean>(false);
	const [localFilterCondition, setLocalFilterCondition] = useState<any>([]);
	const [finalFilterCondition, setFinalFilterCondition] = useState<any>([]);

	useEffect(() => {
		window.scroll({ top: 0, left: 0, behavior: 'smooth' });
	}, []);

	useEffect(() => {
		if (currentGoalReviewCycle && currentGoalReviewCycle?.performanceGoalReviewCycleId) {
			let performanceGoalReviewCycleId =
				currentGoalReviewCycle && currentGoalReviewCycle.performanceGoalReviewCycleId
					? currentGoalReviewCycle.performanceGoalReviewCycleId
					: 0;

			setPerformanceGoalReviewCycleId(performanceGoalReviewCycleId);
			let requestParam: any = {
				performanceGoalReviewCycleId: performanceGoalReviewCycleId,
				sortingText: sortingData.column,
				order: sortingData.order,
				finder: searchTextArray,
				isMyActionable: isMyActionable,
				performanceGoalReviewWorkFlowTypeId: performanceGoalReviewWorkFlowTypeId,
				isPublished: isPublished,
				filterList: finalFilterCondition && finalFilterCondition.map((item: any) => item.subCategoryId),
			};
			getTeamReviewUserListDetails(requestParam);
		}
	}, [
		currentGoalReviewCycle,
		searchTextArray,
		isMyActionable,
		performanceGoalReviewWorkFlowTypeId,
		isPublished,
		finalFilterCondition,
	]);

	const handleToggleFilter = (event: any) => {
		setAnchorEl(event.currentTarget);
		setOpenFilter((prev) => !prev);
	};

	const handleClose = (event: any) => {
		setAnchorEl(null);
		setOpenFilter(false);
	};

	const clearFilter = () => {
		setLocalFilterCondition([]);
		setFinalFilterCondition([]);
	};

	const handleFilterChanged = (value: number) => {
		let filterData: any = [...localFilterCondition];
		let indexParent = filterData.indexOf(value);
		if (indexParent === -1) {
			filterData.push(value);
		} else {
			filterData.splice(indexParent, 1);
		}
		setLocalFilterCondition(filterData);
	};

	const handleCloseChip = (value: any) => {
		let filterData: any = [...localFilterCondition];
		let indexParent = filterData.indexOf(value.subCategoryId);
		if (indexParent !== -1) {
			filterData.splice(indexParent, 1);
		}
		setLocalFilterCondition(filterData);
		handleApplyFilterClick(filterData);
	};
	const handleApplyFilterClick = (filtersData: any) => {
		let filters: any = [];
		TeamReviewFilterList.map((category: any, index: number) => {
			category.subCategories.map((subCategory: any, index: number) => {
				if (filtersData && filtersData.indexOf(subCategory.subCategoryId) !== -1) {
					filters.push({
						...subCategory,
					});
				}
			});
		});
		setAppliedFilter(true);
		setFinalFilterCondition([...filters]);
	};

	const getTeamReviewUserListDetails = async (requestPayload: any) => {
		try {
			setLoader(true);
			const response: any = await dispatch(getTeamReviewUserList(requestPayload));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				if (response?.data) {
					const { entity } = response.data;
					setTeamReviewUserListResponse(entity);
					const { reviewEmployeeDetailsResponse, reviewWorkFlowApprovalResponse, teamsReviewHeaderResponse } = entity;
					setTeamReviewUserList(reviewEmployeeDetailsResponse);
					setReviewWorkFlowApprovalList(reviewWorkFlowApprovalResponse);
					setTeamsReviewHeaderResponse(teamsReviewHeaderResponse);
				} else {
					setTeamReviewUserListResponse(null);
					setTeamReviewUserList([]);
				}
				setLoader(false);
			} else {
				setLoader(false);
			}
		} catch (error) {}
	};

	const sortList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingData.column) {
			newSortOrder = sortingData.order === 'asc' ? 'desc' : 'asc';
		}
		setSortingData({ column: column, order: newSortOrder });
		getTeamReviewUserListDetails({
			performanceGoalReviewCycleId: performanceGoalReviewCycleId,
			sortingText: column,
			order: newSortOrder,
			finder: searchTextArray,
			isMyActionable: isMyActionable,
			performanceGoalReviewWorkFlowTypeId: performanceGoalReviewWorkFlowTypeId,
			isPublished: isPublished,
		});
	};

	const handleHeaderFilter = (workFlowTypeId: any, published: boolean) => {
		setPerformanceGoalReviewWorkFlowTypeId(workFlowTypeId === performanceGoalReviewWorkFlowTypeId ? 0 : workFlowTypeId);
		setIsPublished(published && isPublished ? false : published);
	};

	const handleIsMyActionableOnly = (e: any) => {
		const { checked } = e?.target;
		setLoader(true);
		setIsMyActionable(checked);
	};

	const handleSearchChange = (e: any) => {
		setSearchValue(e.target.value);
	};

	const searchClicked = (e: any) => {
		if (e.key === 'Enter') {
			if (e.target.value && e.target.value.trim() !== '') {
				handleSearch(e.target.value);
			}
			setSearchValue('');
		}
	};

	const handleSearch = (value: any) => {
		setSearchTextArray(value);
		setLoader(true);
	};

	const clearSearch = () => {
		setSearchTextArray('');
	};

	const handleSettingsDrawerOpen = (event: any, usersDetails: any, tabToOpen?: string) => {
		setCategorySettingsOpen({ open: true, data: usersDetails });
		if (tabToOpen) {
			setCurrentTab(tabToOpen);
		}
	};

	const handleSettingsDrawerClose = (event: any) => {
		event.preventDefault();
		event.stopPropagation();
		setCategorySettingsOpen({ open: false, data: '' });
		if (currentTab === 'conversation') {
			//getUserPerformanceGoalsList();
			dispatch(getGoalAlertConversation());
		}
	};

	const handleReviewApprovalOpen = (event: any) => {
		setAnchorElReviewApproval(event.currentTarget);
		setOpenReviewApproval(true);
	};
	const handleReviewApprovalClose = () => {
		setOpenReviewApproval(false);
		setAnchorElReviewApproval(null);
	};

	return (
		<>
			{loader && <LinearLoader />}

			{currentGoalReviewCycle === null ? (
				<Box className='welcome-content goals-review-welcome' id='performance-goals-welcome'>
					<Box textAlign='center' className='pad-container'>
						<Typography variant='h2'>{t('teamReviewYetToBeginTitle')}</Typography>
						<Typography>{t('teamReviewYetToBeginSubTitle')}</Typography>
						<AnimatedIcon
							className=''
							width='388'
							type='image/svg+xml'
							alt='WelcomeImage'
							imagePath={YetToBeginImage}
						/>
					</Box>
				</Box>
			) : teamReviewUserListResponse && !teamReviewUserListResponse.isUsersAssigned ? (
				<Box className='welcome-content goals-review-welcome' id='performance-goals-welcome'>
					<Box textAlign='center' className='pad-container'>
						<Typography variant='h2'>{t('teamReviewNoDRAssignedForThisCycleTitle')}</Typography>
						<Typography>{t('teamReviewNoDRAssignedForThisCycleSubTitle')}</Typography>
						<AnimatedIcon
							className=''
							width='388'
							type='image/svg+xml'
							alt='WelcomeImage'
							imagePath={YetToBeginImage}
						/>
					</Box>
				</Box>
			) : (
				<>
					<Box className='goals-review-stats-area' id='goals-team-review-stats-area'>
						<TeamReviewStats
							t={t}
							teamsReviewHeaderResponse={teamsReviewHeaderResponse}
							teamReviewUserListResponse={teamReviewUserListResponse}
							handleHeaderFilter={handleHeaderFilter}
							performanceGoalReviewWorkFlowTypeId={performanceGoalReviewWorkFlowTypeId}
							isPublished={isPublished}
						/>
					</Box>
					<Box className='goal-team-review-area'>
						<Box className='team-review-listing-header'>
							<Box className='team-review-listing-header-left'>
								<Box className='my-actionable-switch'>
									<FormControlLabel
										control={
											<Switch
												id='my-actionable'
												name='myActionable'
												checked={Boolean(isMyActionable)}
												onChange={handleIsMyActionableOnly}
												inputProps={{ 'aria-label': 'controlled' }}
											/>
										}
										label={`${t('myActionableLevel')} (${teamReviewUserListResponse.myActionableCount})`}
									/>
								</Box>
							</Box>
							<Box className='team-review-listing-header-right'>
								<Box className='filter-list-dropdown'>
									<OKRButton
										className={`list-filter-btn ${openFilter ? 'active' : ''}`}
										icon={<NewFilterIcon />}
										text={
											<span className='filter-txt'>
												{t('filterLabel')}
												{openFilter ? <UpArrowIcon /> : <DownArrowIcon />}
											</span>
										}
										handleClick={handleToggleFilter}
										id='dashboard-filter-btn'
									/>
									<Box className={`filter-chip `}>
										{appliedFilter && (
											<List>
												{finalFilterCondition && finalFilterCondition.length > 0 ? (
													<>
														{finalFilterCondition.slice(0, 1).map((item: any, index: any) => (
															<ListItem key={index}>
																<Tooltip arrow title={item.subCategoryName}>
																	<Typography variant='body2'>{item.subCategoryName}</Typography>
																</Tooltip>
																<Button className='cancel-btn' onClick={() => handleCloseChip(item)}>
																	<CancelIcon />
																</Button>
															</ListItem>
														))}
														{finalFilterCondition.length > 1 && (
															<ListItem className='more-filter-count'>
																<Typography variant='body2'>+{finalFilterCondition.length - 1}</Typography>
															</ListItem>
														)}

														<ListItem className='clear-all'>
															<Link onClick={clearFilter}>{t('clearAll')}</Link>
														</ListItem>
													</>
												) : (
													<></>
												)}
											</List>
										)}
									</Box>
									<Popper
										className={`filter-list-popup`}
										open={openFilter}
										anchorEl={anchorEl}
										id='filtersPopper'
										placement={'bottom-start'}
										modifiers={{
											flip: {
												enabled: false,
											},
										}}
									>
										{({ TransitionProps }) => (
											<Grow {...TransitionProps}>
												<ClickAwayListener onClickAway={handleClose}>
													<Paper>
														<Box className='list-filter-wrap'>
															<Box className='list-filter-wrap-inner'>
																{TeamReviewFilterList &&
																	TeamReviewFilterList.length > 0 &&
																	TeamReviewFilterList?.map((category: any) => (
																		<>
																			<Box className='task-priority-filter'>
																				<Typography variant='body2'>{category.categoryName}</Typography>
																				<List>
																					{category.subCategories.map((subCategory: any, index: number) => {
																						return (
																							<ListItem key={`type-filter-date-list-${subCategory.subCategoryId}`}>
																								<FormControlLabel
																									control={
																										<Checkbox
																											icon={<BorderCheckboxIcon />}
																											checkedIcon={<CheckedIcon />}
																											checked={
																												localFilterCondition &&
																												localFilterCondition.includes(subCategory.subCategoryId)
																											}
																											value={subCategory.subCategoryId}
																											onChange={() => {
																												handleFilterChanged(subCategory.subCategoryId);
																											}}
																											color='primary'
																										/>
																									}
																									label={subCategory.subCategoryName}
																								/>
																							</ListItem>
																						);
																					})}
																				</List>
																			</Box>
																		</>
																	))}
															</Box>
															<Box className='filter-list-actions'>
																<OKRButton
																	className='apply-btn btn-primary'
																	text={t('applyLabel')}
																	handleClick={(e: any) => {
																		handleClose(e);
																		handleApplyFilterClick(localFilterCondition);
																	}}
																/>
															</Box>
														</Box>
													</Paper>
												</ClickAwayListener>
											</Grow>
										)}
									</Popper>
								</Box>
								<Box className='user-search'>
									<TextField
										className='search-text-field'
										id='user-src-field'
										placeholder={t('searchNameOrTeam')}
										fullWidth
										value={searchValue}
										onChange={(e) => handleSearchChange(e)}
										onKeyDown={(e) => searchClicked(e)}
										InputProps={{
											startAdornment: (
												<InputAdornment position='start'>
													<SearchIcon />
												</InputAdornment>
											),
										}}
									/>
									{searchTextArray && (
										<Box className='user-search-chip'>
											<List>
												<ListItem>
													{searchTextArray.length > 20 ? (
														<Tooltip title={searchTextArray} arrow>
															<Chip
																label={searchTextArray.substring(0, 20) + '...'}
																onDelete={clearSearch}
																deleteIcon={
																	<span>
																		<CrossIcon />
																	</span>
																}
															/>
														</Tooltip>
													) : (
														<Chip
															label={searchTextArray}
															onDelete={clearSearch}
															deleteIcon={
																<span>
																	<CrossIcon />
																</span>
															}
														/>
													)}
												</ListItem>
											</List>
										</Box>
									)}
								</Box>
							</Box>
						</Box>
						<TableContainer className='team-review-table-listing'>
							{teamReviewUserList && teamReviewUserList?.length > 0 ? (
								<Table>
									<TableHead>
										<TableRow>
											<TableCell align='center' id={`team-review-col-1`} className='user-col1'>
												<Box className='cell-wrap'>
													<Typography
														onClick={(e) => sortList(e, 'firstName')}
														variant='body2'
														className={
															sortingData.column === 'firstName' ? `sort-title ${sortingData.order}` : 'sort-title asc'
														}
														id={'name'}
													>
														<span className='sortable-label'>
															<span className='txt-wrap'>{t('nameLabel')}</span> <SortingIcon />
														</span>
													</Typography>
												</Box>
											</TableCell>
											<TableCell align='center' id={`team-review-col-2`} className='user-col2'>
												<Box className='cell-wrap'>
													<Typography
														variant='body2'
														onClick={(e) => sortList(e, 'teamName')}
														className={
															sortingData.column === 'teamName' ? `sort-title ${sortingData.order}` : 'sort-title asc'
														}
														id={'team'}
													>
														<span className='sortable-label'>
															<span className='txt-wrap'>{t('teamLabel')}</span> <SortingIcon />
														</span>
													</Typography>
												</Box>
											</TableCell>
											<TableCell align='center' id={`team-review-col-3`} className='user-col3'>
												<Box className='cell-wrap'>
													<Typography
														variant='body2'
														onClick={(e) => sortList(e, 'selfReviewStatus')}
														className={
															sortingData.column === 'selfReviewStatus'
																? `sort-title ${sortingData.order}`
																: 'sort-title asc'
														}
														id={'self-review'}
													>
														<span className='sortable-label'>
															<span className='txt-wrap'>{t('selfReviewLevel')}</span> <SortingIcon />
														</span>
													</Typography>
												</Box>
											</TableCell>
											<TableCell align='center' id={`team-review-col-4`} className='user-col4'>
												<Box className='cell-wrap'>
													<Typography
														variant='body2'
														onClick={(e) => sortList(e, 'statusName')}
														className={
															sortingData.column === 'statusName' ? `sort-title ${sortingData.order}` : 'sort-title asc'
														}
														id={'manager-review'}
													>
														<span className='sortable-label'>
															<span className='txt-wrap'>{t('managerReviewLevel')}</span> <SortingIcon />
														</span>
													</Typography>
												</Box>
											</TableCell>
											<TableCell align='center' id={`team-review-col-5`} className='user-col5'>
												<Box className='cell-wrap'>
													<Typography
														variant='body2'
														onClick={(e) => sortList(e, 'performanceGoalReviewWorkFlowType')}
														className={
															sortingData.column === 'performanceGoalReviewWorkFlowType'
																? `sort-title ${sortingData.order}`
																: 'sort-title asc'
														}
														id={'review-status'}
													>
														<span className='sortable-label'>
															<span className='txt-wrap'>{t('reviewStatusLabel')}</span> <SortingIcon />
														</span>
													</Typography>
													<OKRButton
														className='info-icon-btn'
														id={'my-team-prg-tooltip'}
														icon={<ReportsTooltipIcon />}
														handleClick={handleReviewApprovalOpen}
													/>
													<Popper
														className={`review-status-info-tooltip`}
														open={openReviewApproval}
														anchorEl={anchorElReviewApproval}
														transition
														placement={'bottom-start'}
													>
														<ClickAwayListener onClickAway={handleReviewApprovalClose}>
															<Box className='textarea-expand'>
																<Typography variant='body2'>{t('reviewApprovalsSequence')}</Typography>
																<Box className='review-approval-sequence-list '>
																	{reviewWorkFlowApprovalList &&
																		reviewWorkFlowApprovalList.length &&
																		reviewWorkFlowApprovalList.map((item: any, index: any) => (
																			<Box className='review-approval-sequence'>
																				<Typography className='review-approval-sequence-index'>
																					{item.workFlowName}
																				</Typography>
																			</Box>
																		))}
																</Box>
																<Box className='review-approval-sequence-content'>
																	<Typography variant='body2' className='font-weight-normal'>
																		{t('reviewApprovalsSequenceLineOne')}
																	</Typography>
																	<Typography variant='body2' className='font-weight-normal'>
																		{t('reviewApprovalsSequenceLineTwo')}
																	</Typography>
																</Box>
															</Box>
														</ClickAwayListener>
													</Popper>
													{/* <HeaderTooltip
														infoTooltipClass='review-status-info-tooltip'
														tooltipText={
															<Box className='review-approvals-seq'>
																<Typography variant='body2'>Review Approvals Sequence</Typography>
															</Box>
														}
														btnId={'my-team-prg-tooltip'}
													/> */}
												</Box>
											</TableCell>
											<TableCell align='center' id={`team-review-col-6`} className='user-col6'></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{teamReviewUserList &&
											teamReviewUserList.map((users: any, index: any) => (
												<>
													<TeamReviewData
														{...props}
														users={users}
														t={t}
														handleSettingsDrawerOpen={handleSettingsDrawerOpen}
													/>
												</>
											))}
									</TableBody>
								</Table>
							) : (
								<>
									<NoRecord
										noRecordMessage={t('teamReviewBlankUserListTitle')}
										subHead={t('teamReviewBlankUserListSubTitle')}
										noDataImgWidth={'516'}
										noImg={NoDataImg}
										{...props}
									/>
								</>
							)}
						</TableContainer>
					</Box>
				</>
			)}
			{categorySettingsOpen.open && (
				<ReviewConversationsSettings
					{...props}
					t={t}
					categorySettingsOpen={categorySettingsOpen}
					handleSettingsDrawerClose={handleSettingsDrawerClose}
					selectTab={currentTab}
					teamReviewUserListResponse={teamReviewUserListResponse}
				/>
			)}
		</>
	);
};
