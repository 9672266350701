import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormLabel, Typography, TextField, FormControl } from '@material-ui/core';
import { OKRButton } from '../OKRButton';
import { useTranslation } from 'react-i18next';
import { AddIconSvg, EditIcon } from '../../../config/svg/CommonSvg';
import { formatDate, getCurrencyIcon, getFullName } from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { AddEditContributorDetail } from '../../Goals/TeamsKr/AddEditContributorDetail';
import { ManageContributorList } from '../../Goals/TeamsKr/ManageContributorList';
import { getLocalStorageItem } from '../../../services/StorageService';
import { useSnackbar } from 'notistack';
import { TeamsKr } from '../../Goals/TeamsKr/TeamsKr';
import { UserAvatarGroup } from '../UserAvatarGroup';
import moment from 'moment';
import AlertDialog from '../Dialog';
import { IndividualKr } from '../../Goals/IndividualKr/IndividualKr';
import { assignOkr } from '../../Admin/AdminConstant';
import { checkPermission } from '../../../config/rolePermission';

export const AssignContributors: React.FC<any> = (props) => {
	const {
		keyData,
		kr,
		okr,
		okrMasterData,
		durationCycleDetail,
		keyAttribute, //startDate, dueDate, keyDescription, teamId, contributors, currencyId, startValue, targetValue, goalKeyId, metricId
		objectiveDetail, //objectiveName
		year,
		goalTypeId,
		isContributorOnly,
		actionType,
		selectedMetricDetail,
		objectiveStartDate,
		objectiveEndDate,
		updateKeyAttrDetail,
		setIsContributorUpdated,
		deleteContributor,
		contributorDetails,
		setContributorDetails,
		checkAlignStatus,
		parentGoalTypeId,
		setCallOut,
		anchorElCallouts,
		setAnchorElCallouts,
		initialCallout,
		setShowAssignKrDateCallouts,
		showAssignKrDateCallouts,
		setShowAssignKrTargetCallouts,
		showAssignKrTargetCallouts,
		setShowAssignKrTitleCallouts,
		showAssignKrTitleCallouts,
		calloutsData,
		getPermissionResult,
		resetTeamListData,
		isBecomeContributor,
		checkTargetValue,
		isLocked,
	} = props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const contributorObject = {
		employeeId: Enums.ZERO,
		designation: '',
		emailId: '',
		firstName: '',
		lastName: '',
		fullName: '',
		imagePath: '',
		krStatusId: Enums.TWO,
		krStatusName: t('acceptedOkr'),
		assignmentTypeId: okr.goalTypeId === 1 ? 2 : 1,
		goalStatusId: Enums.TWO,
		goalTypeId: okr.goalTypeId || 2,
		keyResult: keyData?.keyDescription || keyAttribute?.keyDescription || '',
		score: Enums.ZERO,
		startDate: keyData.startDate || keyAttribute?.startDate,
		dueDate: keyData.dueDate || keyAttribute?.dueDate,
		startValue: keyAttribute?.startValue || Enums.ZERO,
		targetValue: keyAttribute?.targetValue || Enums.ZERO,
		krAssignerMessage: '',
		metricName: '',
		currencyName: '',
		objectiveName: actionType === 'editKR' ? okr.objectiveName : objectiveDetail?.objectiveName,
		cycleId: props.userSelectedCycle?.organisationCycleId || 0,
		year,
		isAddedNew: false,
		isActive: true,
	};
	const staticModalProps: any = {
		open: false,
		type: '',
		message: '',
		module: '',
		event: null,
		data: { metricId: '', type: '', date: '' },
	};
	const [modalProps, setModalProps] = useState<any>(staticModalProps);
	const [hideDefaultButton, setHideDefaultButton] = useState<boolean>(false);
	const [addContributorAnchorEl, setAddContributorAnchorEl] = useState<HTMLElement | null>(null);
	const [addContributorOpen, setAddContributorOpen] = useState<boolean>(false);
	const [selectedContributorDetails, setSelectedContributorDetails] = useState<any>(contributorObject);
	const [showTeamsOkr, setShowTeamsOkr] = useState<boolean>(false);
	// const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const [isButtonDisable, setIsButtonDisable] = useState<boolean>(false);
	const [isListEnabled, setIsListEnabled] = useState<boolean>(false);
	const [selectedValues, setSelectedValues] = React.useState<any>([]);
	const [treeNodesData, setTreeNodesData] = React.useState<any>(null);
	const [teamsLoading, setTeamsLoading] = useState<boolean>(true);
	const [totalCount, setTotalCount] = useState<number>(0);
	const [completeOrgData, setCompleteOrgData] = React.useState<any[]>([]);
	const [teamDetails, setTeamDetails] = useState<any>(null);
	const [disabledNodes, setDisabledNodes] = React.useState<any>([]);
	const [deletedContributor, setDeletedContributor] = React.useState<any>({});
	const [currentScreen, setCurrentScreen] = useState<number>(0);
	const [selectedUsersData, setSelectedUsersData] = useState<any[]>([]);
	const [selectedTeamIds, setSelectedTeamIds] = useState<any[]>([]);
	const [openScreen, setOpenScreen] = useState<number>(0);
	const [isDeleteInProgress, setIsDeleteInProgress] = useState<boolean>(false);

	useEffect(() => {
		if (actionType === 'editKR') {
			setSelectedContributorDetails({
				...selectedContributorDetails,
				objectiveName: okr.objectiveName,
				goalStatusId: okr.goalStatusId || 2,
				goalTypeId: okr.goalTypeId || 2,
				assignmentTypeId: okr.goalTypeId === 1 ? 2 : 1,
				cycleId: props.userSelectedCycle?.organisationCycleId || 0,
			});
		}
	}, [okr]);

	useEffect(() => {
		if (props.isAddContributor) {
			props.setIsAddContributor(false);
			onHandleShowAllContributors({});
		}
	}, []);

	useEffect(() => {
		if (resetTeamListData) {
			setCompleteOrgData([]);
		}
	}, [resetTeamListData]);

	const onHandleAddUserClick = (event: any, isUnderAssignedAlertBack: any = false, isSelectedTeamIds: any = false) => {
		if (goalTypeId === 2 || isContributorOnly === true) {
			if (!isUnderAssignedAlertBack) {
				prepareIndividualData();
			}
			// setCustomStyle(false);
			setAddContributorAnchorEl(addContributorAnchorEl ? null : event.currentTarget);
			setAddContributorOpen(true);
		} else {
			setShowTeamsOkr(true);
			if (!isSelectedTeamIds) {
				callTreeNodes(event);
			}
		}
	};
	const getMinDate = (date: any, okrDate: any) => {
		const datesDifferenceInDays = moment(new Date(okrDate)).isAfter(new Date(formatDate(date) || new Date()), 'days');
		return datesDifferenceInDays ? okrDate : date;
	};

	const getMetricCurrencyIcon = (value: any) => {
		let component: string = '';
		if (selectedMetricDetail.selectedMetric.name === 'Currency') {
			component =
				(selectedMetricDetail.selectedCurrencyDetail.symbol
					? selectedMetricDetail.selectedCurrencyDetail.symbol
					: 'Dollar') +
				'' +
				value;
		} else if (selectedMetricDetail.selectedMetric.name === 'Percentage') {
			component = value + '%';
		} else if (selectedMetricDetail.selectedMetric.name === 'Numbers') {
			component = '#' + value;
		} else if (
			selectedMetricDetail.selectedMetric.metricId === 4 ||
			selectedMetricDetail.selectedMetric.metricId === 5
		) {
			component = selectedMetricDetail.selectedMetric.description + ' ' + value;
		}
		return component;
	};

	const callTreeNodes = (event: any, scrollerId = '') => {
		getUserTeams(event, scrollerId);
	};

	const scrollTo = (e: any) => {
		setTimeout(function () {
			let element = document.getElementById(e);
			if (element) {
				element.scrollIntoView({ behavior: 'smooth' });
			}
		}, 100);
	};
	const prepareIndividualData = () => {
		let contributorsList: any[] = [];
		let selVal: any[] = [];
		contributorDetails.forEach((childData: any) => {
			selVal.push(childData.employeeId);
			contributorsList.push({
				id: childData.employeeId,
				employeeId: childData.employeeId,
				fullName: childData.firstName + ' ' + childData.lastName,
				firstName: childData.firstName,
				lastName: childData.lastName,
				emailId: childData.emailId,
				teamCount: childData.teamCount,
				membersCount: childData.membersCount,
				isExternal: childData.isExternal === true ? true : false,
				secondaryText: childData.designation,
				imagePath: childData.imagePath,
				backGroundColorCode: childData.backGroundColorCode,
				colorCode: childData.colorCode,
				parentID: childData.organisationId,
				isAlreadyAligned: childData.isAlreadyAligned,
				dueDate: childData['dueDate'],
				startValue: childData['startValue'],
				startDate: childData['startDate'],
				targetValue: childData['targetValue'],
				isAddedNew: childData.isAddedNew,
				krAssignerMessage: childData.krAssignerMessage,
				isKrOwner: childData.isKrOwner,
				checkEdited: childData.checkEdited,
				isActive: childData.isActive,
				isGroupTarget: childData?.isGroupTarget || false,
				goalId: childData && childData ? childData.goalId : 0,
			});
		});

		setSelectedUsersData(contributorDetails);
		setSelectedValues(selVal);
		setTreeNodesData(contributorsList);
		setCompleteOrgData(contributorsList);
	};
	const getUserTeams = async (event: any, scrollerId = '') => {
		scrollTo('custom-scroll');
		let teamId = keyAttribute.teamId ? keyAttribute.teamId : objectiveDetail.teamId;
		let goalKeyId = kr?.goalKeyId ? kr.goalKeyId : 0;
		let sourceId = kr?.source ? kr.source : goalKeyId;
		if (completeOrgData.length === 0) {
			let isMetricChanged = keyAttribute.isMetricChanged ? true : false;
			const apiRes = await props.getOwnerTeamsData(
				'?teamId=' +
					(teamId ? teamId : 0) +
					'&sourceId=' +
					sourceId +
					'&goalKeyId=' +
					goalKeyId +
					'&isMetricChanged=' +
					isMetricChanged
			);
			setTreeNodesData(null);
			if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
				setTeamsLoading(false);
				setTeamDetails(apiRes?.data?.entity || []);
				prepareTeamData(apiRes?.data?.entity || []);
				scrollTo('custom-scroll');
			}
		} else {
			prepareTeamData(teamDetails);
			setTeamsLoading(false);
			scrollTo('custom-scroll');
		}
		if (scrollerId !== '') {
			scrollTo('custom-scroll-id-' + teamId + '_' + scrollerId);
		}
	};

	const prepareTreeChild = (orgData: any, childData: any) => {
		return {
			id: childData.employeeId,
			employeeId: childData.employeeId,
			backGroundColorCode: childData.backGroundColorCode,
			colorCode: childData.colorCode,
			teamName: childData.teamName,
			designation: childData.designation,
			employeeCode: childData.employeeId,
			firstName: childData.firstName,
			lastName: childData.lastName,
			emailId: childData.emailId,
			imagePath: childData.imagePath,
			organisationId: orgData.organisationId,
			isExternal: true,
			teamId: childData.teamId,
			isAlreadyAligned: childData.isAlreadyAligned,
			isActive: childData.isActive,
		};
	};

	const prepareTeamData = (data: any) => {
		let completeOrgData: any = [];
		let selValParent: string[] = [];
		let selValChild: string[] = [];
		let selVal: string[] = [];
		let nodesData: any = {};
		let parentCounter: any = {};
		let contributor: any[] = [];
		if (keyAttribute.contributors && keyAttribute.contributors.length > 0) {
			contributor = keyAttribute.contributors;
		} else if (contributorDetails && contributorDetails.length > 0) {
			contributor = contributorDetails;
		}
		setSelectedUsersData(contributor);
		if (contributor.length === 0) {
			if (keyAttribute.teamId) {
				selValParent.push(keyAttribute.teamId);
			} else if (objectiveDetail && objectiveDetail.teamOkrRequests && objectiveDetail.teamOkrRequests.length > 0) {
				objectiveDetail.teamOkrRequests.forEach((data: any) => {
					selValParent.push(data.id ? data.id : data.teamId);
				});
			}
		} else {
			selectedContributorDetails.krAssignerMessage = contributor[0].krAssignerMessage;
		}
		if (data && data.teamEmployees) {
			data.teamEmployees = data.teamEmployees.filter((element: any) => element.isExternal !== true);
		}
		let externalContributors: any[] = [];
		let updatedContributorDetails: any[] = [];
		let updateContributor = false;
		contributorDetails.forEach((contributor: any) => {
			//assign isActive in teamEmployee data
			if (data && data.teamEmployees) {
				data.teamEmployees.forEach((teamEmp: any) => {
					if (teamEmp.employeeId === contributor.employeeId) {
						teamEmp.isActive = contributor.isActive;
					}
				});
			}
			// This logic corresponds to updating contributor in list to be shown as checked
			if (contributor.isAddContributor) {
				let employeeData = data.teamEmployees.filter((element: any) => element.employeeId === contributor.employeeId);
				if (employeeData.length > 0) {
					contributor.isExternal = employeeData[0].isExternal;
					if (data.organisationId) {
						contributor.teamId = data.organisationId;
					}
				} else {
					contributor.isExternal = true;
				}
				updateContributor = true;
			}
			if (data && data.teamEmployees && contributor.isExternal === true) {
				externalContributors.push(prepareTreeChild(data, contributor));
				//data.teamEmployees.push(prepareTreeChild(data, contributor));
				let Ky = 'external_' + contributor.employeeId;
				selVal.push(Ky);
				selValChild.push(Ky);
				nodesData[Ky] = contributor;
			} else {
				if (contributor.isTeamSelected === true) {
					selValParent.push(contributor.teamId);
				} else {
					let Ky = 'team_' + contributor.employeeId;
					selVal.push(Ky);
					selValChild.push(Ky);
					nodesData[Ky] = contributor;
				}
			}
			updatedContributorDetails.push(contributor);
		});
		if (updateContributor) {
			setContributorDetails(updatedContributorDetails);
		}
		let startDate: any = getMinDate(
			keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
			actionType === 'editKR' ? okr.startDate : objectiveStartDate
		);
		data = [data];
		if (externalContributors.length > 0) {
			data.push({
				id: 'external',
				organisationId: 'external',
				organisationName: '',
				membersCount: 0,
				backGroundColorCode: '',
				colorCode: '',
				teamEmployees: externalContributors,
				parentName: '',
				imagePath: '',
			});
		}

		let ownerId = 0;
		if (actionType === 'editKR' && okr?.goalTypeId) {
			ownerId = okr.owner;
		} else {
			ownerId = objectiveDetail.owner;
		}
		let tCount = 0;
		let disabledNodesList: any[] = [];
		data.forEach((orgData: any) => {
			let teamObj = {
				id: orgData.organisationId,
				fullName: orgData.organisationName,
				membersCount: orgData.membersCount,
				backGroundColorCode: orgData.backGroundColorCode,
				colorCode: orgData.colorCode,
				canHaveForm: true,
				children: [
					{
						id: '',
						fullName: '',
					},
				],
				isParent: true,
				secondaryText: orgData.parentName,
				imagePath: orgData.imagePath,
				isChecked: false,
				hasForm:
					selValParent.indexOf(orgData.organisationId) !== -1 ||
					(parentCounter[orgData.organisationId] &&
						parentCounter[orgData.organisationId] === orgData.teamEmployees.length),
				expanded: false,
				startDate: startDate,
				dueDate: keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate,
				startValue: keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue,
				targetValue: keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue,
			};
			if (nodesData[orgData.organisationId]) {
				teamObj = Object.assign(teamObj, {
					dueDate: nodesData[orgData.organisationId]['dueDate'],
					startValue: nodesData[orgData.organisationId]['startValue'],
					startDate: nodesData[orgData.organisationId]['startDate'],
					targetValue: nodesData[orgData.organisationId]['targetValue'],
				});
			}
			let orgEmpData = orgData.teamEmployees;
			let childrenData: any[] = [];
			orgEmpData?.forEach((childData: any) => {
				let childKey = orgData.id === 'external' ? 'external_' : 'team_';
				childKey = childKey + childData.employeeId;
				let isExists = selValChild.indexOf(childKey) !== -1;
				const contribObj = keyAttribute.contributors.filter((item: any) => item.employeeId === childData.employeeId);

				let childObj = {
					id: childKey,
					isChecked: isExists,
					employeeId: childData.employeeId,
					fullName: childData.firstName + ' ' + childData.lastName,
					firstName: childData.firstName,
					lastName: childData.lastName,
					emailId: childData.emailId,
					teamCount: childData.teamCount,
					membersCount: childData.membersCount,
					isExternal: childData.isExternal === true ? true : false,
					secondaryText: childData.designation,
					imagePath: childData.imagePath,
					backGroundColorCode: childData.backGroundColorCode,
					colorCode: childData.colorCode,
					canHaveForm: true,
					isParent: false,
					parentID: orgData.organisationId,
					hasForm: isExists,
					teamId: orgData.organisationId,
					startDate: startDate,
					dueDate: keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate,
					teamName: orgData.organisationName,
					teambackGroundColorCode: orgData.backGroundColorCode,
					teamcolorCode: orgData.colorCode,
					userTeamId: orgData.organisationId === 'external' ? childData.teamId : orgData.organisationId,
					isAlreadyAligned: childData.isAlreadyAligned,
					isActive: childData.isActive,
					goalId: contribObj && contribObj[0] ? contribObj[0].goalId : 0,
				};
				if (isExists === true) {
					teamObj.expanded = true;
				}
				if (childData.isAlreadyAligned === true) {
					if (selVal.indexOf(childKey) === -1) {
						selVal.push(childKey);
					}
					disabledNodesList.push(childKey);
				}
				if (nodesData[childKey]) {
					childObj = Object.assign(childObj, {
						dueDate: nodesData[childKey]['dueDate'],
						startValue: nodesData[childKey]['startValue'],
						startDate: nodesData[childKey]['startDate'],
						targetValue: nodesData[childKey]['targetValue'],
						teamName: nodesData[childKey]['teamName'],
						teambackGroundColorCode: nodesData[childKey]['backGroundColorCode'],
						teamcolorCode: nodesData[childKey]['colorCode'],
						isKrOwner: nodesData[childKey]['isKrOwner'],
						checkEdited: nodesData[childKey]['checkEdited'],
						isActive: nodesData[childKey]['isActive'],
					});
					teamObj.expanded = true;
				} else {
					childObj = Object.assign(childObj, {
						dueDate: teamObj['dueDate'],
						startValue: 0,
						startDate: teamObj['startDate'],
						targetValue: 0,
						isActive: 1,
					});
				}
				childrenData.push(childObj);
				tCount++;
			});
			//teamObj.membersCount = tCount;
			teamObj.children = childrenData;
			completeOrgData.push(teamObj);
		});
		setSelectedValues(selVal);
		setDisabledNodes(disabledNodesList);
		setTreeNodesData(completeOrgData);
		setCompleteOrgData(completeOrgData);
		setTotalCount(tCount);
	};

	const onHandleShowAllContributors = (_event: any) => {
		onHandleAddUserClick(_event, false, false);
		setOpenScreen(1);
	};

	const getTeamData = () => {
		let teamData: any = '';
		if (goalTypeId === 1) {
			if (actionType === 'editKR') {
				teamData = okr;
			} else {
				teamData = objectiveDetail;
			}
		}
		return teamData;
	};

	const onSaveTeamContributorsDetailClick = (
		_event: any,
		contributorDetail: any,
		selectedTeams: any,
		isParent: boolean
	) => {
		let updateContributors: any = [];
		isParent = false;
		selectedTeams.forEach((selectedTeam: any) => {
			if (
				selectedContributorDetails &&
				selectedContributorDetails.krAssignerMessage &&
				selectedContributorDetails.krAssignerMessage !== ''
			) {
				setSelectedContributorDetails({
					...selectedContributorDetails,
					krAssignerMessage: contributorDetail.krAssignerMessage,
				});
			}
			let idVal = selectedTeam.id;
			if (!selectedTeam.isParent) {
				idVal = selectedTeam.id.split('_')[1];
			}
			let existingContributor: any[] = [];
			if (!selectedTeam.isParent) {
				existingContributor = contributorDetails.filter((contri: any) => contri.employeeId === idVal);
			}
			let contData = {
				employeeId: selectedTeam.isParent ? 0 : parseInt(idVal),
				assignmentTypeId: 2,
				isExternal: selectedTeam.isExternal === true ? true : false,
				objectiveName: contributorDetail.objectiveName,
				fullName: selectedTeam.fullName,
				firstName: selectedTeam.firstName,
				lastName: selectedTeam.lastName,
				emailId: selectedTeam.emailId,
				designation: selectedTeam.secondaryText,
				keyResult: contributorDetail.keyResult,
				krStatusId: existingContributor.length > 0 ? existingContributor[0].krStatusId : Enums.TWO,
				krStatusName: existingContributor.length > 0 ? existingContributor[0].krStatusName : t('acceptedOkr'),
				isSelfCreation: existingContributor.length > 0 ? existingContributor[0].isSelfCreation : false,
				score: 0,
				startDate: isParent ? contributorDetail.startDate : selectedTeam.startDate,
				dueDate: isParent ? contributorDetail.dueDate : selectedTeam.dueDate,
				startValue: isParent ? contributorDetail.startValue : selectedTeam.startValue,
				targetValue: isParent ? contributorDetail.targetValue : selectedTeam.targetValue,
				myScore: 0,
				ownTargetValue: parseInt(isParent ? contributorDetail.targetValue : selectedTeam.targetValue) || 0,
				ownStartValue: parseInt(isParent ? contributorDetail.startValue : selectedTeam.startValue) || 0,
				realScore: 0,
				actualContribution: 0,
				krAssignerMessage: contributorDetail.krAssignerMessage,
				imagePath: selectedTeam.imagePath,
				goalStatusId: actionType === 'editKR' ? okr.goalStatusId || 2 : 2,
				cycleId: props.userSelectedCycle?.organisationCycleId || 0,
				year: props.userSelectedCycle?.year,
				teamId: selectedTeam.isParent
					? selectedTeam.id
					: selectedTeam.parentID === 'external'
					? selectedTeam.userTeamId
					: selectedTeam.parentID,
				teamName: selectedTeam.teamName,
				backGroundColorCode: selectedTeam.teambackGroundColorCode,
				colorCode: selectedTeam.teamcolorCode,
				isTeamSelected: selectedTeam.isParent ? true : false,
				selectedMetric: selectedMetricDetail,
				isKrOwner: selectedTeam.isKrOwner,
				isActive: selectedTeam.isActive,
				isGroupTarget: selectedTeam?.isGroupTarget || false,
			};
			contData.startValue = contData.startValue === '' ? 0 : contData.startValue;
			contData.targetValue = contData.targetValue === '' ? 0 : contData.targetValue;
			updateContributors.push(contData);
		});
		onSaveContributorDetails(updateContributors, 'team');
	};

	const onSaveIndividualContributorsDetailClick = (
		_event: any,
		contributorDetail: any,
		selectedTeams: any,
		isParent: boolean
	) => {
		let updateContributors: any = [];
		selectedTeams.forEach((selectedTeam: any) => {
			if (
				selectedContributorDetails &&
				selectedContributorDetails.krAssignerMessage &&
				selectedContributorDetails.krAssignerMessage != ''
			) {
				setSelectedContributorDetails({
					...selectedContributorDetails,
					krAssignerMessage: contributorDetail.krAssignerMessage,
				});
			}
			let idVal = selectedTeam.id;
			let existingContributor = contributorDetails.filter((contri: any) => contri.employeeId == idVal);
			let contData = {
				employeeId: idVal,
				assignmentTypeId: 2,
				isExternal: selectedTeam.isExternal === true ? true : false,
				objectiveName: contributorDetail.objectiveName,
				fullName: selectedTeam.fullName,
				firstName: selectedTeam.firstName,
				lastName: selectedTeam.lastName,
				emailId: selectedTeam.emailId,
				designation: selectedTeam.secondaryText,
				keyResult: contributorDetail.keyResult,
				krStatusId: existingContributor.length > 0 ? existingContributor[0].krStatusId : Enums.TWO,
				krStatusName: existingContributor.length > 0 ? existingContributor[0].krStatusName : t('acceptedOkr'),
				isSelfCreation: existingContributor.length > 0 ? existingContributor[0].isSelfCreation : false,
				score: 0,
				startDate: selectedTeam.startDate,
				dueDate: selectedTeam.dueDate,
				startValue: selectedTeam.startValue,
				targetValue: selectedTeam.targetValue,
				myScore: 0,
				ownTargetValue: parseInt(selectedTeam.targetValue) || 0,
				ownStartValue: parseInt(selectedTeam.startValue) || 0,
				realScore: 0,
				actualContribution: 0,
				imagePath: selectedTeam.imagePath,
				goalStatusId: actionType === 'editKR' ? okr.goalStatusId || 2 : 2,
				cycleId: props.userSelectedCycle?.organisationCycleId || 0,
				year: props.userSelectedCycle?.year,
				backGroundColorCode: selectedTeam.teambackGroundColorCode,
				colorCode: selectedTeam.teamcolorCode,
				selectedMetric: selectedMetricDetail,
				isAddedNew: selectedTeam.isAddedNew,
				krAssignerMessage: selectedTeam.krAssignerMessage,
				isKrOwner: selectedTeam.isKrOwner,
				isActive: selectedTeam.isActive,
				isGroupTarget: selectedTeam?.isGroupTarget || false,
			};
			contData.startValue = contData.startValue === '' ? 0 : contData.startValue;
			contData.targetValue = contData.targetValue === '' ? 0 : contData.targetValue;
			updateContributors.push(contData);
		});
		onSaveContributorDetails(updateContributors, 'individual');
	};

	const deleteContributorFromKR = async (
		contributor: any,
		updateContributor: boolean = true,
		showMessage: boolean = true,
		hardDelete = false
	) => {
		const { isAddedNew, employeeId, goalId, targetValue, startValue } = contributor;
		setDeletedContributor(contributor);

		let ownStartValue = parseInt(keyAttribute.startValue || 0);
		let ownTargetValue = parseInt(keyAttribute.targetValue || 0);
		let isPositiveFlow = ownStartValue < ownTargetValue;
		let differenceValue = isPositiveFlow ? ownTargetValue - ownStartValue : ownStartValue - ownTargetValue;

		const updatedContributorDetails =
			contributorDetails && contributorDetails.length
				? contributorDetails.filter((item: any) => item.employeeId !== employeeId)
				: [];

		updatedContributorDetails.forEach((item: any) => {
			const start = parseInt(item.startValue === '' ? 0 : item.startValue);
			const target = parseInt(item.targetValue === '' ? 0 : item.targetValue);
			if (isPositiveFlow) {
				differenceValue = differenceValue - (target - start);
			} else {
				differenceValue = differenceValue - (start - target);
			}
		});

		//Formula: {|main start-target|- Summation of the absolute target of the contributors}= +ve value will get added to the owner's target value. If the value is 0/-ve nothing will be added
		differenceValue = differenceValue <= 0 ? 0 : differenceValue;

		if (!hardDelete || isAddedNew) {
			if (updateContributor) {
				if (updatedContributorDetails.length > 0 && updatedContributorDetails[0].isKrOwner) {
					if (isPositiveFlow) {
						updatedContributorDetails[0].targetValue =
							parseInt(updatedContributorDetails[0].targetValue) + differenceValue;
					} else {
						updatedContributorDetails[0].startValue =
							parseInt(updatedContributorDetails[0].startValue) + differenceValue;
					}
				}
				if (treeNodesData) {
					let treeNodesDataCp: any = treeNodesData.map((item: any) => item);
					if (goalTypeId === 2 && treeNodesDataCp.length > 0 && treeNodesDataCp[0].isKrOwner) {
						if (isPositiveFlow) {
							treeNodesDataCp[0].targetValue = parseInt(treeNodesDataCp[0].targetValue) + targetValue;
						} else {
							treeNodesDataCp[0].startValue = parseInt(treeNodesDataCp[0].startValue) + startValue;
						}
					} else if (goalTypeId === 1) {
						if (
							treeNodesDataCp.length > 0 &&
							treeNodesDataCp?.children?.length > 0 &&
							treeNodesDataCp?.children[0].isKrOwner
						) {
							if (isPositiveFlow) {
								treeNodesDataCp.children[0].targetValue =
									parseInt(treeNodesDataCp.children[0].targetValue) + targetValue;
							} else {
								treeNodesDataCp.children[0].startValue = parseInt(treeNodesDataCp.children[0].startValue) + startValue;
							}
						}
					}
					setTreeNodesData(treeNodesDataCp);
				}
				if (updatedContributorDetails.length === 0) {
					setIsListEnabled(false);
				}
			}
		} else {
			setIsDeleteInProgress(true); // comment if we do not want disabling delete and save icon
			const data = `goalKeyId=${goalId || 0}&employeeId=${employeeId}`;
			const response = await deleteContributor(data);
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				/** Remove Contributor from List */
				if (updateContributor) {
					if (updatedContributorDetails.length > 0 && updatedContributorDetails[0].isKrOwner) {
						if (isPositiveFlow) {
							updatedContributorDetails[0].targetValue =
								parseInt(updatedContributorDetails[0].targetValue) + parseInt(targetValue);
						} else {
							updatedContributorDetails[0].startValue =
								parseInt(updatedContributorDetails[0].startValue) + parseInt(startValue);
						}
					}
					setContributorDetails(updatedContributorDetails);
					updateKeyAttrDetail('contributors', updatedContributorDetails);
					let treeNodesDataCp: any = treeNodesData.map((item: any) => item);
					if (goalTypeId === 2 && treeNodesDataCp.length > 0 && treeNodesDataCp[0].isKrOwner) {
						if (isPositiveFlow) {
							treeNodesDataCp[0].targetValue = parseInt(treeNodesDataCp[0].targetValue) + targetValue;
						} else {
							treeNodesDataCp[0].startValue = parseInt(treeNodesDataCp[0].startValue) + startValue;
						}
					} else if (goalTypeId === 1) {
						if (
							treeNodesDataCp.length > 0 &&
							treeNodesDataCp[0]?.children?.length > 0 &&
							treeNodesDataCp[0]?.children[0].isKrOwner
						) {
							if (isPositiveFlow) {
								treeNodesDataCp[0].children[0].targetValue =
									parseInt(treeNodesDataCp[0].children[0].targetValue) + targetValue;
							} else {
								treeNodesDataCp[0].children[0].startValue =
									parseInt(treeNodesDataCp[0].children[0].startValue) + startValue;
							}
						}
					}
					setTreeNodesData(treeNodesDataCp);
					if (updatedContributorDetails.length === 0) {
						setIsListEnabled(false);
					}
					setIsDeleteInProgress(false);
				}
				if (showMessage) {
					/*enqueueSnackbar(response?.data?.messageList?.Result, {
						variant: 'success',
						autoHideDuration: 5000,
					});*/
				}
				// fetchOkr();
			} else if (response && response.data.status === Enums.STATUS_BAD_REQUEST) {
				const errorMessages = Object.values(response?.data?.messageList);
				if (errorMessages && errorMessages.length > 0) {
					errorMessages.forEach((item: any) =>
						enqueueSnackbar(item, {
							variant: 'error',
							autoHideDuration: 5000,
						})
					);
				}
			} else if (response && response.data.status === Enums.STATUS_SERVER_ERROR) {
				enqueueSnackbar(t('goalAddedFail'), {
					variant: 'error',
					autoHideDuration: 5000,
				});
			}
		}
	};

	const onHandleCancelClick = (_event: any) => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		setHideDefaultButton(false);
		setIsButtonDisable(false);
		setSelectedContributorDetails(contributorObject);
	};

	const onSaveContributorDetailClick = (_event: any, contributorDetail: any) => {
		setAnchorElCallouts && setAnchorElCallouts(null);
		setCallOut && setCallOut(initialCallout);
		if (Boolean(selectedContributorDetails)) {
			const isExist =
				contributorDetails && contributorDetails.length
					? contributorDetails.find((item: any) => item.employeeId === contributorDetail.employeeId)
					: null;
			const updateContributors =
				contributorDetails && contributorDetails.length
					? isExist
						? contributorDetails.map((item: any) => {
								if (isExist && item.employeeId === contributorDetail.employeeId) {
									item = contributorDetail;
								}
								return item;
						  })
						: [...contributorDetails, contributorDetail]
					: [...contributorDetails, contributorDetail];
			setContributorDetails(updateContributors);
			updateKeyAttrDetail('contributors', updateContributors);
			setHideDefaultButton(false);
			setIsButtonDisable(false);
			contributorObject.objectiveName = selectedContributorDetails.objectiveName;
			setSelectedContributorDetails(contributorObject);
			setIsContributorUpdated(true);
		}
	};

	const userAligned = async (selectedUser: any) => {
		const details = kr ? kr : okr;
		const goalKeyId = details?.goalKeyId ? details?.goalKeyId : 0;
		let sourceType = Enums.TWO;
		const sourceId = details?.source && details?.source > 0 ? details?.source : goalKeyId;
		const apiData = `sourceType=${sourceType}&sourceId=${sourceId}&employeeId=${
			selectedUser.employeeId
		}&isMetricChange=${keyAttribute.isMetricChanged ? true : false}`;
		const resp = await checkAlignStatus(apiData);
		const { entity } = resp.data;
		let isAligned = false;
		if (entity && entity?.isAligned === false) {
			isAligned = false;
		} else if (entity?.isAligned && entity?.alignStatus) {
			isAligned = true;
			if (entity?.alignStatus === Enums.ONE) {
				//Pending
				enqueueSnackbar(`${t('pendingUserMessage')} ${getFullName(selectedUser)}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			} else if (entity?.alignStatus === Enums.THREE) {
				//Declined
				enqueueSnackbar(`${getFullName(selectedUser)} ${t('declinedUserMessage')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			} else {
				enqueueSnackbar(`${getFullName(selectedUser)} ${t('alreadyAlign')}`, {
					variant: 'info',
					autoHideDuration: 3000,
				});
			}
		} else {
			enqueueSnackbar(`${getFullName(selectedUser)} ${t('alreadyAlign')}`, {
				variant: 'info',
				autoHideDuration: 3000,
			});
		}
		return isAligned;
	};

	const onDeleteContributor = (event: any, contributor: any, type: String, isGroupTarget: boolean) => {
		event.preventDefault();
		event.stopPropagation();
		if (type === 'Delete') {
			setModalProps({
				open: true,
				type: 'DeleteKRContributor',
				message: isGroupTarget
					? t('deleteKRContributorGroupTarget')
					: isBecomeContributor || !contributor.targetValue || contributor.targetValue === ''
					? t('deleteNewKRContributor')
					: t('deleteKRContributor', { amount: getMetricCurrencyIcon(contributor.targetValue) }),
				module: 'user',
				event,
				data: contributor,
			});
		} else if (type === 'Edit') {
			if (goalTypeId === 2) {
				setSelectedContributorDetails({ ...contributor });
				setAddContributorAnchorEl(null);
				setAddContributorOpen(false);
				setHideDefaultButton(true);
				setIsButtonDisable(true);
				setIsListEnabled((prev) => !prev);
			} else {
				setSelectedContributorDetails({ ...contributor });
				setIsListEnabled((prev) => !prev);
				setShowTeamsOkr(true);
				setTreeNodesData(null);
				callTreeNodes(event, contributor.employeeId);
			}
		}
	};

	const handleCloseModal = (event: any, type: Number) => {
		event.preventDefault();
		if (modalProps.type === 'underAssign') {
			let contData = modalProps.data.contributors.map((item: any) => item);
			setSelectedUsersData(modalProps.data.contributors);
			if (type === 1) {
				if (modalProps.data.actualValue < 0) {
					setCurrentScreen(0);
				}
				if (modalProps.data.type === 'manage') {
					closeDrawer(contData, modalProps.data.type);
					onHandleAddUserClick(event, completeOrgData.length > 0, selectedTeamIds.length > 0);
				}
			} else {
				if (modalProps.data.actualValue < 0 && contData && contData.length > 0 && contData[0].isKrOwner) {
					if (modalProps.data.deviationOn) {
						contData[0].startValue = parseInt(contData[0].startValue) + parseInt(modalProps.data.value);
					} else {
						contData[0].targetValue = parseInt(contData[0].targetValue) + parseInt(modalProps.data.value);
					}
				}
				closeDrawer(contData, modalProps.data.type);
			}
		} else if (modalProps.type === 'DeleteKRContributor') {
			if (type === 1) {
				deleteContributorFromKR(modalProps.data, true, true, true);
			}
		}
		setModalProps(staticModalProps);
	};

	const handleKrMessageChange = (value: any) => {
		contributorDetails.forEach((element: any) => {
			if (element.isAddedNew === true) {
				element.krAssignerMessage = value;
			}
		});
	};

	const onSaveContributorDetails = async (updatedContributorList: any, type: string) => {
		if (!isBecomeContributor) {
			let data: any = {
				krStartValue: keyAttribute.startValue ? keyAttribute.startValue : keyData.startValue,
				krTargetValue: keyAttribute.targetValue ? keyAttribute.targetValue : keyData.targetValue,
				underAssignAndOverAssign: [],
			};
			updatedContributorList.forEach((item: any) => {
				data.underAssignAndOverAssign.push({
					employeeId: item.employeeId,
					isOwner: item.isKrOwner || false,
					startValue: item.startValue,
					targetValue: item.targetValue,
				});
			});
			const apiRes = await props.underAssign(data);
			if (apiRes && apiRes.status === Enums.STATUS_SUCCESS) {
				if (apiRes.data.entity.deviationValue !== 0) {
					setModalProps({
						open: true,
						type: 'underAssign',
						message: t(apiRes.data.entity.deviationValue < 0 ? 'underAssignAlert' : 'overAssignAlert', {
							value: getMetricCurrencyIcon(Math.abs(apiRes.data.entity.deviationValue)),
						}),
						module: 'user',
						event: null,
						data: {
							value: Math.abs(apiRes.data.entity.deviationValue),
							actualValue: apiRes.data.entity.deviationValue,
							contributors: updatedContributorList,
							deviationOn: apiRes.data.entity.isReverseProgress,
							type,
						},
					});
				} else {
					closeDrawer(updatedContributorList, type);
				}
			}
		} else {
			closeDrawer(updatedContributorList, type);
		}
	};
	const closeDrawer = (updatedContributorList: any, type: string) => {
		if (type === 'manage') {
			closeManageContributor(updatedContributorList);
		} else if (type === 'individual') {
			closeIndividualContributor(updatedContributorList);
		} else if (type === 'team') {
			closeTeamContributor(updatedContributorList);
		}
	};
	const closeManageContributor = (updatedContributorList: any) => {
		if (
			updatedContributorList.length > 0 &&
			updatedContributorList[0].isKrOwner === true &&
			updatedContributorList[0].checkEdited
		) {
			delete updatedContributorList[0].checkEdited;
		}
		setContributorDetails(updatedContributorList);
		updateKeyAttrDetail('contributors', updatedContributorList);
		setIsContributorUpdated(true);
		setIsListEnabled(false);
		setOpenScreen(0);
		setCurrentScreen(0);
	};
	const closeIndividualContributor = (updateContributors: any) => {
		const isGroupTargetDetails =
			updateContributors && updateContributors.length
				? [...updateContributors].find((item: any) => item?.isGroupTarget)
				: null;
		setContributorDetails(updateContributors);
		setShowTeamsOkr(false);
		setAddContributorOpen(false);
		updateKeyAttrDetail('contributors', updateContributors);
		if (isGroupTargetDetails && isGroupTargetDetails?.isGroupTarget) {
			updateKeyAttrDetail('isGroupTarget', isGroupTargetDetails?.isGroupTarget);
		}
		setIsButtonDisable(false);
		setIsContributorUpdated(true);
		enqueueSnackbar(t('contributorsAddedMsg'), {
			variant: 'success',
			autoHideDuration: 5000,
		});
		setOpenScreen(0);
		setCurrentScreen(0);
	};
	const closeTeamContributor = (updateContributors: any) => {
		const isGroupTargetDetails =
			updateContributors && updateContributors.length
				? [...updateContributors].find((item: any) => item?.isGroupTarget)
				: null;
		setContributorDetails(updateContributors);
		setShowTeamsOkr(false);
		updateKeyAttrDetail('contributors', updateContributors);
		if (isGroupTargetDetails && isGroupTargetDetails?.isGroupTarget) {
			updateKeyAttrDetail('isGroupTarget', isGroupTargetDetails?.isGroupTarget);
		}
		setIsButtonDisable(false);
		setIsContributorUpdated(true);
		enqueueSnackbar(t('contributorsAddedMsg'), {
			variant: 'success',
			autoHideDuration: 5000,
		});
		setOpenScreen(0);
		setCurrentScreen(0);
	};
	return (
		<Box
			className={
				hideDefaultButton || addContributorOpen || isLocked
					? 'kr-assign-contributor assignee-data add-contributor-open'
					: 'kr-assign-contributor assignee-data'
			}
		>
			<Box className='assign-contributor-label'>
				<Box display='flex' alignItems='center'>
					{contributorDetails && contributorDetails.length > 0 ? (
						<FormLabel component='legend'>{t('contributorsLabel')}</FormLabel>
					) : (
						<Fragment>
							<OKRButton
								className={`add-btn2 ${addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''}`}
								icon={<AddIconSvg />}
								color='primary'
								handleClick={onHandleAddUserClick}
								disabled={false}
								id={'add-contributor-btn'}
							/>
							<FormLabel component='legend'>{t('contributorsLabel')}</FormLabel>
						</Fragment>
					)}
				</Box>
				<Box>
					{contributorDetails && contributorDetails.length > 0 ? (
						<OKRButton
							className={`btn-link-type1 ${addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''}`}
							disabled={hideDefaultButton || keyAttribute.isDefaultGoalKey || !Boolean(checkTargetValue())}
							icon={<EditIcon />}
							text={t('manageContributor')}
							handleClick={onHandleShowAllContributors}
							id={'mng-contributor-btn'}
						/>
					) : (
						<OKRButton
							className={`btn-link-type1 disabled ${
								addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''
							}`}
							disabled={isLocked || hideDefaultButton || keyAttribute.isDefaultGoalKey || !Boolean(checkTargetValue())}
							icon={<EditIcon />}
							text={t('manageContributor')}
							id={'mng-contributor-btn'}
						/>
					)}
				</Box>
			</Box>

			{contributorDetails && contributorDetails.length ? (
				<Box>
					<Box className='add-more-contri'>
						<Box className='kr-attribute-avatar-list'>
							<UserAvatarGroup
								{...props}
								t={t}
								max={10}
								contributorDetails={contributorDetails}
								handleAddUserClick={onHandleAddUserClick}
								addButtonClassName='add-btn'
								avatarClassName=''
								isButtonVisible={false}
								isButtonDisable={isLocked || hideDefaultButton || isButtonDisable || keyAttribute.isDefaultGoalKey}
								isOnHoverVisible={true}
								contributorDetailsType={true}
								showEditHeader={!keyAttribute.isDefaultGoalKey}
								hideScore={true}
								hideLinks={true}
								doNotAllowUserSelection={true}
								showTeamDetails={false}
								onEditHeaderclick={() => {
									setIsListEnabled(true);
								}}
								fullEmailId={true}
							/>
						</Box>
						{checkPermission(getPermissionResult?.employeePermissions, assignOkr) ? (
							<OKRButton
								className={`add-btn2 ${addContributorOpen === true || showTeamsOkr === true ? 'no-pointer' : ''}`}
								icon={<AddIconSvg />}
								color='primary'
								title={
									!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? t('AddMoreContributors') : ''
								}
								handleClick={onHandleAddUserClick}
								disabled={!Boolean(checkTargetValue()) || isLocked || keyAttribute.isDefaultGoalKey || false}
								id={'add-contributor-btn'}
							/>
						) : (
							<></>
						)}
					</Box>
				</Box>
			) : (
				getTeamData() !== '' && (
					<Box className='kr-no-contributors-text'>
						<Typography variant='subtitle1'>
							<em>{t('ifNoContributors')}</em>
						</Typography>
					</Box>
				)
			)}
			{showTeamsOkr && (
				<TeamsKr
					{...props}
					setDeletedContributor={setDeletedContributor}
					deletedContributor={deletedContributor}
					disabledNodes={disabledNodes}
					selectedValues={selectedValues}
					setSelectedValues={setSelectedValues}
					treeNodesData={treeNodesData}
					setTreeNodesData={setTreeNodesData}
					open={showTeamsOkr}
					setOpen={setShowTeamsOkr}
					teamsLoading={teamsLoading}
					setDataUpdated={() => {}}
					setIsSaveDisabled={() => {}}
					totalCount={totalCount}
					t={t}
					selectedContributorDetails={selectedContributorDetails}
					keyAttribute={keyAttribute}
					okrMasterData={okrMasterData}
					onSaveTeamContributorsDetailClick={onSaveTeamContributorsDetailClick}
					getCurrencyIcon={getCurrencyIcon}
					selectedMetricDetail={selectedMetricDetail}
					keyData={keyData}
					completeOrgData={completeOrgData}
					setCompleteOrgData={setCompleteOrgData}
					deleteContributor={deleteContributorFromKR}
					contributorDetails={contributorDetails}
					type={2}
					durationCycleDetail={durationCycleDetail}
					objectiveStartDate={objectiveStartDate}
					objectiveEndDate={objectiveEndDate}
					onHandleCancelClick={onHandleCancelClick}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					dueCycle={okr?.dueCycle}
					userAligned={userAligned}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					initialCallout={initialCallout}
					showAssignKrDateCallouts={showAssignKrDateCallouts}
					setShowAssignKrDateCallouts={setShowAssignKrDateCallouts}
					setShowAssignKrTargetCallouts={setShowAssignKrTargetCallouts}
					showAssignKrTargetCallouts={showAssignKrTargetCallouts}
					showAssignKrTitleCallouts={showAssignKrTitleCallouts}
					setShowAssignKrTitleCallouts={setShowAssignKrTitleCallouts}
					calloutsData={calloutsData}
					onDeleteContributor={onDeleteContributor}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					openScreen={openScreen}
					setOpenScreen={setOpenScreen}
					selectedUsersData={selectedUsersData}
					setSelectedUsersData={setSelectedUsersData}
					selectedTeamIds={selectedTeamIds}
					setSelectedTeamIds={setSelectedTeamIds}
					isDeleteInProgress={isDeleteInProgress}
					isLocked={isLocked}
				/>
			)}

			{addContributorOpen ? (
				<IndividualKr
					{...props}
					t={t}
					setDeletedContributor={setDeletedContributor}
					deletedContributor={deletedContributor}
					open={addContributorOpen}
					setOpen={setAddContributorOpen}
					completeOrgData={completeOrgData}
					selectedValues={selectedValues}
					setSelectedValues={setSelectedValues}
					treeNodesData={treeNodesData}
					setTreeNodesData={setTreeNodesData}
					setDataUpdated={() => {}}
					setIsSaveDisabled={() => {}}
					totalCount={totalCount}
					selectedContributorDetails={selectedContributorDetails}
					keyAttribute={keyAttribute}
					onSaveIndividualContributorsDetailClick={onSaveIndividualContributorsDetailClick}
					getCurrencyIcon={getCurrencyIcon}
					selectedMetricDetail={selectedMetricDetail}
					keyData={keyData}
					setCompleteOrgData={setCompleteOrgData}
					deleteContributor={deleteContributorFromKR}
					contributorDetails={contributorDetails}
					type={2}
					onHandleCancelClick={onHandleCancelClick}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					dueCycle={okr?.dueCycle}
					userAligned={userAligned}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					initialCallout={initialCallout}
					showAssignKrDateCallouts={showAssignKrDateCallouts}
					setShowAssignKrDateCallouts={setShowAssignKrDateCallouts}
					setShowAssignKrTargetCallouts={setShowAssignKrTargetCallouts}
					showAssignKrTargetCallouts={showAssignKrTargetCallouts}
					showAssignKrTitleCallouts={showAssignKrTitleCallouts}
					setShowAssignKrTitleCallouts={setShowAssignKrTitleCallouts}
					calloutsData={calloutsData}
					onDeleteContributor={onDeleteContributor}
					currentScreen={currentScreen}
					setCurrentScreen={setCurrentScreen}
					openScreen={openScreen}
					setOpenScreen={setOpenScreen}
					selectedUsersData={selectedUsersData}
					setSelectedUsersData={setSelectedUsersData}
					isDeleteInProgress={isDeleteInProgress}
					isLocked={isLocked}
				/>
			) : (
				<></>
			)}
			{hideDefaultButton ? (
				<AddEditContributorDetail
					{...props}
					t={t}
					type={2}
					keyData={keyData}
					keyAttribute={keyAttribute}
					durationCycleDetail={durationCycleDetail}
					objectiveStartDate={objectiveStartDate}
					objectiveEndDate={objectiveEndDate}
					setKRContributorDetailDashboard={() => {}}
					selectedContributorDetails={selectedContributorDetails}
					onHandleCancelClick={onHandleCancelClick}
					onSaveContributorDetailClick={onSaveContributorDetailClick}
					getCurrencyIcon={getCurrencyIcon}
					hideBackButton={true}
					handleCancel={onHandleCancelClick}
					goalTypeId={goalTypeId}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					initialCallout={initialCallout}
					calloutsData={calloutsData}
				/>
			) : (
				<></>
			)}
			{isListEnabled && (
				<ManageContributorList
					{...props}
					contributorDetails={contributorDetails}
					keyAttribute={keyAttribute}
					keyData={keyData}
					objectiveEndDate={objectiveEndDate}
					getCurrencyIcon={getCurrencyIcon}
					onDeleteContributor={onDeleteContributor}
					t={t}
					teamData={getTeamData()}
					goalTypeId={parentGoalTypeId ? parentGoalTypeId : goalTypeId}
					open={isListEnabled}
					setOpen={setIsListEnabled}
					durationCycleDetail={durationCycleDetail}
					objectiveStartDate={objectiveStartDate}
					selectedContributorDetails={selectedContributorDetails}
					setContributorDetails={setContributorDetails}
					updateKeyAttrDetail={updateKeyAttrDetail}
					setIsContributorUpdated={() => {}}
					dueCycle={okr?.dueCycle}
					setCallOut={setCallOut}
					setAnchorElCallouts={setAnchorElCallouts}
					anchorElCallouts={anchorElCallouts}
					initialCallout={initialCallout}
					calloutsData={calloutsData}
					onSaveContributorDetails={onSaveContributorDetails}
					selectedMetricDetail={selectedMetricDetail}
					isLocked={isLocked}
				/>
			)}

			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
					isCancel={false}
				/>
			)}
		</Box>
	);
};
