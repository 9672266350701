import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Box, ClickAwayListener, Grow, List, ListItem, Paper, Popper, Tab, Tabs } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { AlignSettingIcon } from '../../../../config/svg/CommonSvg';
import LinearLoader from '../../../Common/Loader';
import { CreateOKRIcon } from '../../../../config/svg/MyGoalSvg';
import { SettingsTabPanel } from '..';
import { Align } from './Align';
import { DefaultOkr } from './DefaultOkr';
import { CreateDefaultOkr } from './DefaultOkr/CreateDefaultOkr';
import { CycleDropdown } from '../../../Common/CycleDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setDefaultOkrName } from '../../../../action/common';
import { getLocalStorageItem } from '../../../../services/StorageService';
import { checkUnSavePopUpVisible, getCycleDefaultSelection } from '../../../../config/utils';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Enums } from '../../../../config/enums';
import {
	alignSelectedCycle,
	getDefaultOkrCycleDetails,
	getDefaultOkrDesignation,
	getDefaultOkrDetailsForEditCall,
	getDefaultOkrPerformanceRole,
	pastCycleSelected,
} from '../../../../action/adminDefaultOkr';
import { CopyOkrMainDrawer } from './AdminCopyOkr/CopyOkrMainDrawer';
import { AlignSettingsTab } from './AlignSettingsTab';
import { getOkrMasterData } from '../../../../action/myGoal';
import AlertDialog from '../../../Common/Dialog';
import { ImportOkrMainDrawer } from './AdminImportOkr/ImportOkrMainDrawer';

export const AlignSetting: React.FC<any> = (props: any) => {
	const {
		getCyclesData,
		cycleMasterData,
		parentOrganisation,
		handleTabChange,
		a11yProps,
		tabSelected,
		defaultOkrOpen,
		setDefaultOkrOpen,
		setTabSelected,
		handleClickDefaultOkr,
		openImportOkr,
		setOpenImportOkr,
	} = props;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const {
		cycleDetailsForDefaultOkr,
		designationDetailsForDefaultOkr,
		performanceRoleDetailsForDefaultOkr,
		openCopyOkr,
	} = useSelector((state: any) => state.adminDefaultOkrReducer);
	const { okrMasterData } = useSelector((state: any) => state.okrReducer);
	const moment = extendMoment(Moment);
	// const allCycleDetails = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const cycleId = getLocalStorageItem('cycleId') ? parseInt(getLocalStorageItem('cycleId') || '0') : 0;
	// const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	const currentCycleYear = getLocalStorageItem('year') || null;
	// const defaultGoalKeyAssignMappingsObject = {
	// 	defaultGoalKeyAssignMappingId: Enums.ZERO,
	// 	assignId: Enums.ZERO,
	// 	defaultGoalKeyId: Enums.ZERO,
	// 	defaultGoalObjectiveAssignMappingId: Enums.ZERO,
	// 	weight: Enums.ZERO,
	// 	startValue: Enums.ZERO,
	// 	targetValue: Enums.ZERO,
	// };
	const defaultGoalKeyObject = {
		defaultGoalKeyId: Enums.ZERO,
		name: '',
		startDate: null,
		endDate: null,
		metricType: Enums.ZERO,
		currencyId: Enums.ZERO,
		isAddContributorAllow: true,
		weight: Enums.ONE,
		startValue: Enums.ZERO,
		targetValue: Enums.ZERO,
		isWeightSame: true,
		isTargetSame: true,
		status: Enums.ZERO, // Draft = 1, Publish = 2, Unpublish = 3, UpdateNotPublish = 4
		defaultGoalKeyAssignMappings: [], // [defaultGoalKeyAssignMappingsObject],
		isKrEdited: false,
		searchTextValueForKrRole: '',
		searchTextForKrRole: '',
		isExpand: true,
		krSortColumn: '',
	};
	const defaultOkrDetailsObj = {
		defaultGoalObjectiveId: Enums.ZERO,
		teamCycleDetailId: Enums.ZERO,
		name: '',
		startDate: null,
		endDate: null,
		assignType: Enums.ZERO, // All = Enums.ZERO,  Designation = 1, JobLevel = 2, Users = 3, AllEmployees = 4
		natureTypeId: Enums.ZERO, // Aspiration = 1, Committed = 2
		status: Enums.ZERO, // Draft = 1, Publish = 2, Unpublish = 3, UpdateNotPublish = 4
		assignIds: [], // { defaultGoalObjectiveAssignMappingId: Enums.ZERO, assignId: Enums.ZERO },
		selectedListDetails: [], // For UI Use
		defaultGoalKeys: [defaultGoalKeyObject],
	};
	const refObject = useRef<any>(null);

	const [showLoader, setShowLoader] = useState(false);
	const [listLoading, setListLoading] = useState(false);
	const [selectedCycle, setSelectedCycle] = useState({});
	const [cycleList, setCycleList] = useState([]);
	const [organisationDetails, setOrganisationDetails] = useState<any>({});
	const [orgOriginalData, setOrgOriginalData] = useState<any>({});
	const [selectedDate, setDate] = useState(moment('2020-12-12'));
	const [zeroNotAllowed, setZeroNotAllowed] = useState<boolean>(false);
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [emailPlanningPeriodError, setEmailPlanningPeriodError] = useState<any>({
		error: '',
		helperText: '',
		type: '',
	});
	const [emailExecutionPeriodError, setEmailExecutionPeriodError] = useState<any>({
		error: '',
		helperText: '',
		type: '',
	});
	const [cycleCompletionPeriodError, setCycleCompletionPeriodError] = useState<any>({
		error: '',
		helperText: '',
		type: '',
	});
	const [defaultOkrTagNameError, setDefaultOkrTagNameError] = useState<any>({
		error: false,
		helperText: '',
		type: '',
	});
	const [selectedCycleDetails, setSelectedCycleDetails] = useState<any>(null);
	const [isCurrentCycle, setIsCurrentCycle] = useState<any>(null);
	// const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	const [currentCycleId, setCurrentCycleId] = useState(cycleId);
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [currentSelectedCycleYear, setCurrentSelectedCycleYear] = useState(currentCycleYear);
	// ==========================================================================================
	const [defaultOkrFormDetails, setDefaultOkrFormDetails] = useState(defaultOkrDetailsObj);
	const [defaultOkrDesignationDetails, setDefaultOkrDesignationDetails] = useState([]);
	const [defaultOkPerformanceRoleDetails, setDefaultOkrPerformanceDetails] = useState([]);
	const [editDetails, setEditDetails] = useState<any>(null);
	const [isDefaultOkrFormEdited, setIsDefaultOkrFormEdited] = useState<boolean>(false);
	const [modalPerformanceProps, setModalPerformanceProps] = useState<any>({ open: false, message: '', details: '' });
	const [highlightDefaultOkr, setHighlightDefaultOkr] = useState<any>(0);
	const [openDefaultObjDrawer, setOpenDefaultObjDrawer] = useState<boolean>(false);
	const [openDefaultKrDrawer, setOpenDefaultKrDrawer] = useState<any>({ open: false, details: null });
	const [weightSelectionOpen, setWeightSelectionOpen] = useState<boolean>(false);

	// const [openDefaultObjDrawer, setOpenDefaultObjDrawer] = useState<boolean>(false);
	// const [selectedAssignTab, setSelectedAssignTab] = useState<any>(0);
	useEffect(() => {
		dispatch(pastCycleSelected(false));
	}, []);
	useEffect(() => {
		dispatch(alignSelectedCycle(selectedCycleDetails));
	}, [selectedCycleDetails]);
	useEffect(() => {
		if (
			cycleDetailsForDefaultOkr &&
			cycleDetailsForDefaultOkr.cycleDetails &&
			cycleDetailsForDefaultOkr.cycleDetails.length
		) {
			let currentQuarterDetails: any = getCycleDefaultSelection(cycleDetailsForDefaultOkr.cycleDetails, cycleId);
			if (currentQuarterDetails) {
				setSelectedCycleDetails({ ...currentQuarterDetails });
				setIsCurrentCycle({ ...currentQuarterDetails });
				// setCurrentSelectedCycleYear(currentQuarterDetails?.year || currentCycleYear);
				setCurrentCycleId(currentQuarterDetails?.organisationCycleId || currentCycleId);
				const { startDate, endDate } = currentQuarterDetails;
				setSelectedDateRange(moment().range(new Date(startDate), new Date(endDate)));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cycleDetailsForDefaultOkr]);

	useEffect(() => {
		setShowLoader(true);
		props.getParentOrganization();
		if (!cycleMasterData.length) {
			getCyclesData();
		}
		dispatch(getDefaultOkrCycleDetails());
		dispatch(getDefaultOkrDesignation());
		dispatch(getDefaultOkrPerformanceRole());
		if (!Boolean(okrMasterData)) {
			dispatch(getOkrMasterData());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (designationDetailsForDefaultOkr && performanceRoleDetailsForDefaultOkr) {
			setDefaultOkrDesignationDetails(designationDetailsForDefaultOkr);
			setDefaultOkrPerformanceDetails(performanceRoleDetailsForDefaultOkr);
			setShowLoader(false);
		}
	}, [designationDetailsForDefaultOkr, performanceRoleDetailsForDefaultOkr]);

	useEffect(() => {
		if (parentOrganisation && parentOrganisation.teamId) {
			setOrganisationDetails(parentOrganisation);
			setOrgOriginalData(parentOrganisation);
			setDate(parentOrganisation.cycleStartDate);
			if (parentOrganisation?.defaultOkrTagName || '') {
				dispatch(setDefaultOkrName(parentOrganisation?.defaultOkrTagName || ''));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [parentOrganisation]);

	useEffect(() => {
		if (cycleMasterData.length && parentOrganisation.teamId) {
			// setShowLoader(false);
			let cycles = cycleMasterData.map((cycle: any) => {
				return {
					...cycle,
					label: cycle.cycleName,
					value: cycle.cycleId,
				};
			});
			let currentCycle = cycles.filter((item: any) => item.cycleId === parentOrganisation.cycleId);
			setCycleList(cycles);
			setSelectedCycle(currentCycle[0]);
		}
	}, [cycleMasterData, parentOrganisation]);

	/** OUT SIDE CLICK */
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDefaultOkrFormEdited]);

	const handleClickOutside = (event: any) => {
		if (
			refObject.current &&
			!refObject.current?.contains(event.target) &&
			(checkUnSavePopUpVisible(event) || event.target.id === 'back-btn') &&
			isDefaultOkrFormEdited
		) {
			const calendarBox = document.getElementsByClassName('calender-control-inner');
			const weightageBox = document.getElementsByClassName('weightage-dropdown');
			if (
				openDefaultObjDrawer ||
				openDefaultKrDrawer?.open ||
				weightSelectionOpen ||
				(calendarBox && calendarBox.length > 0 && calendarBox[0].contains(event.target)) ||
				(weightageBox && weightageBox.length > 0 && weightageBox[0].contains(event.target))
			) {
				//
			} else {
				const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
				if (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target)) {
					event.preventDefault();
					event.stopPropagation();

					// console.log('isDefaultOkrFormEdited === OPEN Model ===> ', isDefaultOkrFormEdited);
					setModalPerformanceProps({ open: true, message: t('taskUnsavedChanges'), details: '', event: event });
				}
			}
		}
	};
	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalPerformanceProps?.event) {
				// setDefaultOkrOpen(false);
				// setTabSelected(1);
				// setDefaultOkrFormDetails(defaultOkrDetailsObj);
				// setEditDetails(null);
				/** OUT SIDE CLICK */
				await setIsDefaultOkrFormEdited(false);
				const { target } = modalPerformanceProps?.event || {};
				const clonedNativeEvent = document.createEvent('HTMLEvents');
				clonedNativeEvent.initEvent('click', true, false);
				target.dispatchEvent(clonedNativeEvent);
			} else {
				// setPerformanceReviewFormType('Add');
				// setEditableDetails(null);
				// setSelectedTemplateDetails([]);
				// setSelectedCoreValueTemplateDetails([]);
			}

			setModalPerformanceProps({ open: false, message: '', details: '' });
		} else {
			setModalPerformanceProps({ open: false, message: '', details: '' });
		}
	};

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const handleAlignSettingsSave = async () => {
		if (organisationDetails?.emailExecutionPeriod === 0 && organisationDetails?.isEmailExecutionPeriod) {
			setZeroNotAllowed(true);
			setEmailExecutionPeriodError({
				error: t('zeroNotAllowedText'),
				helperText: t('zeroNotAllowedText'),
				type: 'emailExecutionPeriod',
			});

			return false;
		}
		if (
			!organisationDetails?.defaultOkrTagName ||
			(organisationDetails?.defaultOkrTagName && organisationDetails?.defaultOkrTagName?.trim() === '')
		) {
			setDefaultOkrTagNameError({
				error: true,
				helperText: 'Please enter default okr name',
				type: 'defaultOkrTagName',
			});
			return false;
		}
		if (
			(!organisationDetails?.cycleCompletionPeriod || organisationDetails?.cycleCompletionPeriod?.trim() === '') &&
			organisationDetails?.isCycleCompletion
		) {
			setZeroNotAllowed(true);
			setCycleCompletionPeriodError({
				error: t('zeroNotAllowedText'),
				helperText: t('zeroNotAllowedText'),
				type: 'cycleCompletionPeriod',
			});

			return false;
		}
		setShowLoader(true);
		let orgData = {
			teamId: organisationDetails.teamId,
			cycleId: organisationDetails.cycleId,
			gracePeriod: organisationDetails.gracePeriod,
			planningPeriod: organisationDetails.planningPeriod,
			cycleStartDate: organisationDetails.cycleStartDate,
			emailPlanningPeriod: organisationDetails.emailPlanningPeriod,
			emailExecutionPeriod: organisationDetails.emailExecutionPeriod,
			isEmailPlanningPeriod: organisationDetails.isEmailPlanningPeriod,
			isEmailExecutionPeriod: organisationDetails.isEmailExecutionPeriod,
			industryType: organisationDetails.industryType,
			defaultOkrTagName: organisationDetails?.defaultOkrTagName
				? organisationDetails?.defaultOkrTagName?.trim()
				: 'Default',
			isCycleCompletion: organisationDetails.isCycleCompletion,
			cycleCompletionPeriod: organisationDetails?.cycleCompletionPeriod
				? organisationDetails.cycleCompletionPeriod
						.split(',')
						.filter((item: any) => item)
						.join(',')
				: organisationDetails.cycleCompletionPeriod,
			isAllConsecutiveDay: organisationDetails?.isAllConsecutiveDay || false,
		};
		const response: any = await props.updateAlignSettings(orgData);
		if (response && response?.data?.status === 200) {
			setShowLoader(false);
			showApiMsgs(t('alignSettingsUpdatedSuccess'), 'success');
			setDefaultOkrTagNameError({
				error: '',
				helperText: '',
				type: '',
			});
			props.getParentOrganization();
		} else {
			const responseAPI = response?.data?.messageList || {};
			const keys = Object.keys(responseAPI);
			const messages = keys && keys?.length ? keys?.map((item) => responseAPI[item]) : t('someErrorOccurred');
			showApiMsgs(`${messages}`, 'error');
			setShowLoader(false);
		}
	};

	const handleAlignSettingReset = async () => {
		setOrganisationDetails(orgOriginalData);
	};

	const onCycleSelect = (value: any) => {
		setSelectedCycle(value);
	};

	const handleOrganisationIndustryChange = (e: any) => {
		const { name, value } = e.target;
		let organisation = { ...organisationDetails };
		organisation[name] = value;
		setOrganisationDetails(organisation);
		setFormEdited(true);
	};

	const handleOrganisationFieldsChange = (e: any) => {
		const { name, value } = e.target;
		if (e.target.value !== 0) {
			setZeroNotAllowed(false);
		}
		let organisation = { ...organisationDetails };
		organisation[name] = name === 'cycleCompletionPeriod' ? value : parseInt(value);
		setOrganisationDetails(organisation);
		setFormEdited(true);
	};
	const handleDefaultOkrTagNameChange = (e: any) => {
		const { name, value } = e.target;
		let organisation = { ...organisationDetails };
		organisation[name] = value;
		setOrganisationDetails(organisation);
		setFormEdited(true);
		setDefaultOkrTagNameError({
			error: '',
			helperText: '',
			type: '',
		});
	};

	const handleOrganisationFieldsBlur = (e: any) => {
		const { name, value } = e.target;
		let organisation = { ...organisationDetails };
		if (!value) {
			organisation[name] = 0;
		}
		setOrganisationDetails(organisation);
		setFormEdited(true);
	};

	const handleOrganisationToggleChange = (e: any) => {
		const { name, checked } = e.target;
		let organisation = { ...organisationDetails };
		organisation[name] = checked;

		if (name === 'isEmailPlanningPeriod' && !checked) {
			organisation['emailPlanningPeriod'] = 0;
		}

		if (name === 'isEmailExecutionPeriod' && !checked) {
			organisation['emailExecutionPeriod'] = 0;
		}
		if (name === 'isCycleCompletion') {
			organisation['cycleCompletionPeriod'] = checked ? '7,3' : '';
			organisation['isAllConsecutiveDay'] = false;
		}

		setOrganisationDetails(organisation);
		setFormEdited(true);
	};
	const handleEditDefaultOkrClick = (event: any, okrDetails: any) => {
		try {
			getEditDetailsForOkr(okrDetails);
		} catch (error) {
			console.error(error);
		}
	};
	const getEditDetailsForOkr = async (okrDetails: any) => {
		try {
			if (okrDetails && okrDetails?.defaultGoalObjectiveId) {
				setShowLoader(true);
				const response: any = await dispatch(getDefaultOkrDetailsForEditCall(okrDetails?.defaultGoalObjectiveId));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data || {};
					const updatedDetails = { ...entity };
					updatedDetails.assignIds =
						updatedDetails?.assignIds && updatedDetails?.assignIds?.length
							? updatedDetails?.assignIds.map((role: any) => {
									if (role?.name) {
										role.label = role.name;
									}
									if (role?.emailId) {
										role.value = role.emailId;
									} else {
										role.value = role.userCount;
									}
									if (role?.image) {
										role.imagePath = role.image;
									}
									return role;
							  })
							: [];
					const newRow = {
						...defaultGoalKeyObject,
						startDate: updatedDetails?.startDate || selectedCycleDetails?.startDate,
						endDate: updatedDetails?.endDate || selectedCycleDetails?.endDate,
						defaultGoalKeyAssignMappings:
							updatedDetails?.assignType === Enums.FOUR
								? updatedDetails?.assignIds
								: updatedDetails?.assignIds && updatedDetails?.assignIds?.length
								? updatedDetails?.assignIds?.map((item: any) => {
										return {
											...item,
											defaultGoalKeyAssignMappingId: Enums.ZERO,
											defaultGoalKeyId: defaultOkrFormDetails?.defaultGoalObjectiveId || Enums.ZERO,
											weight: Enums.ONE,
											startValue: Enums.ZERO,
											targetValue: Enums.ZERO,
										};
								  })
								: [],
					};
					updatedDetails.defaultGoalKeys =
						updatedDetails?.defaultGoalKeys && updatedDetails?.defaultGoalKeys?.length
							? [...updatedDetails?.defaultGoalKeys, newRow]
							: [newRow];
					updatedDetails.defaultGoalKeys =
						updatedDetails?.defaultGoalKeys && updatedDetails?.defaultGoalKeys?.length
							? updatedDetails?.defaultGoalKeys?.map((kr: any) => {
									kr.defaultGoalKeyAssignMappings =
										kr?.defaultGoalKeyAssignMappings && kr?.defaultGoalKeyAssignMappings?.length
											? kr?.defaultGoalKeyAssignMappings?.map((role: any) => {
													let extraParam = {};
													// if (role?.assignId) {
													const recordData =
														updatedDetails?.assignIds && updatedDetails?.assignIds?.length
															? updatedDetails?.assignIds?.find((item: any) => item?.assignId === role?.assignId)
															: null;
													extraParam = {
														...extraParam,
														name:
															updatedDetails?.assignType === Enums.FOUR
																? 'All Employees in the organization'
																: recordData?.name || '',
														userCount:
															updatedDetails?.assignType === Enums.FOUR
																? okrMasterData?.totalUserCount
																: recordData?.userCount || Enums.ZERO,
														value: recordData?.value || '',
													};
													if (updatedDetails?.assignType === Enums.THREE) {
														extraParam = {
															...extraParam,
															label: recordData?.label || '',
															emailId: recordData?.emailId || recordData?.value || '',
															imagePath: recordData?.imagePath || null,
															isActive: recordData?.isActive || false,
														};
													}
													// }
													return { ...role, ...extraParam };
											  })
											: [];
									return kr;
							  })
							: [];

					setEditDetails(entity);
					setDefaultOkrFormDetails({ ...updatedDetails });
					setDefaultOkrOpen(true);
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : null;
					const messages = keys && keys?.length ? keys.map((item) => messageList[item]) : [];
					showApiMsg('error', messages || 'Error occurred while fetching default okr details');
				}
				setShowLoader(false);
			} else {
				showApiMsg('error', 'Error occurred while fetching default okr details');
			}
		} catch (error) {
			console.error(error);
			showApiMsg('error', 'Error occurred while fetching default okr details');
			setShowLoader(false);
		}
	};
	const showApiMsg = (variant: any, msg: any) => {
		enqueueSnackbar(msg || 'Error occurred while fetching default okr details', {
			variant: variant || 'error',
			autoHideDuration: 3000,
		});
	};
	return (
		<>
			{showLoader && (
				<Box className='loader-wrap'>
					<LinearLoader />
				</Box>
			)}
			{defaultOkrOpen ? (
				<CreateDefaultOkr
					{...props}
					refObject={refObject}
					defaultOkrDetailsObj={defaultOkrDetailsObj}
					setDefaultOkrOpen={setDefaultOkrOpen}
					setTabSelected={setTabSelected}
					allCycleDetails={cycleDetailsForDefaultOkr}
					cycleId={cycleId}
					defaultGoalKeyObject={defaultGoalKeyObject}
					defaultOkrFormDetails={defaultOkrFormDetails}
					setDefaultOkrFormDetails={setDefaultOkrFormDetails}
					defaultOkrDesignationDetails={defaultOkrDesignationDetails}
					defaultOkPerformanceRoleDetails={defaultOkPerformanceRoleDetails}
					selectedCycleDetailsInTab={selectedCycleDetails}
					mode={editDetails ? 'Edit' : 'Add'}
					showLoader={showLoader}
					setShowLoader={setShowLoader}
					editDetails={editDetails}
					setEditDetails={setEditDetails}
					setSelectedCycle={setSelectedCycle}
					setSelectedOkrListCycleDetails={setSelectedCycleDetails}
					showApiMsg={showApiMsg}
					isDefaultOkrFormEdited={isDefaultOkrFormEdited}
					setIsDefaultOkrFormEdited={setIsDefaultOkrFormEdited}
					setHighlightDefaultOkr={setHighlightDefaultOkr}
					openDefaultObjDrawer={openDefaultObjDrawer}
					setOpenDefaultObjDrawer={setOpenDefaultObjDrawer}
					openDefaultKrDrawer={openDefaultKrDrawer}
					setOpenDefaultKrDrawer={setOpenDefaultKrDrawer}
					weightSelectionOpen={weightSelectionOpen}
					setWeightSelectionOpen={setWeightSelectionOpen}
				/>
			) : (
				<>
					<Box className='align-settings'>
						<Box className='admin-tabpanel-inner'>
							<Box className='admin-tabpanel-head'>
								<Box className='admin-tabpanel-head-left'>
									<AlignSettingsTab
										a11yProps={a11yProps}
										tabSelected={tabSelected}
										handleTabChange={handleTabChange}
										parentOrganisation={parentOrganisation}
										selectedCycleDetails={selectedCycleDetails}
										cycleDetailsForDefaultOkr={cycleDetailsForDefaultOkr}
										setSelectedCycle={setSelectedCycle}
										setSelectedCycleDetails={setSelectedCycleDetails}
										isCurrentCycle={isCurrentCycle}
										selectedCycle={selectedCycleDetails || selectedCycle}
									/>
								</Box>
								<Box className='admin-tabpanel-head-right'></Box>
							</Box>
							<SettingsTabPanel value={tabSelected} index={0}>
								<Align
									{...props}
									setShowLoader={setShowLoader}
									onCycleSelect={onCycleSelect}
									selectedCycle={selectedCycle}
									setSelectedCycle={setSelectedCycle}
									cycleList={cycleList}
									setCycleList={setCycleList}
									setDate={setDate}
									selectedDate={selectedDate}
									formEdited={formEdited}
									setFormEdited={setFormEdited}
									setOrgOriginalData={setOrgOriginalData}
									zeroNotAllowed={zeroNotAllowed}
									emailPlanningPeriodError={emailPlanningPeriodError}
									emailExecutionPeriodError={emailExecutionPeriodError}
									handleAlignSettingsSave={handleAlignSettingsSave}
									handleAlignSettingReset={handleAlignSettingReset}
									handleOrganisationIndustryChange={handleOrganisationIndustryChange}
									handleOrganisationFieldsChange={handleOrganisationFieldsChange}
									handleOrganisationFieldsBlur={handleOrganisationFieldsBlur}
									handleOrganisationToggleChange={handleOrganisationToggleChange}
									defaultOkrTagNameError={defaultOkrTagNameError}
									setDefaultOkrTagNameError={setDefaultOkrTagNameError}
									handleDefaultOkrTagNameChange={handleDefaultOkrTagNameChange}
									organisationDetails={organisationDetails}
									cycleCompletionPeriodError={cycleCompletionPeriodError}
								/>
							</SettingsTabPanel>
							<SettingsTabPanel value={tabSelected} index={1}>
								<DefaultOkr
									showLoader={showLoader}
									setShowLoader={setShowLoader}
									setListLoading={setListLoading}
									listLoading={listLoading}
									selectedCycleDetails={selectedCycleDetails}
									handleEditDefaultOkrClick={handleEditDefaultOkrClick}
									showApiMsg={showApiMsg}
									handleClickDefaultOkr={handleClickDefaultOkr}
									setHighlightDefaultOkr={setHighlightDefaultOkr}
									highlightDefaultOkr={highlightDefaultOkr}
								/>
							</SettingsTabPanel>
						</Box>
					</Box>
				</>
			)}

			{openCopyOkr && <CopyOkrMainDrawer open={openCopyOkr} />}
			{openImportOkr && <ImportOkrMainDrawer {...props} open={openImportOkr} setOpenImportOkr={setOpenImportOkr} />}

			{modalPerformanceProps && modalPerformanceProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalPerformanceProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={modalPerformanceProps.open}
				/>
			)}
		</>
	);
};
