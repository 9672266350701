import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormLabel, Tab, Tabs, Typography } from '@material-ui/core';
import CkEditor from '../../../Common/CkEditor/CkEditor';
import { PipDetails } from './PipDetails';
import LinearLoader from '../../../Common/Loader';
import {
	AreaOfConcernIcon,
	DocumentIcon,
	ImprovementGoalsIcon,
	ObjectiveAndExpectationsIcon,
	RoleExpectationsIcon,
} from '../../../../config/svg/CommonSvgIcon';
import { OKRButton } from '../../../Common/OKRButton';
import { ErrorIcon } from '../../../../config/svg/formElementIcons';
import { PipTaskDetails } from './PipTaskDetails';
import { Enums } from '../../../../config/enums';
import { DueDateIcon, TasksIcon } from '../../../../config/svg/CommonSvg';
import { AddObjectivesIcon } from '../../../../config/svg/MyGoalSvg';

export const CreatePip = (props: any) => {
	const {
		pipFormType,
		loader,
		setLoader,
		expanded,
		handleChange,
		selectedDateRange,
		setSelectedDateRange,
		pipFormDetails,
		setPIPFormDetails,
		updatePipFormDetails,
		isFormEdited,
		handleClearPIPForm,
		handleSaveAsDraftPIPForm,
		handleSubmitPIPForm,
		allTaskList,
		setAllTaskList,
		isExtendTimeFrameClicked,
		scrollTo,
		timeFrameChangeReasonTypes,
		checkPipNomineeExistApiCall,
		showMsg,
		getNomineeFeedbackList,
		selectedNomineeFeedbackList,
		setIsFormEdited,
		isFormCleared,
		setIsFormCleared,
		checkEmployeePIPCreatedOrNot,
	} = props;
	const { t } = useTranslation();
	const [createPipTab, setCreatePipTab] = useState<any>(Enums.ONE);
	const handleTabChange = (event: any, newValue: number) => {
		scrollTo('pip_review_dates_field_container');
		setCreatePipTab(newValue);
	};
	function a11yProps(index: number) {
		return {
			id: `create-pip-tab-${index}`,
			'aria-controls': `create-pip-tabpanel-${index}`,
		};
	}

	return (
		<>
			{loader && <LinearLoader />}
			<Box className='pip-section-panel full-rectangular-card'>
				<Box className='pip-section-panel-head'>
					<Typography className='font-weight-normal' variant='h4'>
						{t('detailsText')}
						{/* <i style={{ color: 'red' }}>*</i> */}
					</Typography>
				</Box>
				<PipDetails
					pipFormType={pipFormType}
					selectedDateRange={selectedDateRange}
					setSelectedDateRange={setSelectedDateRange}
					pipFormDetails={pipFormDetails}
					setPIPFormDetails={setPIPFormDetails}
					updatePipFormDetails={updatePipFormDetails}
					loader={loader}
					setLoader={setLoader}
					isExtendTimeFrameClicked={isExtendTimeFrameClicked}
					scrollTo={scrollTo}
					timeFrameChangeReasonTypes={timeFrameChangeReasonTypes}
					checkPipNomineeExistApiCall={checkPipNomineeExistApiCall}
					getNomineeFeedbackList={getNomineeFeedbackList}
					selectedNomineeFeedbackList={selectedNomineeFeedbackList}
					showMsg={showMsg}
					setIsFormEdited={setIsFormEdited}
					isFormCleared={isFormCleared}
					setIsFormCleared={setIsFormCleared}
					checkEmployeePIPCreatedOrNot={checkEmployeePIPCreatedOrNot}
				/>
			</Box>
			<Box className='create-pip-tabs'>
				<Box className='create-pip-tab-head'>
					<Tabs
						className='okr-tab-button okr-tab-white-button'
						value={createPipTab}
						onChange={handleTabChange}
						aria-label='User Tabs'
					>
						<Tab
							label={
								<>
									<ObjectiveAndExpectationsIcon />
									{t('objectiveAndExpectations')}
								</>
							}
							value={Enums.ONE}
							{...a11yProps(Enums.ONE)}
						/>
						<Tab
							label={
								<>
									<TasksIcon />
									{t('tasksLabel')}
								</>
							}
							value={Enums.TWO}
							{...a11yProps(Enums.TWO)}
						/>
					</Tabs>
				</Box>
				<Box className='create-pip-tab-content'>
					{createPipTab === Enums.ONE && (
						<Box className='pip-section-panel full-rectangular-card'>
							<Box className='pip-editor-panel' id='area-of-concern'>
								<FormLabel>
									<AreaOfConcernIcon />
									{t('areaOfConcern')}
									<i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<Box className='helperText-bottom'>
									<CkEditor
										placeHolderText={t('areaOfConcernPlaceholder')}
										showEmoticons={true}
										value={pipFormDetails?.areasOfConcern || ''}
										handleEditorChange={(value: string) => {
											if (pipFormDetails?.areasOfConcern !== value) {
												updatePipFormDetails('areasOfConcern', value || '');
											}
										}}
									/>
									{pipFormDetails?.areasOfConcernError ? (
										<Typography className='error-field'>
											<ErrorIcon /> {pipFormDetails?.areasOfConcernError}
										</Typography>
									) : (
										<></>
									)}
								</Box>
							</Box>
							<Box className='pip-editor-panel' id='role-expectations'>
								<FormLabel>
									<RoleExpectationsIcon />
									{t('roleExpectationsLabel')}
									<i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<Box className='helperText-bottom'>
									<CkEditor
										placeHolderText={t('roleExpectationsPlaceholder')}
										showEmoticons={true}
										value={pipFormDetails?.roleExpectations || ''}
										handleEditorChange={(value: string) => {
											if (pipFormDetails?.roleExpectations !== value) {
												updatePipFormDetails('roleExpectations', value || '');
											}
										}}
									/>
									{pipFormDetails?.roleExpectationsError ? (
										<Typography className='error-field'>
											<ErrorIcon /> {pipFormDetails?.roleExpectationsError}
										</Typography>
									) : (
										<></>
									)}
								</Box>
							</Box>
							<Box className='pip-editor-panel' id='improvement-goals'>
								<FormLabel>
									<ImprovementGoalsIcon />
									{t('improvementGoalsLabel')}
									<i style={{ color: 'red' }}>*</i>
								</FormLabel>
								<Box className='helperText-bottom'>
									<CkEditor
										placeHolderText={t('improvementGoalsWaterMarks')}
										showEmoticons={true}
										value={pipFormDetails?.improvementGoals || ''}
										handleEditorChange={(value: string) => {
											if (pipFormDetails?.improvementGoals !== value) {
												updatePipFormDetails('improvementGoals', value || '');
											}
										}}
									/>
									{pipFormDetails?.improvementGoalsError ? (
										<Typography className='error-field'>
											<ErrorIcon /> {pipFormDetails?.improvementGoalsError}
										</Typography>
									) : (
										<></>
									)}
								</Box>
							</Box>
						</Box>
					)}
					{createPipTab === Enums.TWO && (
						<Box className='pip-tasks-area'>
							{(pipFormType === 'Edit' && pipFormDetails?.performanceImprovementPlanId) || pipFormType === 'Add' ? (
								<PipTaskDetails
									t={t}
									pipFormType={pipFormType}
									loader={loader}
									setLoader={setLoader}
									pipFormDetails={pipFormDetails}
									setPIPFormDetails={setPIPFormDetails}
									updatePipFormDetails={updatePipFormDetails}
									allTaskList={allTaskList}
									setAllTaskList={setAllTaskList}
									showMsg={showMsg}
									setIsFormEdited={setIsFormEdited}
								/>
							) : (
								<></>
							)}
						</Box>
					)}
				</Box>
			</Box>
			{/* <Accordion
				className='pip-accordion-panel full-rectangular-card'
				square
				expanded={expanded.includes(1)}
				onChange={(e: any) => handleChange(e, 1)}
			>
				<AccordionSummary expandIcon={<RightArrowIcon />} aria-controls='panel1d-content' id='panel1d-header'>
					<Typography className='font-weight-normal' variant='h4'>
						{t('detailsText')}
						<i style={{ color: 'red' }}>*</i>
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<PipDetails
						pipFormType={pipFormType}
						selectedDateRange={selectedDateRange}
						setSelectedDateRange={setSelectedDateRange}
						pipFormDetails={pipFormDetails}
						setPIPFormDetails={setPIPFormDetails}
						updatePipFormDetails={updatePipFormDetails}
						loader={loader}
						setLoader={setLoader}
						isExtendTimeFrameClicked={isExtendTimeFrameClicked}
						scrollTo={scrollTo}
						timeFrameChangeReasonTypes={timeFrameChangeReasonTypes}
						checkPipNomineeExistApiCall={checkPipNomineeExistApiCall}
						getNomineeFeedbackList={getNomineeFeedbackList}
						selectedNomineeFeedbackList={selectedNomineeFeedbackList}
						showMsg={showMsg}
						setIsFormEdited={setIsFormEdited}
						isFormCleared={isFormCleared}
						setIsFormCleared={setIsFormCleared}
						checkEmployeePIPCreatedOrNot={checkEmployeePIPCreatedOrNot}
					/>
				</AccordionDetails>
			</Accordion> */}
			{/* <Accordion
				className='pip-accordion-panel full-rectangular-card'
				square
				expanded={expanded.includes(2)}
				onChange={(e: any) => handleChange(e, 2)}
			>
				<AccordionSummary expandIcon={<RightArrowIcon />} aria-controls='panel2d-content' id='panel2d-header'>
					<Typography className='font-weight-normal' variant='h4'>
						{t('areaOfImprovements')}
						<i style={{ color: 'red' }}>*</i>
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box className='pip-improvements-area' id='pip_area_of_improvements_container'>
						<Box className='helperText-bottom'>
							<CkEditor
								placeHolderText={t('areaOfConcernPlaceholder')}
								showEmoticons={true}
								value={pipFormDetails?.areasOfConcern || ''}
								handleEditorChange={(value: string) => {
									if (pipFormDetails?.areasOfConcern !== value) {
										updatePipFormDetails('areasOfConcern', value || '');
									}
								}}
							/>
							{pipFormDetails?.areasOfConcernError ? (
								<Typography className='error-field'>
									<ErrorIcon /> {pipFormDetails?.areasOfConcernError}
								</Typography>
							) : (
								<></>
							)}
						</Box>
					</Box>
				</AccordionDetails>
			</Accordion> */}
			{/* <Accordion
				className='pip-accordion-panel full-rectangular-card'
				square
				expanded={expanded.includes(3)}
				onChange={(e: any) => handleChange(e, 3)}
			>
				<AccordionSummary expandIcon={<RightArrowIcon />} aria-controls='panel3d-content' id='panel3d-header'>
					<Typography className='font-weight-normal' variant='h4'>
						{t('tasksLabel')}
					</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Box className='pip-tasks-area'>
						{(pipFormType === 'Edit' && pipFormDetails?.performanceImprovementPlanId) || pipFormType === 'Add' ? (
							<PipTaskDetails
								t={t}
								pipFormType={pipFormType}
								loader={loader}
								setLoader={setLoader}
								pipFormDetails={pipFormDetails}
								setPIPFormDetails={setPIPFormDetails}
								updatePipFormDetails={updatePipFormDetails}
								allTaskList={allTaskList}
								setAllTaskList={setAllTaskList}
								showMsg={showMsg}
								setIsFormEdited={setIsFormEdited}
							/>
						) : (
							<></>
						)}
					</Box>
				</AccordionDetails>
			</Accordion> */}
			{/* <Box className='pip-improvements-area' id='pip_role_expectations_container'>
				<FormLabel>
					{t('roleExpectationsLabel')}
					<i style={{ color: 'red' }}>*</i>
				</FormLabel>
				<Box className='helperText-bottom'>
					<CkEditor
						placeHolderText={t('roleExpectationsPlaceholder')}
						showEmoticons={true}
						value={pipFormDetails?.roleExpectations || ''}
						handleEditorChange={(value: string) => {
							if (pipFormDetails?.roleExpectations !== value) {
								updatePipFormDetails('roleExpectations', value || '');
							}
						}}
					/>
					{pipFormDetails?.roleExpectationsError ? (
						<Typography className='error-field'>
							<ErrorIcon /> {pipFormDetails?.roleExpectationsError}
						</Typography>
					) : (
						<></>
					)}
				</Box>
			</Box>
			<Box className='pip-improvements-area' id='pip_area_of_improvements_container'>
				<FormLabel>
					{t('improvementGoalsLabel')}
					<i style={{ color: 'red' }}>*</i>
				</FormLabel>
				<Box className='helperText-bottom'>
					<CkEditor
						placeHolderText={t('improvementGoalsWaterMarks')}
						showEmoticons={true}
						value={pipFormDetails?.improvementGoals || ''}
						handleEditorChange={(value: string) => {
							if (pipFormDetails?.improvementGoals !== value) {
								updatePipFormDetails('improvementGoals', value || '');
							}
						}}
					/>
					{pipFormDetails?.improvementGoalsError ? (
						<Typography className='error-field'>
							<ErrorIcon /> {pipFormDetails?.improvementGoalsError}
						</Typography>
					) : (
						<></>
					)}
				</Box>
			</Box> */}
			<Box className='pip-form-action'>
				<Box>
					{/* <OKRButton
						className={'btn-link'}
						id='pip_create_clear_form'
						text={t('clearForm')}
						disabled={loader || !isFormEdited}
						handleClick={handleClearPIPForm}
					/> */}
				</Box>
				<Box>
					{pipFormDetails?.performanceImprovementPlanStatusId === Enums.ONE || pipFormType === 'Add' ? (
						<OKRButton
							className={'btn-link'}
							id='pip_create_saveAsDraft_form'
							text={t('saveDraft')}
							disabled={loader}
							handleClick={(e: any) => handleSaveAsDraftPIPForm(e, pipFormType)}
						/>
					) : (
						<></>
					)}
					<OKRButton
						className={'btn-primary'}
						id='pip_create_submit_form'
						text={t('submit')}
						disabled={loader}
						handleClick={(e: any) => handleSubmitPIPForm(e, pipFormType)}
					/>
				</Box>
			</Box>
		</>
	);
};
