import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Collapse, Link, Tooltip, Typography } from '@material-ui/core';
// import { Rating, IconContainerProps } from '@material-ui/lab';
// import { StarBorderIcon, StarFilledIcon } from '../../../config/svg/RatingSvg';
// import { getPerformanceRatings } from '../../../config/utils';
// import CkEditor from '../../Common/CkEditor/CkEditor';
// import { CommentIcon } from '../../../config/svg/CommonSvg';
import { OKRButton } from '../../Common/OKRButton';
import { Enums } from '../../../config/enums';
import { StarRating } from '../../Common/PerformanceComponent/StarRating';
import { MultiChoiceRating } from '../../Common/PerformanceComponent/MultiChoiceRating';
import { SliderRating } from '../../Common/PerformanceComponent/SliderRating';
import { ShortAnswerRating } from '../../Common/PerformanceComponent/ShortAnswerRating';
import { RightArrowIcon } from '../../../config/svg/ArrowSvg';
import { NoRecordPerformance } from '../NoRecordPerformance';
import { getLocalStorageItem } from '../../../services/StorageService';
import { InfoprolearningDomainName } from '../../../config/constant';
import { ManagerAssessmentGuideTemplateUrl, SelfAssessmentGuideTemplateUrl } from '../../../config/url';
import { DownloadIcon } from '../../../config/svg/CommonSvg';
import { useDispatch, useSelector } from 'react-redux';
import { aiAuthorLogin } from '../../../action/aiBot';
import { aiBotOKRDataReader } from '../../../action/aiBot';
import { ubotOKRORGID, ubotOKRProjectID, ubotOKRORGSecretKey } from '../../../config/url';
import { useSnackbar } from 'notistack';
import { getDownloadAssessmentData } from '../../../action/UserPerformance';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { AssessmentDetailPdf } from '../TeamAssessment/AssessmentDetailPdf';
import { formatDateWithoutSpace } from '../../../config/utils';

export const UserAssessmentForm = (props: any) => {
	const dispatch = useDispatch();
	const { performanceCycle } = useSelector((state: any) => state.performReducer);
	const [userId, setUserId] = useState<any>(null);
	// console.log(ubotOKRORGID);
	const {
		// performanceCycle,
		// defaultAssessmentFormDetails,
		assessmentFormDetails,
		// questionTypeMasterData,
		callingFrom, // AdminAssessmentView
		handleClearAssessmentForm,
		handleSaveAsDraftAssessmentForm,
		handleSubmitAssessmentForm,
		handleChangeQuestionComment,
		handleChangeShortQuestionValue,
		handleChangeStarQuestionValue,
		handleChangeStarQuestionHoverValue,
		handleChangeScaleQuestionValue,
		handleChangeMultiChoiceQuestionValue,
		handleCommentsToggle,
		loader,
		isFormEdited,
		isViewMode = false,
		isAssessmentPopup = false,
		handleSectionToggle = () => { },
		handleOneOnOneClick = () => { },
		disableDraftBtn = false,
		selfAssessmentVisibilityData,
		selectedUserAssessment,
	} = props;
	const { t } = useTranslation();
	const tenantData = JSON.parse(getLocalStorageItem('TenantData') || '{}');
	const [assesmentPdfData, setAssesmentPdfData] = useState<any>(null); // or useState({})
	const { enqueueSnackbar } = useSnackbar();
	const [showDownload, setShowDownload] = useState(false);

	// const [commentsCollapse, setCommentsCollapse] = useState<any[]>([]);
	// const [sliderValue, setSliderValue] = useState<any>(0);

	// const handleCommentsToggle = (e: any, performanceRoleLinkedFormDetailsId: any) => {
	// 	e.preventDefault();
	// 	e.stopPropagation();
	// 	if (commentsCollapse.findIndex((val: any) => val === performanceRoleLinkedFormDetailsId) > -1) {
	// 		const updatedCommentsCollapse = commentsCollapse.filter(
	// 			(item: any) => item !== performanceRoleLinkedFormDetailsId
	// 		);
	// 		setCommentsCollapse([...updatedCommentsCollapse]);
	// 	} else {
	// 		setCommentsCollapse([...commentsCollapse, performanceRoleLinkedFormDetailsId]);
	// 	}
	// };

	const getLabelText = (value: number, labels: any) => {
		return `${labels[value - 1]?.name || ''}`;
	};
	const getDynamicMarks = (marksLength: any, ratingValue: any) => {
		const marks = marksLength?.map((item: any, index: number) => ({
			...item,
			value: index + 1,
			label:
				index === 0 || index === marksLength?.length - 1 ? (
					<span
						className={`${ratingValue ? '' : 'show_slider_label'} ${!ratingValue ? '' : ratingValue - 1 === index ? 'show-selected-label-value' : 'hide_slider_label'
							}`}
					>
						{item?.name || index.toString()}
					</span>
				) : (
					<span
						className={`${ratingValue ? '' : 'show_slider_label'} ${!ratingValue ? '' : ratingValue - 1 === index ? 'show-selected-label-value' : 'hide_slider_label'
							}`}
					>
						{item?.name || index.toString()}
					</span>
				),
		}));
		marks.unshift({
			value: 0,
			label: '',
		});
		return marks;
	};
	const getSelectedScaleValueIndex = (selectedScaleId: any, scaleItems: any) => {
		const marks = scaleItems?.findIndex(
			(item: any) => item?.performanceRoleLinkedFormScaleDetailId === selectedScaleId
		);
		return marks > -1 ? marks + 1 : 0;
	};
	const getSelectedStartValueIndex = (selectedStartId: any, starItem: any) => {
		const marks = starItem?.findIndex((item: any) => item?.performanceRoleLinkedFormScaleDetailId === selectedStartId);
		// console.log(selectedStartId, starItem, marks);
		return marks > -1 ? marks + 1 : 0;
	};
	const getSelectedMultiChoiceValue = (selectedScaleId: any, performanceRoleLinkedFormScaleDetailId: any) => {
		return Boolean(selectedScaleId === performanceRoleLinkedFormScaleDetailId) || false;
	};
	const getQuestionNumber = (section: any, question: any, sectionIndex: number, questionIndex: number) => {
		// let quesNum = 0;
		// if (sectionIndex === 0) {
		// 	quesNum = quesNum + questionIndex + 1;
		// } else {
		// 	const data = assessmentFormDetails[sectionIndex - 1]?.performanceRoleLinkedFormDetails?.length;
		// 	quesNum = quesNum + data + questionIndex + 1;
		// }

		return `${question?.questionNumber || 0}. `;
	};

	useEffect(() => {
		async function authenticateUser() {
			try {
				let userData = {
					emailId: selectedUserAssessment.emailId,
					password: 'aiauthor@@1234',
					organizationId: ubotOKRORGID,
					isSSO: false,
					projectId: ubotOKRProjectID,
					OrgSecretKey: ubotOKRORGSecretKey
				};

				const actionResult: any = await dispatch(aiAuthorLogin(userData));
				if (actionResult && actionResult.data) {
					setUserId(actionResult.data.entity.userId);
				}
			} catch (error) {
				console.error("Error:", error);
			}
		}
		if (selectedUserAssessment) {
			authenticateUser();
		}
	}, [selectedUserAssessment]);

	useEffect(() => {
		async function okrDataReader() {
			try {
				let userData = {
					orgId: ubotOKRORGID,
					project_id: ubotOKRProjectID,
					userId: userId,
					module_name: 'PMS',
					pms_request: {
						employeeId: selectedUserAssessment.employeeId,
						cycleId: performanceCycle.performanceCycleId,
					},
				};

				const actionDataReader: any = await dispatch(aiBotOKRDataReader(userData));
				if (actionDataReader && actionDataReader.data) {
					console.log(actionDataReader.data);
				}
			} catch (error) {
				console.error('Error:', error);
			}
		}
		if (userId) {
			console.log("", userId);
			okrDataReader();
		}
	}, [userId]);
	const fetchOkrDetail = async (e: any, user: any) => {
		try {
			const requestParams = `empId=${user?.employeeId}&performanceCycleId=${performanceCycle.performanceCycleId}`;
			const response1: any = await dispatch(getDownloadAssessmentData(requestParams));
			if (response1 && response1?.data?.status === Enums.STATUS_SUCCESS) {
				const updatedData = { ...response1.data.entity }; // Make a shallow copy of the data to update
				//updated selfAssessment isSelected flag based on rating value
				updatedData.selfAssessment.performanceRoleLinkedFormSection.forEach((section: any) => {
					section.performanceRoleLinkedFormDetails.forEach((detail: any) => {
						const { performanceRoleLinkedFormScales, ratingValue } = detail;

						if (performanceRoleLinkedFormScales && ratingValue) {
							const scaleDetails = performanceRoleLinkedFormScales.performanceRoleLinkedFormScaleDetails;

							// Using a for loop for early exit and to avoid multiple iterations
							for (const scaleDetail of scaleDetails) {
								if (scaleDetail.name.toLowerCase() === ratingValue.toLowerCase()) {
									scaleDetail.isSelected = true;
									break; // Stop loop once we find the matching scale detail
								}
							}
						}
					});
				});
				//updated managerAssessment isSelected flag based on rating value
				updatedData.managerAssessment.performanceRoleLinkedFormSection.forEach((section: any) => {
					section.performanceRoleLinkedFormDetails.forEach((detail: any) => {
						const { performanceRoleLinkedFormScales, ratingValue } = detail;

						if (performanceRoleLinkedFormScales && ratingValue) {
							const scaleDetails = performanceRoleLinkedFormScales.performanceRoleLinkedFormScaleDetails;

							// Using a for loop for early exit and to avoid multiple iterations
							for (const scaleDetail of scaleDetails) {
								if (scaleDetail.name.toLowerCase() === ratingValue.toLowerCase()) {
									scaleDetail.isSelected = true;
									break; // Stop loop once we find the matching scale detail
								}
							}
						}
					});
				});
				setAssesmentPdfData({
					...updatedData,
					loggedInEmployeeTasks: [],
					otherEmployeeTasks: [],
					userDetails: user,
				});
				//setLoader(false);
			} else {
				//setLoader(false);
			}
		} catch (error) {
			//setLoader(false);
			setShowDownload(false);
			//setMeetingDetail('');
			enqueueSnackbar('Error occurred while downloading Meeting Notes', {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};

	const textInput: any = useRef();
	useEffect(() => {
		if (assesmentPdfData) {
			setShowDownload(true);
			setTimeout(() => {
				textInput.current.click();
				//setLoader(false);
			}, 1000);
		}
	}, [assesmentPdfData]);
	const downloadPdf = async (e: any, user: any) => {
		//setLoader(true);	e.preventDefault();

		setAssesmentPdfData(null);
		setShowDownload(false);
		fetchOkrDetail(e, user); // Ensure fetchOkrDetail completes
	};

	return (
		<Box className='user-assessment-section-area'>
			<Box className='user-assessment-guide-link'>
				{tenantData && tenantData.domainName === InfoprolearningDomainName ? (
					selfAssessmentVisibilityData?.selfAssessment ? (
						<>
							<Typography variant='h5'>
								<Link
									id={'download-temp-self-assess-guide'}
									target='_blank'
									rel='noopener noreferrer'
									href={SelfAssessmentGuideTemplateUrl}
								>
									<span>
										<DownloadIcon />
									</span>
									{t('Self Assessment Guide')}
								</Link>
							</Typography>
						</>
					) : (
						<>
							<Typography variant='h5'>
								<Link
									id={'employee_overview'}
									//target='_blank'
									rel='noopener noreferrer'
									//ref={textInput}
									//href={ManagerAssessmentGuideTemplateUrl}
									onClick={(e) => downloadPdf(e, selectedUserAssessment)}
								>
									<span>
										<DownloadIcon />
									</span>
									{t('Employee Overview')}
								</Link>
							</Typography>

							{selectedUserAssessment && selectedUserAssessment?.piFilePath ? (
								<Typography variant='h5'>
									<Link
										id={'download-pi-form'}
										target='_blank'
										rel='noopener noreferrer'
										href={selectedUserAssessment?.piFilePath}
									>
										<span>
											<DownloadIcon />
										</span>
										{t('PI Report')}
									</Link>
								</Typography>
							) : (
								<></>
							)}
							<Typography variant='h5'>
								<Link
									id={'download-temp-manager-assess-guide'}
									target='_blank'
									rel='noopener noreferrer'
									href={ManagerAssessmentGuideTemplateUrl}
								>
									<span>
										<DownloadIcon />
									</span>
									{t('Manager Guide')}
								</Link>
							</Typography>
						</>
					)
				) : (
					<></>
				)}
			</Box>
			{assessmentFormDetails && assessmentFormDetails?.length ? (
				assessmentFormDetails?.map((section: any, sectionIndex: number) => {
					const { performanceRoleLinkedFormSectionId = 0, performanceRoleLinkedFormDetails } = section;
					return (
						<Box
							className='user-assessment-ques-area'
							key={`section_index_value_${performanceRoleLinkedFormSectionId}`}
						>
							<Box
								className={`user-assessment-ques-head ${section?.isSectionExpand ? 'expand-head' : 'collapse-head'}`}
							>
								{isAssessmentPopup ? (
									<>
										<Tooltip arrow title={section?.isSectionExpand ? t('collapseLabel') : t('expendLabel')}>
											<Box
												className={`rotating-arrow ${section?.isSectionExpand ? 'expand' : 'collapse'}`}
												onClick={(e: any) => handleSectionToggle(e, sectionIndex)}
											>
												<RightArrowIcon />
											</Box>
										</Tooltip>
									</>
								) : (
									<></>
								)}
								<Typography variant='h4'>{section?.name || ''}</Typography>
								<Typography>{section?.description || ''}</Typography>
							</Box>
							{performanceRoleLinkedFormDetails && performanceRoleLinkedFormDetails?.length ? (
								performanceRoleLinkedFormDetails.map((question: any, questionIndex: number) => {
									const { questionsType } = question;
									return (
										<Collapse
											in={isAssessmentPopup ? section?.isSectionExpand : true}
											className='assessment-comments-field'
										>
											<Box
												className='user-assess-ques-list'
												key={`section_question_index_value_${performanceRoleLinkedFormSectionId}_${question?.performanceRoleLinkedFormDetailsId}_${question?.ratingAssessmentId}`}
												id={`section_question_index_value_${performanceRoleLinkedFormSectionId}_${question?.performanceRoleLinkedFormDetailsId}_${question?.ratingAssessmentId}`}
											>
												{questionsType === Enums.ONE ? (
													<>
														<StarRating
															t={t}
															section={section}
															sectionIndex={sectionIndex}
															questionIndex={questionIndex}
															question={question}
															getQuestionNumber={getQuestionNumber}
															isViewMode={isViewMode}
															getSelectedStartValueIndex={getSelectedStartValueIndex}
															getLabelText={getLabelText}
															handleChangeStarQuestionValue={handleChangeStarQuestionValue}
															handleChangeStarQuestionHoverValue={handleChangeStarQuestionHoverValue}
															handleCommentsToggle={handleCommentsToggle}
															handleChangeQuestionComment={handleChangeQuestionComment}
															callingFrom={callingFrom}
														/>
													</>
												) : questionsType === Enums.TWO ? (
													<>
														<MultiChoiceRating
															t={t}
															section={section}
															sectionIndex={sectionIndex}
															questionIndex={questionIndex}
															question={question}
															getQuestionNumber={getQuestionNumber}
															isViewMode={isViewMode}
															handleCommentsToggle={handleCommentsToggle}
															handleChangeQuestionComment={handleChangeQuestionComment}
															handleChangeMultiChoiceQuestionValue={handleChangeMultiChoiceQuestionValue}
															getSelectedMultiChoiceValue={getSelectedMultiChoiceValue}
															callingFrom={callingFrom}
														/>
													</>
												) : questionsType === Enums.THREE ? (
													<>
														<SliderRating
															t={t}
															section={section}
															sectionIndex={sectionIndex}
															questionIndex={questionIndex}
															question={question}
															getQuestionNumber={getQuestionNumber}
															isViewMode={isViewMode}
															handleCommentsToggle={handleCommentsToggle}
															handleChangeQuestionComment={handleChangeQuestionComment}
															getSelectedScaleValueIndex={getSelectedScaleValueIndex}
															getDynamicMarks={getDynamicMarks}
															handleChangeScaleQuestionValue={handleChangeScaleQuestionValue}
															callingFrom={callingFrom}
														/>
													</>
												) : questionsType === Enums.FOUR ? (
													<>
														<ShortAnswerRating
															t={t}
															section={section}
															sectionIndex={sectionIndex}
															questionIndex={questionIndex}
															question={question}
															getQuestionNumber={getQuestionNumber}
															isViewMode={isViewMode}
															handleChangeShortQuestionValue={handleChangeShortQuestionValue}
															callingFrom={callingFrom}
															selectedUserAssessment={selectedUserAssessment}
															userId={userId}
														/>
													</>
												) : (
													<></>
												)}
											</Box>
										</Collapse>
									);
								})
							) : (
								<></>
							)}
						</Box>
					);
				})
			) : assessmentFormDetails && assessmentFormDetails?.length === 0 ? (
				<NoRecordPerformance message={'No data found self'} handleOneOnOneClick={handleOneOnOneClick} />
			) : !assessmentFormDetails ? (
				<Box className='loading-center-type1'>Loading</Box>
			) : (
				<></>
			)}
			{assessmentFormDetails && assessmentFormDetails?.length && !isViewMode ? (
				<>
					<Box className='user-assessment-notes-txt'>
						<Typography variant='subtitle2'>
							{t('note')} {t('prsResponseModifyNote')}
						</Typography>
					</Box>
					<Box className='user-assessment-action'>
						<OKRButton
							className={'btn-link'}
							id={'btn-delete'}
							text={t('clearForm')}
							disabled={loader || !isFormEdited}
							handleClick={handleClearAssessmentForm}
						/>
						<Box>
							{!disableDraftBtn && (
								<OKRButton
									className={'btn-link'}
									id={'btn-save-draft'}
									text={t('saveDraft')}
									disabled={loader}
									handleClick={handleSaveAsDraftAssessmentForm}
								/>
							)}
							<OKRButton
								className={'btn-primary'}
								id={'btn-submit'}
								text={t('submit')}
								disabled={loader}
								handleClick={handleSubmitAssessmentForm}
							/>
						</Box>
					</Box>
				</>
			) : (
				<></>
			)}
			{showDownload && assesmentPdfData && (
				<PDFDownloadLink
					document={assesmentPdfData && <AssessmentDetailPdf data={assesmentPdfData} {...props} />}
					fileName={`${
						assesmentPdfData?.userDetails?.firstName + '_' + assesmentPdfData?.userDetails?.lastName
					}'s_Overview_${formatDateWithoutSpace(new Date())}.pdf`}
					style={{ display: 'none' }}
				>
					{
						<Tooltip title={'Employee_Overview'} arrow>
							<Button className='download-btn' ref={textInput}>
								<DownloadIcon />
							</Button>
						</Tooltip>
					}
				</PDFDownloadLink>
			)}
		</Box>
	);
};
