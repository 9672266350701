import { Avatar, Box, Chip, InputAdornment, List, ListItem, TextField, Tooltip, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { checkPermission } from '../../../config/rolePermission';
import { USER_ROLE_ID } from '../../../config/users';
import { getUserName } from '../../../config/utils';
import { OKRButton } from '../../Common/OKRButton';
import { editExistingRole } from '../AdminConstant';
import { NoRecord } from '../CommonComponent/NoRecord';
import '../../../styles/pages/admin/manageUserDrawer.scss';
import {
	AddIconSvg,
	CrossIcon,
	SortingIcon,
	SearchIcon,
	StarIcon,
	DisableIcon,
} from '../../../config/svg/CommonSvgIcon';

export const ManageUserDrawerForm: React.FC<any> = (props) => {
	const {
		searchUser,
		searchEmployeeText,
		handleAssignModal,
		assignedUser,
		handleDeleteUser,
		handleSortManageUsers,
		roleData,
		getPermissionResult,
		loadMoreUser,
		currentRole,
		pageIndex,
		teamDetails,
	} = props;
	const { t } = props;
	return (
		<Box className='manage-user-drawer'>
			<Box className='drawer-input-field add-user-field'>
				<OKRButton
					className='add-btn2'
					id={'add-member-btn'}
					handleClick={handleAssignModal}
					icon={<AddIconSvg />}
					color='primary'
					disabled={!checkPermission(getPermissionResult?.employeePermissions, editExistingRole)}
				/>
				<Typography variant='subtitle1'>
					<em>{t('addMembersLabel')}</em>
				</Typography>
			</Box>
			<Fragment>
				<Box className='drawer-input-field drawer-user-search-field'>
					<TextField
						className='user-search-field'
						id='user-src-field'
						placeholder={t('searchMember')}
						fullWidth
						onChange={searchUser}
						value={searchEmployeeText}
						inputProps={{
							'aria-label': 'search',
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position='start'>
									<SearchIcon />
								</InputAdornment>
							),
						}}
					/>
				</Box>
				{assignedUser.length > 0 ? (
					<Box
						className={
							props.view !== 'editOrganisation' && props.view !== 'editTeam'
								? 'users-listing users-listing-three-col'
								: 'users-listing'
						}
					>
						{roleData && roleData.roleId > 0 ? (
							<Box className='userCount'>
								<Typography variant='subtitle2'>{`(${assignedUser.length} of ${roleData?.totalRecords} ${t(
									'membersLabel'
								)})`}</Typography>
							</Box>
						) : (
							<Box className='userCount'>
								<Typography variant='subtitle2'>{`(${assignedUser.length} ${t('membersLabel')})`}</Typography>
							</Box>
						)}

						<Fragment>
							<Box className='okr-list-panel-head'>
								<List component='ul' disablePadding>
									<ListItem className='user-col1'>
										<Typography
											variant='body2'
											className={`sort-title ${props.sortOrder === 'asc' ? 'desc' : ''}`}
											onClick={handleSortManageUsers}
											id='sort-name'
										>
											<span className='sortable-label'>
												<span className='txt-wrap'>
													{t('nameLabel')} <SortingIcon />
												</span>
											</span>
										</Typography>
									</ListItem>
									<ListItem className='user-col2'>
										<Typography variant='body2' className={'sort-title'} id='sort-email'>
											<span className={`sortable-label `}>
												<span className='txt-wrap'>{t('emailLabel')}</span>
											</span>
										</Typography>
									</ListItem>
									{props.view !== 'editOrganisation' && props.view !== 'editTeam' && (
										<>
											<ListItem className='user-col3'>
												<Typography variant='body2' className={'sort-title'} id='sort-role'>
													<span className={`sortable-label `}>
														<span className='txt-wrap'>{t('roleLabel')}</span>
													</span>
												</Typography>
											</ListItem>
											<ListItem className='user-col4'></ListItem>
										</>
									)}
								</List>
							</Box>
							<Box className='users-list-content'>
								{assignedUser.map((item: any, index: number) => (
									<Fragment>
										{!item.isRemoved && (
											<Box
												className='users-list-row'
												key={`selectedUser${item.employeeId}`}
												id={`selected-user-${item.employeeId}`}
											>
												<List component='ul' disablePadding>
													<ListItem className='user-col1'>
														<Box className='user-info'>
															<Box className='user-img'>
																<Avatar
																	className='avatar-default'
																	key={`user-icon-${item.employeeId}`}
																	src={item.imagePath || ''}
																>
																	{getUserName({
																		firstName: item.firstName || '',
																		lastName: item.lastName || '',
																		fullName: item.label || '',
																	})}
																</Avatar>
															</Box>
															<Box className='user-info-details'>
																<Typography variant='h4'>
																	{item.firstName} {item.lastName}
																</Typography>
																<Typography variant='subtitle2'>{item.designation}</Typography>
															</Box>
														</Box>
													</ListItem>
													<ListItem className='user-col2'>
														{item?.emailId && item?.emailId.length > 32 ? (
															<Tooltip arrow title={item?.emailId} aria-label='Email'>
																<Typography variant='h4'>{item?.emailId.substring(0, 32)}...</Typography>
															</Tooltip>
														) : (
															<Typography variant='h4'>{item?.emailId}</Typography>
														)}
														{props.view === 'editTeam' &&
														checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ? (
															<OKRButton
																handleClick={(e) =>
																	teamDetails?.teamHeadId !== item?.employeeId && handleDeleteUser(e, index)
																}
																icon={teamDetails?.teamHeadId === item?.employeeId ? <DisableIcon /> : <CrossIcon />}
																disabled={roleData?.roleId === USER_ROLE_ID}
																className={
																	teamDetails?.teamHeadId === item?.employeeId ? 'btn-cross btn-disable' : 'btn-cross'
																}
																title={teamDetails?.teamHeadId === item?.employeeId ? t('disableUserDeleteMsg') : ''}
															/>
														) : (
															<></>
														)}
													</ListItem>
													{props.view !== 'editOrganisation' && props.view !== 'editTeam' ? (
														<>
															<ListItem className='user-col3'>
																<Box className='chip-group'>
																	{roleData?.roleName && (
																		<Chip
																			className={item.isIndividualPermission ? 'chip chip-star' : 'chip'}
																			label={
																				<>
																					{roleData?.roleName}
																					{item.isIndividualPermission && (
																						<sup>
																							<StarIcon />
																						</sup>
																					)}
																				</>
																			}
																			component='h2'
																		/>
																	)}
																</Box>
															</ListItem>
															<ListItem className='user-col4'>
																{checkPermission(getPermissionResult?.employeePermissions, editExistingRole) ? (
																	<Tooltip
																		arrow
																		title={roleData?.roleId === USER_ROLE_ID ? t('disabledRemovePermission') : ''}
																	>
																		<OKRButton
																			handleClick={(e) => handleDeleteUser(e, index)}
																			icon={<CrossIcon />}
																			disabled={roleData?.roleId === USER_ROLE_ID}
																			className={'btn-cross'}
																		/>
																	</Tooltip>
																) : (
																	<></>
																)}
															</ListItem>
														</>
													) : (
														<></>
													)}
												</List>
											</Box>
										)}
									</Fragment>
								))}
								{currentRole && currentRole?.totalPages !== pageIndex && roleData?.roleId > 0 && (
									<Box className='alignCenter' display={'flex'} justifyContent={'center'}>
										<OKRButton
											id='load-more'
											className='btn-link'
											handleClick={loadMoreUser}
											text={'Load More...'}
										></OKRButton>
									</Box>
								)}
							</Box>
						</Fragment>
					</Box>
				) : (
					<NoRecord />
				)}
			</Fragment>
		</Box>
	);
};
