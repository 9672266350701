import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { OKRButton } from '../../Common/OKRButton';
import NotYetCheckedIn from '../../../images/not-yet-checked-in.svg';
import CheckInsMissed from '../../../images/check-ins-missed.svg';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import { Enums } from '../../../config/enums';
import AlertDialog from '../../Common/Dialog';
import CkEditor from '../../Common/CkEditor/CkEditor';
import { WeeklyTasks } from './WeeklyTasks';
import { roleRemovePlugin } from '../../Common/CkEditor/CkEditorEnums';
import { ImportTaskIcon } from '../../../config/svg/CommonSvg';
// import { TickIcon } from '../../../config/svg/CommonSvgIcon';
import { checkUnSavePopUpVisible } from '../../../config/utils';
import { WeeklyTaskDetails } from './WeeklyTaskDetails';
import { CancelIcon, TickIcon } from '../../../config/svg/Action';

export const QuesAns: React.FC<any> = (props) => {
	const {
		checkInData,
		handleQuestionChange,
		handleCheckInUpdate,
		canCheckInEdit,
		viewToShow,
		editCheckIn,
		isEdit,
		// showQuestionHeading,
		selectedUser,
		selectedDate,
		setIsDataSaving,
		defaultCheckInData,
		isDataSaving,
		fetchCheckInTaskData,
		getCheckInDatesFromTask,
		// =================================
		defaultTaskListRequest,
		globalTaskRequest,
		setGlobalTaskRequest,
		defaultRecentUserList,
		setDefaultRecentUserList,
		getAllTask,
		getRecentSearchesList,
		krList,
		isLaunchFormEdited,
		setIsLaunchFormEdited,
		setIsCreateLinkPopupOpen,
		myRef,
		handleSaveCallBack,
		checkInResult,
		checkIfNotLoggedInUser,
	} = props;
	const { t } = useTranslation();
	// const ref = useRef<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);
	const [isNewTaskEdited, setIsNewTaskEdited] = useState<boolean>(false);
	const [isTaskEdited, setIsTaskEdited] = useState<boolean>(false);
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {} });
	const [createTaskValue, setCreateTaskValue] = useState<string>('');
	const [openKrList, setOpenKrList] = useState<boolean>(false);
	const [linkedKr, setLinkedKr] = useState<any>({});
	const [questonSaving, setQuestonSaving] = useState<number>(-1);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited, isNewTaskEdited, isTaskEdited]);

	const isDataChanged = () => {
		let isChanged = false;
		defaultCheckInData.forEach((element: any, index: number) => {
			if (element?.checkInDetails !== checkInData[index].checkInDetails) {
				if (
					(!checkInData[index].checkInDetails ||
						checkInData[index].checkInDetails?.trim() === '' ||
						checkInData[index].checkInDetails?.trim() === '<p></p>' ||
						checkInData[index].checkInDetails?.trim() === '<p><br></p>') &&
					element.checkInDetails === ''
				) {
				} else {
					isChanged = true;
				}
			}
		});
		return isChanged;
	};
	const handleClickOutside = (event: any) => {
		if (
			myRef.current &&
			!myRef.current?.contains(event.target) &&
			((isFormEdited && isDataChanged()) || isNewTaskEdited || isTaskEdited) &&
			checkUnSavePopUpVisible(event)
		) {
			const popupEditorElement = document.getElementsByClassName('ck-body-wrapper');
			let editorPopup = true;
			if (popupEditorElement.length > 0) {
				if (popupEditorElement[0]?.contains(event.target)) {
					editorPopup = false;
				}
			}
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (editorPopup && (dialogDiv.length === 0 || !dialogDiv[0].contains(event.target))) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({ open: true, event: event });
			}
		} else {
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			await setIsFormEdited(false);
			await setIsNewTaskEdited(false);
			await setIsTaskEdited(false);
			const { target } = modalProps.event;
			const clonedNativeEvent = document.createEvent('HTMLEvents');
			clonedNativeEvent.initEvent('click', true, false);
			target.dispatchEvent(clonedNativeEvent);
		} else {
		}
		setModalProps({ open: false, event: {} });
	};

	const handleTaskChange = (e: any) => {
		const { value } = e.target;
		setCreateTaskValue(value);
		if (value.includes(' /')) {
			setOpenKrList(true);
		}
	};
	const handleKrListClickAway = () => {
		setOpenKrList(false);
	};
	const handleKRLink = (e: any, kr: any) => {
		setLinkedKr(kr);
		let taskText = createTaskValue.split('/')[0];
		setOpenKrList(false);
		setCreateTaskValue(taskText);
	};

	useEffect(() => {
		setQuestonSaving(-1);
	}, [checkInData, selectedDate]);

	const handleBlur = (e: any, checkInQuestion: any, index: number) => {
		let checkInDataClone = [...checkInData];
		if (props.checkInDataCopy.length) {
			if (
				checkInDataClone[index].checkInDetails !== props.checkInDataCopy[index].checkInDetails &&
				props.checkInDataCopy.length
			) {
				setQuestonSaving(checkInDataClone[index].checkInDetailsId);
				handleCheckInUpdate(checkInDataClone[index]);
				props.setCheckInDataCopy([]);
				if (questonSaving !== -1) {
					setQuestonSaving(-1);
				}
			}
		}
	};

	const [importModalProps, setImportModalProps] = useState<boolean>(false);

	const handleImportTask = () => {
		setImportModalProps(true);
	};

	const handleImportCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		setImportModalProps(false);
		if (type === 1) {
			props.showLoader(true);
			const importResponse = await props.checkInImport();
			if (Boolean(importResponse) && importResponse.data.isSuccess) {
				props.showApiMsgs(t('taskImportedSuccess'), 'success');
				props.fetchCheckInData();
				setTimeout(() => {
					handleSaveCallBack('');
				}, 1000);
			} else {
				props.handleAPImessages(importResponse.data.messageList, 'error');
			}
		}
	};
	const checkIfHasEmptyData = () => {
		let hasEmptyData = false;
		if (checkInData && checkInData.length > 0) {
			checkInData.map((item: any, index: number) => {
				if (
					item.checkInPointsId === 2 &&
					(item.checkInDetails.trim() === '' ||
						item.checkInDetails.trim() === '<p></p>' ||
						item.checkInDetails.trim() === '<p><br></p>' ||
						item.checkInDetails === item.historyText)
				) {
					hasEmptyData = true;
				}
			});
		} else {
			hasEmptyData = true;
		}
		return hasEmptyData;
	};
	return (
		<>
			<div className='ques-ans-area' ref={myRef}>
				<Box className='ques-ans-head'>
					{/* {showQuestionHeading() ? (
						<Typography variant='h3'>{t('checkinsHeading')}</Typography>
					) : (
						<Typography variant='h3'>{t('checkinsHeading2')}</Typography>
					)} */}
					{/* {selectedDate?.checkInStatus === Enums.STATUS_CHECKED_ID && checkInData?.length > 0 && canCheckInEdit() && (
						<Box className='cont-style'>
							<OKRButton
								handleClick={(e) => editCheckIn()}
								icon={<EditIcon />}
								text={t('editResponses')}
								className={'btn-link'}
								disabled={isEdit}
								id={'edit-res-btn'}
							></OKRButton>

							{checkInData[0]?.isAfterCycleCutoff && (
								<Typography className='text-style' variant='body2'>
									Last updated on {moment(checkInData[0]?.checkInSubmitDate).format('MM/DD/YYYY')}
								</Typography>
							)}
						</Box>
					)} */}
				</Box>

				{viewToShow === 'missed' ? (
					<Box className='checkins-no-data'>
						<Box className='no-record'>
							<AnimatedIcon
								className='no-record-img'
								width={'212'}
								type='image/svg+xml'
								alt={'Not Yet Checked In'}
								imagePath={CheckInsMissed}
							/>
							<Typography variant='h2'>{t('itwasMissedPrimaryText')}</Typography>
							<Typography>
								{t('itwasMissedSecondaryText', { userName: `${selectedUser.firstName} ${selectedUser.lastName}` })}
							</Typography>
							<Typography>{t('itwasMissedTertiaryText')}</Typography>
						</Box>
					</Box>
				) : viewToShow === 'noYetChecked' ? (
					<Box className='ques-ans-area'>
						<Box className='checkins-no-data'>
							<Box className='no-record'>
								<AnimatedIcon
									className='no-record-img'
									width={'296'}
									type='image/svg+xml'
									alt={'Not Yet Checked In'}
									imagePath={NotYetCheckedIn}
								/>
								<Typography variant='h2'>{t('notYetCheckedInLabel')}</Typography>
								<Typography>
									{t('notYetCheckedInSecondaryText', {
										userName: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
									})}
								</Typography>
							</Box>
						</Box>
					</Box>
				) : viewToShow === 'noYetCheckedPlanning' ? (
					<Box className='ques-ans-area'>
						<Box className='checkins-no-data'>
							<Box className='no-record'>
								<AnimatedIcon
									className='no-record-img'
									width={'296'}
									type='image/svg+xml'
									alt={'Not Yet Checked In'}
									imagePath={NotYetCheckedIn}
								/>
								<Typography variant='h2'>{t('notYetCheckedInLabelDRs')}</Typography>
								<Typography>
									{t('notYetCheckedInSecondaryText', {
										userName: `${selectedUser?.firstName} ${selectedUser?.lastName}`,
									})}
								</Typography>
							</Box>
						</Box>
					</Box>
				) : (
					<Box className='ques-ans-list'>
						{checkInData &&
							checkInData.length > 0 &&
							checkInData.map((item: any, index: number) => (
								<>
									{item.checkInPointsId === 1 ? (
										<>
											{props.isOldVersion ? (
												<Box className='ques-ans-list-inner editor-fifteen-line' key={index}>
													<Typography className='qa-list-index'>{index + 1}</Typography>
													<Typography variant='h4' className='ques-head'>
														{item.checkInPoints}
													</Typography>
													<Box className='ans-box editior-pane'>
														{canCheckInEdit() ? (
															<>
																{/* <ClickAwayListener
																	onClickAway={(e) => {
																		handleBlur(e, item, index);
																	}}
																> */}
																<Box>
																	<CkEditor
																		key={index}
																		{...props}
																		className={selectedDate.checkInWeekType === Enums.PREVIOUS_WEEK ? 'inactive' : ''}
																		//isDisabled={currentDate.checkInStatus === Enums.STATUS_CHECKED_ID && !isEdit}
																		value={item.checkInDetails}
																		skipBlankCheck={true}
																		handleEditorChange={(value: string) => {
																			setIsFormEdited(true);
																			handleQuestionChange(value, item, index);
																		}}
																		id={`question-${index}`}
																		editorId={`question-${index}`}
																		removedPlugins={roleRemovePlugin}
																		showEmoticons={true}
																		// handleBlur={(value: string) => {
																		// 	handleBlur(value, item, index);
																		// }}
																		placeHolderText={
																			item.checkInPointsId === 1
																				? t('accomplishmentPlaceholder')
																				: item.checkInPointsId === 2
																				? t('learnPlaceholder')
																				: item.checkInPointsId === 4
																				? t('challengesPlaceholder')
																				: ''
																		}
																	/>
																	{/* {questonSaving === item.checkInDetailsId && props.isDataSaving ? (
																		<Box className='common-save-loader'>
																			<RefreshIcon />
																			<Typography variant='h6'>{t('savingChangesLevel')}</Typography>
																		</Box>
																	) : questonSaving === item.checkInDetailsId && !props.isDataSaving ? (
																		<Box className='common-save-loader'>
																			<TickIcon />
																			<Typography variant='h6'>{t('changesSavedLevel')}</Typography>
																		</Box>
																	) : (
																		<></>
																	)} */}
																</Box>
																{/* </ClickAwayListener> */}
															</>
														) : (
															<Box
																className='long-text'
																dangerouslySetInnerHTML={{ __html: item.checkInDetails ? item.checkInDetails : '--' }}
															></Box>
														)}
													</Box>
												</Box>
											) : (
												<Box className='ques-ans-list-inner editor-fifteen-line' key={index}>
													<Typography className='qa-list-index'>{index + 1}</Typography>
													<Box className='accomplish-ques-title'>
														<Typography variant='h4'>{item.checkInPoints}</Typography>
														{!checkIfNotLoggedInUser() &&
															checkInResult?.isImportButtonVisible &&
															selectedDate.checkInWeekType === Enums.CURRENT_WEEK && (
																<OKRButton
																	className='acc-task-import-btn'
																	icon={<ImportTaskIcon />}
																	text={t('pullIncompleteTasks')}
																	handleClick={handleImportTask}
																/>
															)}
													</Box>
													{/* <WeeklyTasks
														linkedKr={linkedKr}
														openKrList={openKrList}
														setIsNewTaskEdited={setIsNewTaskEdited}
														setIsTaskEdited={setIsTaskEdited}
														handleKrListClickAway={handleKrListClickAway}
														getCheckInDates={props.getCheckInDates}
														fetchCheckInTaskData={fetchCheckInTaskData}
														getCheckInDatesFromTask={getCheckInDatesFromTask}
														{...props}
													/> */}
													<WeeklyTaskDetails
														linkedKr={linkedKr}
														openKrList={openKrList}
														setIsNewTaskEdited={setIsNewTaskEdited}
														setIsTaskEdited={setIsTaskEdited}
														handleKrListClickAway={handleKrListClickAway}
														getCheckInDates={props.getCheckInDates}
														fetchCheckInTaskData={fetchCheckInTaskData}
														getCheckInDatesFromTask={getCheckInDatesFromTask}
														{...props}
														defaultTaskListRequest={defaultTaskListRequest}
														globalTaskRequest={globalTaskRequest}
														setGlobalTaskRequest={setGlobalTaskRequest}
														defaultRecentUserList={defaultRecentUserList}
														setDefaultRecentUserList={setDefaultRecentUserList}
														getAllTask={getAllTask}
														getRecentSearchesList={getRecentSearchesList}
														krList={krList}
														isLaunchFormEdited={isLaunchFormEdited}
														setIsLaunchFormEdited={setIsLaunchFormEdited}
														setIsCreateLinkPopupOpen={setIsCreateLinkPopupOpen}
														handleSaveCallBack={handleSaveCallBack}
													/>
												</Box>
											)}
										</>
									) : (
										<Box className='ques-ans-list-inner editor-fifteen-line' key={index}>
											<Typography className='qa-list-index'>{index + 1}</Typography>
											<Typography variant='h4' className='ques-head'>
												{item.checkInPoints}
											</Typography>
											<Box
												className={`ans-box editior-pane ${
													isDataSaving || !checkIfHasEmptyData() ? 'editor-active' : ''
												}`}
											>
												{canCheckInEdit() ? (
													<>
														{/* <ClickAwayListener
															onClickAway={(e) => {
																handleBlur(e, item, index);
															}}
														> */}
														<Box>
															<CkEditor
																key={index}
																{...props}
																className={selectedDate.checkInWeekType === Enums.PREVIOUS_WEEK ? 'inactive' : ''}
																//isDisabled={currentDate.checkInStatus === Enums.STATUS_CHECKED_ID && !isEdit}
																value={item.checkInDetails}
																skipBlankCheck={true}
																handleEditorChange={(value: string) => {
																	setIsFormEdited(true);
																	handleQuestionChange(value, item, index);
																}}
																id={`question-${index}`}
																editorId={`question-${index}`}
																removedPlugins={roleRemovePlugin}
																showEmoticons={true}
																// handleBlur={(value: string) => {
																// 	handleBlur(value, item, index);
																// }}
																placeHolderText={
																	item.checkInPointsId === 2
																		? t('learnPlaceholder')
																		: item.checkInPointsId === 4
																		? t('challengesPlaceholder')
																		: ''
																}
															/>
															{/* {questonSaving === item.checkInDetailsId && props.isDataSaving ? (
																	<Box className='common-save-loader'>
																		<RefreshIcon />
																		<Typography variant='h6'>{t('savingChangesLevel')}</Typography>
																	</Box>
																) : questonSaving === item.checkInDetailsId && !props.isDataSaving ? (
																	<Box className='common-save-loader'>
																		<TickIcon />
																		<Typography variant='h6'>{t('changesSavedLevel')}</Typography>
																	</Box>
																) : (
																	<></>
																)} */}
														</Box>
														{/* </ClickAwayListener> */}
													</>
												) : (
													<Box
														className='long-text'
														dangerouslySetInnerHTML={{ __html: item.checkInDetails ? item.checkInDetails : '--' }}
													></Box>
												)}
												{canCheckInEdit() && checkInData && checkInData.length > 0 && (
													<Box className='ques-ans-list-bottom'>
														<Box className='ques-ans-notes'>
															<Typography className='short-des-text'>{t('checkinsNotesText')}</Typography>
														</Box>
														{isDataSaving || !checkIfHasEmptyData() ? (
															<Box className='ques-and-action'>
																<Box className='textfield-action-btn'>
																	<OKRButton
																		className='cancel-btn'
																		id={'cancel-btn'}
																		icon={<CancelIcon />}
																		handleClick={(e: any) => {
																			setIsFormEdited(false);
																			// handleCheckInCancel();
																			handleQuestionChange(item?.historyText || '', item, index);
																		}}
																	/>
																	<OKRButton
																		className='save-btn'
																		id={'save-btn'}
																		icon={<TickIcon />}
																		disabled={isDataSaving || checkIfHasEmptyData()}
																		handleClick={(e: any) => {
																			setIsFormEdited(false);
																			handleCheckInUpdate();
																		}}
																	/>
																</Box>
																{/* <OKRButton
																handleClick={(e) => {
																	setIsFormEdited(false);
																	handleCheckInUpdate();
																}}
																className='btn-primary'
																disabled={isDataSaving || checkIfHasEmptyData()}
																text={t('submit')}
															/> */}
															</Box>
														) : (
															<></>
														)}
													</Box>
												)}
											</Box>
										</Box>
									)}
								</>
							))}
					</Box>
				)}

				{modalProps.open && (
					<AlertDialog
						message={t('checkinUnsavedMessage')}
						handleCloseModal={handleCloseModal}
						modalOpen={modalProps.open}
					/>
				)}
				{importModalProps && (
					<AlertDialog
						message={t('checkinImport')}
						handleCloseModal={handleImportCloseModal}
						modalOpen={importModalProps}
					/>
				)}
			</div>
		</>
	);
};
