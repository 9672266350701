import React, { Fragment, useState, useEffect } from 'react';
import { Box, Chip, List, ListItem, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { DeleteIcon, SortingIcon } from '../../../../../config/svg/CommonSvgIcon';
import { NoRecord } from '../../../CommonComponent/NoRecord';
import NoDataImg from '../../../../../images/my-goal-bg.svg';
import '../../../../../styles/pages/admin/settings/align-goals/goalWorkflowList.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteGoalCycle,
	deleteGoalReviewCycle,
	goalReviewList,
	refreshGoalReviewReviewList,
	setGoalReviewPaginationData,
} from '../../../../../action/adminSettings';
import { getFullName, getMonthDateYear } from '../../../../../config/utils';
import { EnumPerformanceGoalCycleStatus, Enums } from '../../../../../config/enums';
import { OKRButton } from '../../../../Common/OKRButton';
import { DialogComponent } from '../../../CommonComponent/DialogComponent';
import { useSnackbar } from 'notistack';

export const GoalReviewList: React.FC<any> = (props: any) => {
	const { setLoader, goalCycleSearchTextArray, pageIndex, pageSize, selectedWorkflowFilterCycleId } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [goalReviewListData, setGoalReviewListData] = useState<any>(null);
	const [sortingData, setSortingData] = useState<any>({ column: '', order: '' });
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '' });
	const { enqueueSnackbar } = useSnackbar();

	const { refreshGoalReviewReviewListing, goalReviewCycleIdHighlight } = useSelector(
		(state: any) => state.adminSettingReducer
	);

	useEffect(() => {
		getGoalReviewList({
			finder: goalCycleSearchTextArray,
			pageIndex: pageIndex,
			sortingText: sortingData.column,
			//performanceGoalCycleId: selectedWorkflowFilterCycleId,
			order: sortingData.order,
			pageSize: pageSize,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [goalCycleSearchTextArray, pageIndex, pageSize]);
	useEffect(() => {
		if (refreshGoalReviewReviewListing) {
			getGoalReviewList({
				finder: '',
				pageIndex: pageIndex,
				sortingText: sortingData.column,
				//performanceGoalCycleId: selectedWorkflowFilterCycleId,
				order: sortingData.order,
				pageSize: pageSize,
			});
			dispatch(refreshGoalReviewReviewList(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshGoalReviewReviewListing]);
	const getGoalReviewList = async (requestPayload: any) => {
		let requestParam = {
			...requestPayload,
			//performanceGoalCycleId: 0,
			//startDate: null,
			//endDate: null,
		};
		setLoader(true);
		const response: any = await dispatch(goalReviewList(requestParam));
		if (response) {
			if (response && response.data && response.data.status === 200) {
				const { entity } = response.data;
				dispatch(setGoalReviewPaginationData(entity));
				if (entity) {
					const { records } = entity || {};
					if (records && records.length) {
						setGoalReviewListData(records);
					} else {
						setGoalReviewListData([]);
					}
				} else {
					setGoalReviewListData([]);
				}
				setLoader(false);
			} else {
				setLoader(false);
			}
		}
		setLoader(false);
	};

	const sortGoalReviewList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		if (column === sortingData.column) {
			newSortOrder = sortingData.order === 'asc' ? 'desc' : 'asc';
		}
		setSortingData({ column: column, order: newSortOrder });

		getGoalReviewList({
			finder: goalCycleSearchTextArray,
			pageIndex: 1,
			sortingText: column,
			order: newSortOrder,
			pageSize: pageSize,
		});
	};

	const handleRecordClick = (e: any, record: any, type: string) => {
		e.preventDefault();
		e.stopPropagation();
		if (record && record.performanceGoalCycleId) {
			if (type === 'Delete') {
				setModalProps({
					open: true,
					message:
						record.status === EnumPerformanceGoalCycleStatus.Draft
							? t('deleteDraftGoalReviewText')
							: record.status === EnumPerformanceGoalCycleStatus.Submitted
							? t('deleteSubmittedGoalReviewText')
							: record.status === EnumPerformanceGoalCycleStatus.Published
							? t('deleteOngoingGoalReviewText')
							: t('deleteCompletedGoalReviewText'),
					details: record,
				});
			}
		}
	};

	const handleCloseModal = async (e: any, type: any) => {
		//e.preventDefault();
		if (type === 1) {
			handleDeleteReviewCycle();
		} else {
			setModalProps({ open: false, message: '', details: '' });
		}
	};

	const handleDeleteReviewCycle = async () => {
		if (modalProps.details && modalProps.details.performanceGoalReviewCycleId) {
			setLoader(true);
			const queryParams = `performanceGoalReviewCycleId=${modalProps.details.performanceGoalReviewCycleId}`;
			setModalProps({ open: false, message: '', details: '' });
			const response: any = await dispatch(deleteGoalReviewCycle(queryParams));
			if (response) {
				if (response && response?.data && response.data.status === 200) {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'cycle deleted successfully', {
						variant: 'success',
						autoHideDuration: 3000,
					});
					getGoalReviewList({
						finder: goalCycleSearchTextArray,
						pageIndex: pageIndex,
						sortingText: sortingData.column,
						order: sortingData.order,
						pageSize: pageSize,
					});
					//dispatch(getPerformanceGoalTabCount());
				} else {
					const { messageList } = response.data;
					const keys = Object.keys(messageList);
					const messages = keys.map((item) => messageList[item]);
					enqueueSnackbar(messages || 'Error occurred while deleting assessment form', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				setLoader(false);
			}
		}
	};

	return (
		<Box className='goal-review-listing'>
			{goalReviewListData && goalReviewListData?.length > 0 ? (
				<Box className='okr-list-panel-head'>
					<List component='ul' disablePadding>
						<ListItem className='goal-review-col1'>
							<Typography
								variant='body2'
								onClick={(e) => sortGoalReviewList(e, 'performanceGoalReviewCycleName')}
								className={
									sortingData.column === 'performanceGoalReviewCycleName'
										? `sort-title ${sortingData.order}`
										: 'sort-title asc'
								}
							>
								<span className='sortable-label'>
									<span className='txt-wrap'>
										{t('reviewCycleNameLevel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-review-col2'>
							<Typography
								variant='body2'
								onClick={(e) => sortGoalReviewList(e, 'performanceGoalCycleName')}
								className={
									sortingData.column === 'performanceGoalCycleName'
										? `sort-title ${sortingData.order}`
										: 'sort-title asc'
								}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('settingCycleNameLevel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-review-col3'>
							<Typography
								variant='body2'
								onClick={(e) => sortGoalReviewList(e, 'startDate')}
								className={sortingData.column === 'startDate' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('reviewDurationLevel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-review-col4'>
							<Typography
								variant='body2'
								onClick={(e) => sortGoalReviewList(e, 'usersCount')}
								className={sortingData.column === 'usersCount' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('numberUser')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
						<ListItem className='goal-review-col5'>
							<Typography
								variant='body2'
								onClick={(e) => sortGoalReviewList(e, 'statusName')}
								className={sortingData.column === 'statusName' ? `sort-title ${sortingData.order}` : 'sort-title asc'}
							>
								<span className={`sortable-label `}>
									<span className='txt-wrap'>
										{t('statusLabel')} <SortingIcon />
									</span>
								</span>
							</Typography>
						</ListItem>
					</List>
				</Box>
			) : (
				<></>
			)}
			{goalReviewListData && goalReviewListData?.length > 0 ? (
				goalReviewListData.map((item: any, index: number) => {
					//const userFullName = getFullName(item?.employeeDetails);
					return (
						<Box className={`goal-review-list-content`}>
							<List component='ul' disablePadding key={`goal-review-list-${index}`}>
								<ListItem className='goal-review-col1'>
									<Typography>{item.performanceGoalReviewCycleName}</Typography>
									<Box className='goal-review-list-actions'>
										<OKRButton
											handleClick={(event: any) => handleRecordClick(event, item, 'Delete')}
											icon={<DeleteIcon />}
											title={t('delete')}
										/>
									</Box>
								</ListItem>
								<ListItem className='goal-review-col2'>
									<Typography>{item.performanceGoalCycleName}</Typography>
								</ListItem>
								<ListItem className='goal-review-col3'>
									<Typography>
										{item?.startDate && item.endDate
											? getMonthDateYear(item.startDate) + ' - ' + getMonthDateYear(item.endDate)
											: ''}
									</Typography>
								</ListItem>
								<ListItem className='goal-review-col4'>
									<Typography>{item.usersCount}</Typography>
								</ListItem>
								<ListItem className='goal-review-col5'>
									<Box className='goal-review-status'>
										<Chip
											className={
												item?.status === Enums.ZERO
													? 'default-grey-chip'
													: item?.status === Enums.ONE
													? 'draft-chip'
													: item?.status === Enums.TWO
													? 'lagging-chip'
													: item?.status === Enums.THREE
													? 'default-grey-chip'
													: item?.status === Enums.FOUR
													? 'on-track-chip'
													: ''
											}
											label={item.statusName}
										/>
									</Box>
								</ListItem>
							</List>
						</Box>
					);
				})
			) : (
				<NoRecord
					noRecordMessage={t('noGoalReviewYet')}
					subHead={t('clickPlusIconToGoalReview')}
					noDataImgWidth={'516'}
					noImg={NoDataImg}
					{...props}
				/>
			)}
			{modalProps && modalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={modalProps.open}
					handleCloseModal={(e: any) => handleCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
					headingText={t('deleteGoalReviewCycleTitle')}
					subHeading={modalProps.message}
				/>
			)}
		</Box>
	);
};
