import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { OKRButton } from '../../Common/OKRButton';
import { AddIconSvg } from '../../../config/svg/CommonSvgIcon';
import '../../../styles/pages/performance-development/performanceFeedback.scss';
import { AnimatedIcon } from '../../Common/AnimatedIcon';
import WelcomeImage from '../../../images/my-goal-bg.svg';
import { CommonFilters } from '../Common/CommonFilters';
import {
	deleteFeedbackPlan,
	getFeedbackListingData,
	getOtherFeedbackListingData,
	getFeedbackStatusDetails,
	getPipListingFilter,
	reloadFeedbackList,
	getFeedbackCategoryData,
	setFeedbackImprovementData,
	getFeedbackAlertData,
	getPipAlertData,
	getFeedbackPermissionApi,
	setThreeSixtyData,
} from '../../../action/performanceImprovementPlan';
import { useDispatch, useSelector } from 'react-redux';
import { checkUnSavePopUpVisible, formatFeedbackDate, getUserDetails } from '../../../config/utils';
import { FeedbackMyList } from './FeedbackMyList';
import { ViewFeedback } from './ViewFeedback';
import { useSnackbar } from 'notistack';
import { globalSearchAPIWithTeam } from '../../../action/search';
import { CreateOneOnOne } from '../../Engagement/Request1on1/CreateOneOnOne';
import { getOkrMasterData } from '../../../action/myGoal';
import { Enums } from '../../../config/enums';
import { DialogComponent } from '../../Admin/CommonComponent/DialogComponent';
import { threeSixtyFilter } from '../../../config/constant';
import AlertDialog from '../../Common/Dialog';
import { CreateThreeSixtyExperience } from './CreateThreeSixtyExperience';

export const ThreeSixetyExperience = (props: any) => {
	const {
		loader,
		setLoader,
		handleCreatePIPFormOpenClick,
		openViewFeedbackForm,
		setOpenViewFeedbackForm,
		handleFeedbackItemClick,
		openCreateFeedbackForm,
		setOpenCreateFeedbackForm,
		setFeedbackDetailsForPip,
		setGoBackToFeedback,
	} = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const userDetails = getUserDetails();
	const ref = useRef<any>(null);
	const ref1 = useRef<any>(null);
	// const ref2 = useRef<any>(null);
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);

	let defaultFinalSettings = {
		...{
			resolvedStatus: [],
			status: [],
			start: [],
			filterData: [],
			timeframe: [],
		},
	};
	const [finalFilterCondition, setFinalFilterCondition] = useState<any>(defaultFinalSettings);
	const [noRecords, setNoRecords] = useState<boolean>(false);
	const [selectedFilterDateRange, setSelectedFilterDateRange] = useState<any>(null);
	const [isFormEdited, setIsFormEdited] = useState<boolean>(false);

	const pageSize = 3;

	const [searchValue, setSearchValue] = useState('');
	const [myPagingInfo, setMyPagingInfo] = useState({
		pageIndex: 1,
		pageSize: 3,
		totalPages: 0,
		totalRecords: 0,
	});
	const [otherPagingInfo, setOtherPagingInfo] = useState({
		pageIndex: 1,
		pageSize: 3,
		totalPages: 0,
		totalRecords: 0,
	});
	// const [openCreateFeedbackForm, setOpenCreateFeedbackForm] = useState<any>({
	// 	open: false,
	// 	feedbackFormEditDetails: null,
	// 	type: 'Add',
	// });
	// const [openViewFeedbackForm, setOpenViewFeedbackForm] = useState<any>({
	// 	open: false,
	// 	feedbackItem: null,
	// });
	const [performanceImprovementList, setPerformanceImprovementList] = useState<any>(null);
	const [visibleFeedback, setVisibleFeedback] = useState<number>(0);
	const [performanceOthersImprovementList, setPerformanceOthersImprovementList] = useState<any>(null);
	const [requestOneOnOneDrawer, setRequestOneOnOneDrawer] = useState<any>({ open: false, type: '' });
	const [employeeFeedbackPermission, setEmployeeFeedbackPermission] = useState<any>(null);
	const [performanceImprovementPlanPermission, setPerformanceImprovementPlanPermission] = useState<any>(null);
	const [resolveStatusTypes, setResolveStatusTypes] = useState<any[]>([]);
	const [twoFactorModalProps, setTwoFactorModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [feedbackFilters, setFeedbackFilters] = useState<any>({});
	const [feedbackFiltersList, setFeedbackFiltersList] = useState<any>({});
	const [modalProps, setModalProps] = useState<any>({ open: false, event: {}, message: '' });
	const [select1on1Tab, setSelect1on1Tab] = useState<any>(Enums.ONE);
	const [feedbackPermissionData, setFeedbackPermissionData] = useState<any>({});

	const { reloadFeeback, threeSixtyData } = useSelector((state: any) => state?.performanceImprovementPlanReducer);
	useEffect(() => {
		setFeedbackDetailsForPip(null);
		setGoBackToFeedback(null);
		getResolveStatusAPICall();
		dispatch(getOkrMasterData());
		getAllCommonFilters();
		dispatch(reloadFeedbackList(true));
		getFeedbackPermissionData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getFeedbackPermissionData = async () => {
		const response: any = await dispatch(getFeedbackPermissionApi());
		if (Boolean(response) && response.data.status === 200) {
			setFeedbackPermissionData(response.data.entity);
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	useEffect(() => {
		if (threeSixtyData && threeSixtyData.feedbackId) {
			handleFeedbackItemClick({}, threeSixtyData);
			/*setOpenViewPipForm({
				open: true,
				pipItem: { performanceImprovementPlanId: pipImprovementData?.pipId },
			});*/
			dispatch(setThreeSixtyData(null));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [threeSixtyData]);
	useEffect(() => {
		if (feedbackFilters) {
			if (feedbackFiltersList && feedbackFiltersList.length > 0) {
				let pipFiltersListCopy: any[] = Object.assign([], feedbackFiltersList);
				pipFiltersListCopy.forEach((item: any, selectedCycleIndex: number) => {
					if (
						feedbackFilters[item.performanceImprovementPlanStatusId] &&
						feedbackFiltersList[selectedCycleIndex].values.length === 0
					) {
						pipFiltersListCopy[selectedCycleIndex].values =
							feedbackFilters[item.performanceImprovementPlanStatusId] || [];
					}
				});
				setFeedbackFiltersList(pipFiltersListCopy);
			} else {
				let pipFiltersListCopy: any[] = Object.assign([], threeSixtyFilter);
				pipFiltersListCopy.forEach((item: any, selectedCycleIndex: number) => {
					if (feedbackFilters[item.performanceImprovementPlanStatusId]) {
						pipFiltersListCopy[selectedCycleIndex].values =
							feedbackFilters[item.performanceImprovementPlanStatusId] || [];
					}
				});
				setFeedbackFiltersList(pipFiltersListCopy);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [feedbackFilters]);
	// ======= HANDLING UN_SAVE CHANGES WITH BELOW CODE =========== //
	useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isFormEdited, requestOneOnOneDrawer]);

	const handleClickOutside = (event: any) => {
		// if (event?.target?.download) {
		// 	//
		// } else {
		// console.log('handleClickOutside ===> ', isFormEdited);
		if (
			ref.current &&
			(!ref.current?.contains(event.target) ||
				event.target.id === 'back-btn' ||
				event.target.id === 'open_resolve_form_tab' ||
				event.target.id === 'open_pip_create_form' ||
				event.target.id === 'feedback_tas_main_component') &&
			// (!ref.current?.contains(event.target) ||
			// 	!ref1.current?.contains(event.target)) &&
			checkUnSavePopUpVisible(event) &&
			isFormEdited &&
			!requestOneOnOneDrawer?.open
		) {
			const dialogDiv = document.getElementsByClassName('MuiDialog-paper');
			if (dialogDiv.length === 0) {
				event.preventDefault();
				event.stopPropagation();
				setModalProps({ open: true, event: event, type: 'UnSaveChanges', message: t('checkinUnsavedMessage') });
			}
		} else {
			//
		}
		// }
	};
	const handleCloseModal = (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			if (modalProps?.type === 'UnSaveChanges') {
				if (modalProps?.event) {
					// setPIPFormDetails(defaultPIPFormDetails);
					setIsFormEdited(false);
					const { target } = modalProps.event;
					const clonedNativeEvent = document.createEvent('HTMLEvents');
					clonedNativeEvent.initEvent('click', true, false);
					target.dispatchEvent(clonedNativeEvent);
				}
			} else if (modalProps?.type === 'DirectUnSaveCheck') {
				const { newValue } = modalProps?.detail || {};
				setIsFormEdited(false);
				setSelect1on1Tab(newValue);
			}
		}
		setModalProps({ open: false, event: null, message: '' });
	};

	const getAllCommonFilters = async () => {
		const [myResp, otherResp]: any = await Promise.allSettled([
			dispatch(getFeedbackCategoryData('feedbackTypeId=0')),
			dispatch(getFeedbackStatusDetails()),
		]);
		let filtersList: any = {};
		if (myResp && myResp?.value?.data && myResp?.value?.data?.status === Enums.STATUS_SUCCESS) {
			// call alert api
			dispatch(getFeedbackAlertData());
			dispatch(getPipAlertData());
			const { entityList } = myResp?.value?.data || {};
			let list = entityList && entityList?.length ? [...entityList] : [];
			list = list.map((item: any) => {
				return {
					...item,
					performanceImprovementPlanStatusId: item.feedbackCategoryId,
				};
			});
			filtersList.categoryFilters = list;
		}
		if (otherResp && otherResp?.value?.data && otherResp?.value?.data?.status === Enums.STATUS_SUCCESS) {
			const { entityList } = otherResp?.value?.data || {};
			let list = entityList && entityList?.length ? [...entityList] : [];
			list = list.filter((item: any) => item?.isResolvedStatus && item?.isEnabled);
			list = list.map((item: any) => {
				return {
					...item,
					performanceImprovementPlanStatusId: item.feedbackStatusId,
				};
			});
			filtersList.resolvedStatus = list;
		}
		setFeedbackFilters(filtersList);
	};

	useEffect(() => {
		if (reloadFeeback) {
			getAllFeedbackData();
			dispatch(reloadFeedbackList(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [reloadFeeback]);
	useEffect(() => {
		if (okrMasterData && okrMasterData?.employeeFeedbackPermission) {
			setEmployeeFeedbackPermission(okrMasterData?.employeeFeedbackPermission);
		}
		if (okrMasterData && okrMasterData?.performanceImprovementPlanPermission) {
			setPerformanceImprovementPlanPermission(okrMasterData?.performanceImprovementPlanPermission);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [okrMasterData]);

	const isUserHavePIPPermission = () => {
		return Boolean(performanceImprovementPlanPermission?.isAddingPipAllowed);
	};

	// const isUserHavePIPPermission = () => {
	// 	return Boolean(employeeFeedbackPermission?.isAddingPipAllowed);
	// };
	const isUserHaveFeedbackEditPermission = () => {
		return Boolean(employeeFeedbackPermission?.allowReviewersToEditFeedback);
	};
	// const isUserHavePIPPermission = () => {
	// 	return Boolean(employeeFeedbackPermission?.isFeedbackVisibleToRM);
	// };

	const getResolveStatusAPICall = async () => {
		try {
			setLoader(true);
			const response: any = await dispatch(getFeedbackStatusDetails());
			if (response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				let updatedStatusDetails: any[] = entityList && entityList?.length ? entityList : [];
				updatedStatusDetails = updatedStatusDetails
					.filter((item: any) => item?.isResolvedStatus && item?.isEnabled)
					.map((item: any, index: any) => {
						const data = { ...item };
						return data;
					});
				setResolveStatusTypes(updatedStatusDetails);
				setLoader(false);
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
				showMsg('error', messages || `Some error occurred`);
				setLoader(false);
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};

	const getFilters = (type = '') => {
		let payload: any = {
			finder: '',
			pageIndex: 1,
			pageSize: pageSize,
		};
		if (searchValue !== '') {
			payload.finder = searchValue;
		}

		if (selectedFilterDateRange && selectedFilterDateRange?.start?._d && selectedFilterDateRange?.end?._d) {
			payload.isDateFilterAdded = true;
			payload.timeframeStartDate = selectedFilterDateRange?.start?._d
				? formatFeedbackDate(selectedFilterDateRange?.start?._d)
				: '';
			payload.timeframeEndDate = selectedFilterDateRange?.end?._d
				? formatFeedbackDate(selectedFilterDateRange?.end?._d)
				: '';
		}
		if (finalFilterCondition?.status && finalFilterCondition?.status.length > 0) {
			let statusIds: any[] = [];
			finalFilterCondition.status.forEach((item: any) => {
				statusIds.push(item.performanceImprovementPlanStatusId);
			});
			payload.status = statusIds;
		}
		if (finalFilterCondition?.resolvedStatus && finalFilterCondition?.resolvedStatus.length > 0) {
			let resolvedStatusIds: any[] = [];
			finalFilterCondition.resolvedStatus.forEach((item: any) => {
				resolvedStatusIds.push(item.performanceImprovementPlanStatusId);
			});
			payload.resolvedStatus = resolvedStatusIds;
		}

		if (finalFilterCondition?.categoryFilters && finalFilterCondition?.categoryFilters.length > 0) {
			let categoryFiltersIds: any[] = [];
			finalFilterCondition.categoryFilters.forEach((item: any) => {
				categoryFiltersIds.push(item.performanceImprovementPlanStatusId);
			});
			payload.category = categoryFiltersIds; // category filter
		}
		if (finalFilterCondition?.timeframe && finalFilterCondition?.timeframe.length === 1) {
			payload.noTimeFrame = 1; // No Time Frame
		}
		let visibleFeedbackIndex = 0;
		if (finalFilterCondition?.start && finalFilterCondition?.start.length === 1) {
			if (finalFilterCondition?.start[0].performanceImprovementPlanStatusId === 1) {
				visibleFeedbackIndex = 1;
			} else if (finalFilterCondition?.start[0].performanceImprovementPlanStatusId === 2) {
				visibleFeedbackIndex = 2;
			}
		}
		payload.visibleFeedbackIndex = visibleFeedbackIndex;
		payload.isThreeSixtyExperience = true;
		setVisibleFeedback(visibleFeedbackIndex);
		return payload;
	};
	const getAllFeedbackData = async () => {
		try {
			let payload = getFilters();
			setLoader(true);
			const [myResp, otherResp]: any = await Promise.allSettled([
				dispatch(getFeedbackListingData(payload)),
				dispatch(getOtherFeedbackListingData(payload)),
			]);
			let loaded = true;
			let hasData = false;
			let entityListCount = 0;
			if (myResp.value && myResp.value?.status === 200) {
				const { entityList, pageInfo } = myResp.value?.data || {};
				if (payload.visibleFeedbackIndex !== 2) {
					setPerformanceImprovementList(entityList || []);
					entityListCount = entityList.length;
					hasData = hasData || entityList?.length > 0;
					setMyPagingInfo(pageInfo);
				} else {
					setPerformanceImprovementList([]);
					setMyPagingInfo({
						pageIndex: 1,
						pageSize: 3,
						totalPages: 0,
						totalRecords: 0,
					});
				}
			} else {
				setPerformanceImprovementList([]);
				loaded = false;
				setMyPagingInfo({
					pageIndex: 1,
					pageSize: 3,
					totalPages: 0,
					totalRecords: 0,
				});
			}
			if (otherResp.value && otherResp.value?.status === 200) {
				const { entityList, pageInfo } = otherResp.value?.data || {};
				if (payload.visibleFeedbackIndex !== 1) {
					hasData = hasData || otherResp.value?.data?.entityList.length > 0;
					if (entityListCount === 0 && otherResp.value?.data?.entityList.length > 0) {
						loadMoreOtherRecords(2, 3, otherResp.value?.data?.entityList);
					} else {
						setPerformanceOthersImprovementList(entityList || []);
						setOtherPagingInfo(pageInfo);
					}
				} else {
					setPerformanceOthersImprovementList([]);
				}
			} else {
				setPerformanceOthersImprovementList([]);
				loaded = false;
			}
			setLoader(false);
			setNoRecords(!hasData);
		} catch (error) {
			setPerformanceOthersImprovementList([]);
			setPerformanceImprovementList([]);
			setLoader(false);
		}
	};

	const loadMoreOtherRecords = async (pageIndex = 1, pageSize = 3, existingData = null) => {
		let payload = getFilters();
		payload.pageIndex = pageIndex;
		payload.pageSize = pageSize;
		const otherResp: any = await dispatch(getOtherFeedbackListingData(payload));
		if (otherResp && otherResp?.status === 200) {
			let options: any = [];
			if (existingData !== null) {
				options = Object.assign([], existingData);
			} else {
				options = Object.assign([], performanceOthersImprovementList);
			}
			const { entityList, pageInfo } = otherResp?.data || {};
			let finalOptions = options.concat(entityList);
			setPerformanceOthersImprovementList(finalOptions);
			setOtherPagingInfo(pageInfo);
		}
	};
	const handleCreateFeedbackClick = (event: any) => {
		setOpenCreateFeedbackForm({
			open: true,
			feedbackFormEditDetails: null,
			type: 'Add',
		});
	};

	const applyFilter = (filterData: any) => {
		setFinalFilterCondition(filterData);
		dispatch(reloadFeedbackList(true));
	};

	const applyDateFilter = (range: any) => {
		setSelectedFilterDateRange(range);
		dispatch(reloadFeedbackList(true));
	};
	const handleSearchText = (searchText: any) => {
		//perform search
		dispatch(reloadFeedbackList(true));
	};

	// const handleFeedbackItemClick = (event: any, feedbackItem: any) => {
	// 	try {
	// 		setOpenViewFeedbackForm({
	// 			open: true,
	// 			feedbackItem,
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	const loadMoreMyRecords = async (pageIndex = 1) => {
		try {
			let payload = getFilters();
			payload.pageIndex = pageIndex;
			const myResp: any = await dispatch(getFeedbackListingData(payload));
			if (myResp && myResp?.status === 200) {
				let options = Object.assign([], performanceImprovementList);
				const { entityList, pageInfo } = myResp?.data || {};
				let finalOptions = options.concat(entityList);
				setPerformanceImprovementList(finalOptions);
				setMyPagingInfo(pageInfo);
			}
		} catch (error) {}
	};
	const myLoadMore = () => {
		loadMoreMyRecords(myPagingInfo.pageIndex + 1);
	};
	const otherLoadMore = () => {
		loadMoreOtherRecords(otherPagingInfo.pageIndex + 1);
	};
	const backClicked = (backType: any) => {
		getAllFeedbackData();
		setOpenViewFeedbackForm({
			open: false,
			feedbackItem: null,
		});
		setOpenCreateFeedbackForm({
			open: false,
			feedbackFormEditDetails: null,
			type: 'Add',
		});
		setFinalFilterCondition(defaultFinalSettings);
		setSelect1on1Tab(Enums.ONE);
	};
	const handleOneOnOneClick = (e: any, clickType: string, empId: any) => {
		const { reportingTo } = userDetails || {};
		let employeeId = reportingTo || 0;
		if (clickType === 'ViewPip') {
			employeeId = empId || 0;
		}
		if (clickType && employeeId) {
			const redirectInfo = { employeeId: employeeId, employeeInfo: null, redirectFrom: 'PIP' };

			getRedirectedEmployeeData(redirectInfo);
		}
	};

	const getRedirectedEmployeeData = async (details: any) => {
		setLoader(true);
		const { employeeId } = details || {};
		const resp: any = await dispatch(globalSearchAPIWithTeam(employeeId, 1, 999999, 1));
		setLoader(false);
		if (resp && resp?.data && resp?.data?.status === 200) {
			let list = resp?.data?.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetails.employeeId);
			const employeeInfo = list && list.length ? list.find((rec: any) => rec?.employeeId === employeeId) : null;
			if (employeeInfo) {
				handleClickRequestOneOnOneCreate({});
				// handleRequestOneOnOneCall({}, employeeInfo);
			} else {
				handleClickRequestOneOnOneCreate({});
			}
		} else {
			handleClickRequestOneOnOneCreate({});
		}
	};
	const handleRequestOneOnOneCall = (event: any, empRecord: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Direct', details: '', empInfo: empRecord });
	};
	const handleClickRequestOneOnOneCreate = (event: any) => {
		setRequestOneOnOneDrawer({ open: true, type: 'Add' });
	};

	const showMsg = (variant: any, messages: any) => {
		enqueueSnackbar(messages || `Error occurred while fetching comments`, {
			variant: variant || 'success',
			autoHideDuration: 3000,
		});
	};
	const deleteFeedbackApiCall = async (feedbackItem: any, feedbackFormType = 'View') => {
		try {
			if (feedbackItem?.feedbackId) {
				setLoader(true);
				const requestParam = `feedbackId=${feedbackItem?.feedbackId}`;
				const response: any = await dispatch(deleteFeedbackPlan(requestParam));
				if (response && response?.data && response.data?.status === Enums.STATUS_SUCCESS) {
					backClicked(feedbackFormType);
					showMsg('success', `Feedback deleted successfully`);
					dispatch(reloadFeedbackList(true));
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					showMsg('error', messages || `Error occurred while deleting personal development plan.`);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			showMsg('error', `Some error occurred`);
			setLoader(false);
		}
	};
	const handleTwoFactorCloseModal = async (e: any, type: any) => {
		if (type === 1) {
			const { feedbackItem, feedbackFormType } = twoFactorModalProps?.details || {};
			setTwoFactorModalProps({ open: false, message: '', details: '' });
			deleteFeedbackApiCall(feedbackItem, feedbackFormType);
		} else {
			setTwoFactorModalProps({ open: false, message: '', details: '' });
		}
	};
	const handleDeleteFeedback = (event: any, feedbackItem: any, feedbackFormType: string) => {
		setTwoFactorModalProps({
			open: true,
			message: {
				headingText: '', // t('Deleting the Performance Improvement Plan?'),
				subHeading: t(
					'Deleting the feedback will delete all feedback comment details (if any) and data cannot be recovered.'
				),
			},
			details: { feedbackItem, feedbackFormType },
		});
	};

	const handleEditFeedback = (event: any, feedbackItem: any, feedbackFormType: string) => {
		setOpenCreateFeedbackForm({
			open: true,
			feedbackFormEditDetails: feedbackItem,
			type: 'Edit',
		});
	};
	const handleResolveClick = () => {
		setSelect1on1Tab(Enums.TWO);
	};
	const handleTabChange = (event: any, newValue: number) => {
		if (isFormEdited) {
			setModalProps({
				open: true,
				detail: { newValue },
				type: 'DirectUnSaveCheck',
				message: t('checkinUnsavedMessage'),
			});
		} else {
			setSelect1on1Tab(newValue);
		}
	};

	return (
		<>
			<Box className='pip-area'>
				{openViewFeedbackForm?.open ? (
					<></>
				) : (
					<Box className='pip-header-area'>
						<Box className='pip-header-left'>
							{!loader &&
								(visibleFeedback !== 2 && performanceImprovementList && performanceImprovementList.length > 0 ? (
									<Typography variant='h2'>My ({myPagingInfo.totalRecords})</Typography>
								) : visibleFeedback !== 1 &&
								  performanceOthersImprovementList &&
								  performanceOthersImprovementList.length > 0 ? (
									<Typography variant='h2'>
										{t('allNominations')} ({otherPagingInfo.totalRecords})
									</Typography>
								) : (
									<></>
								))}
						</Box>
						<Box className='pip-header-right'>
							{(performanceImprovementList && performanceImprovementList.length > 0) ||
							(performanceOthersImprovementList && performanceOthersImprovementList.length > 0) ||
							selectedFilterDateRange ||
							(finalFilterCondition.filterData && finalFilterCondition.filterData.length) ||
							searchValue ? (
								<CommonFilters
									{...props}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
									defaultFinalSettings={defaultFinalSettings}
									finalFilterCondition={finalFilterCondition}
									setFinalFilterCondition={applyFilter}
									handleSearchText={handleSearchText}
									setSelectedFilterDateRange={applyDateFilter}
									selectedFilterDateRange={selectedFilterDateRange}
									minDateValue={new Date(new Date().setFullYear(new Date().getFullYear() - 10))}
									maxDateValue={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
									defaultLocalSettings={defaultFinalSettings}
									pipFiltersList={feedbackFiltersList}
									type={'feedback'}
									fromThreeSixety={true}
								/>
							) : (
								<></>
							)}
							{feedbackPermissionData?.isAddIconVisible && (
								<OKRButton
									className='add-btn'
									icon={<AddIconSvg />}
									title={t('createFeedback')}
									handleClick={handleCreateFeedbackClick}
								/>
							)}
						</Box>
					</Box>
				)}
				{openViewFeedbackForm?.open ? (
					<div ref={ref}>
						<Box className='pip-content-area'>
							<ViewFeedback
								feedbackFormType={'View'}
								loader={loader}
								setLoader={setLoader}
								feedbackItem={openViewFeedbackForm?.feedbackItem || null}
								backClicked={backClicked}
								showMsg={showMsg}
								handleOneOnOneClick={handleOneOnOneClick}
								setIsFormEdited={setIsFormEdited}
								userDetails={userDetails}
								isUserHaveFeedbackEditPermission={isUserHaveFeedbackEditPermission}
								isUserHavePIPPermission={isUserHavePIPPermission}
								resolveStatusTypes={resolveStatusTypes}
								handleDeleteFeedback={handleDeleteFeedback}
								handleEditFeedback={handleEditFeedback}
								handleCreatePIPFormOpenClick={handleCreatePIPFormOpenClick}
								handleResolveClick={handleResolveClick}
								select1on1Tab={select1on1Tab}
								setSelect1on1Tab={setSelect1on1Tab}
								handleTabChange={handleTabChange}
							/>
						</Box>
					</div>
				) : (
					<></>
				)}
				{openCreateFeedbackForm?.open ? (
					<CreateThreeSixtyExperience
						{...props}
						feedbackFormType={openCreateFeedbackForm?.feedbackFormEditDetails ? 'Edit' : 'Add'}
						loader={loader}
						setLoader={setLoader}
						openCreatePipForm={openCreateFeedbackForm}
						feedbackFormEditDetails={openCreateFeedbackForm?.feedbackFormEditDetails || null}
						setOpenFeedbackDrawer={setOpenCreateFeedbackForm}
						showMsg={showMsg}
						employeeFeedbackPermission={employeeFeedbackPermission}
					/>
				) : (
					<></>
				)}

				{!openViewFeedbackForm?.open && loader ? (
					<Box className='loading-center-global'>
						<Typography>Loading...</Typography>
					</Box>
				) : !openViewFeedbackForm?.open && noRecords ? (
					<Box className='welcome-content pip-welcome' id='pip-welcome'>
						<Box textAlign='center' className='pad-container'>
							<Typography variant='h2'>
								{selectedFilterDateRange && selectedFilterDateRange?.start?._d && selectedFilterDateRange?.end?._d
									? t('noRecordPip')
									: finalFilterCondition.filterData && finalFilterCondition.filterData.length > 0
									? t('noRecordPip')
									: searchValue
									? t('noRecordPip')
									: t('noFeedbackMsg1')}
							</Typography>
							<Typography>
								{selectedFilterDateRange && selectedFilterDateRange?.start?._d && selectedFilterDateRange?.end?._d
									? t('noRecordPipText')
									: finalFilterCondition.filterData && finalFilterCondition.filterData.length > 0
									? t('noRecordPipText')
									: searchValue
									? t('noRecordPipText')
									: t('noFeedbackMsg2')}
							</Typography>
							<AnimatedIcon className='' width='516' type='image/svg+xml' alt='WelcomeImage' imagePath={WelcomeImage} />
						</Box>
					</Box>
				) : !openViewFeedbackForm?.open ? (
					<>
						<Box className='pip-content-area'>
							<>
								{visibleFeedback !== 2 && performanceImprovementList && performanceImprovementList.length > 0 && (
									<>
										<FeedbackMyList
											performanceData={performanceImprovementList}
											setPerformanceData={setPerformanceImprovementList}
											handlePIPItemClick={handleFeedbackItemClick}
											headingLabel={'My'}
											showLoadMore={
												myPagingInfo.pageIndex !== myPagingInfo.totalPages &&
												myPagingInfo.pageIndex <= myPagingInfo.totalPages
											}
											loadMore={myLoadMore}
										/>
									</>
								)}
							</>
							<>
								{visibleFeedback !== 1 &&
									performanceOthersImprovementList &&
									performanceOthersImprovementList.length > 0 && (
										<>
											{performanceOthersImprovementList &&
											performanceImprovementList &&
											performanceImprovementList.length > 0 &&
											performanceOthersImprovementList.length ? (
												<Box className='pip-others-header'>
													<Typography variant='h2'>
														{t('allNominations')} ({otherPagingInfo.totalRecords})
													</Typography>
												</Box>
											) : (
												<></>
											)}
											<FeedbackMyList
												performanceData={performanceOthersImprovementList}
												setPerformanceData={setPerformanceOthersImprovementList}
												handlePIPItemClick={handleFeedbackItemClick}
												headingLabel={'Other'}
												showLoadMore={
													otherPagingInfo.pageIndex !== otherPagingInfo.totalPages &&
													otherPagingInfo.pageIndex <= otherPagingInfo.totalPages
													//(otherPagingInfo.pageIndex === otherPagingInfo.totalPages)
												}
												loadMore={otherLoadMore}
											/>
										</>
									)}
							</>
						</Box>
					</>
				) : (
					<></>
				)}
			</Box>

			{requestOneOnOneDrawer && requestOneOnOneDrawer?.open ? (
				<CreateOneOnOne
					requestOneOnOneDrawer={requestOneOnOneDrawer}
					setRequestOneOnOneDrawer={setRequestOneOnOneDrawer}
					isDirect={1}
					callingType={'Feedback'}
				/>
			) : (
				<></>
			)}
			{/* Delete user dialog */}
			{twoFactorModalProps && twoFactorModalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={twoFactorModalProps.open}
					handleCloseModal={(e: any) => handleTwoFactorCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleTwoFactorCloseModal(e, 1)}
					headingText={twoFactorModalProps?.message?.headingText}
					subHeading={twoFactorModalProps?.message?.subHeading}
				/>
			)}
			{modalProps?.open && (
				<AlertDialog
					message={modalProps?.message || ''}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
				/>
			)}
		</>
	);
};
