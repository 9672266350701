/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment, useCallback, useRef } from 'react';
import {
	Avatar,
	Box,
	ClickAwayListener,
	FormLabel,
	Grow,
	InputAdornment,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	TextField,
	Typography,
} from '@material-ui/core';
import moment from 'moment';
import { OKRDrawer } from '../../Common/OKRDrawer';
import {
	formatDate,
	getDateRange,
	getDueDays,
	getRandomBackgroundColor,
	getRandomColor,
	getUserDetails,
	getUserName,
	GAevent,
	isTrialVersion,
} from '../../../config/utils';
import { Enums } from '../../../config/enums';
import { OKRButton } from '../../Common/OKRButton';
import '../style.scss';
import {
	DollarIcon,
	EuroIcon,
	PoundIcon,
	RupeeIcon,
	YenIcon,
	PercentageIcon,
	NumbersIcon,
} from '../../../config/svg/MyGoalSvg';
import AlertDialog from '../../Common/Dialog';
import { AddIconSvg, AspirationalIcon, CommittedIcon, EditIcon } from '../../../config/svg/CommonSvg';
import { NumberFormatComponent } from '../../Common/NumberFormat';
import { CallOutTooltip } from '../../OkrAction/CallOutTooltip';
import { AssignContributors } from '../../Common/assignment/AssignContributors';
import { getLocalStorageItem } from '../../../services/StorageService';
import { calloutsDataForKeyResult, defaultCalloutProps } from '../../../config/constant';
import { checkPermission } from '../../../config/rolePermission';
import { assignOkr } from '../../Admin/AdminConstant';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import ReactDateRangePicker from '../../Common/ReactDateRangePicker';
import { KrWeight } from '../../Common/KrWeight';
import { KrConfidence } from '../../Common/KrConfidence';
import { WeeklyTrendDetails } from '../../Common/WeeklyTrendDetails';
import { KababIcon } from '../../../config/svg/CommonSvgIcon';
import { InformationMessage } from '../../Admin/CommonComponent/InformationMessage';

export const KeyResultAttributes: React.FC<any> = (props) => {
	const {
		currentUser,
		open,
		durationCycleDetail,
		handleDrawerClose,
		okrMasterData,
		keyData,
		objectiveStartDate,
		objectiveEndDate,
		setIsUpdated,
		krAttrDateError,
		setKeyAttribute,
		keyAttribute,
		objectiveDetail,
		setKRAttrDateError,
		loader,
		t,
		deleteContributor,
		goalDetail,
		year,
		activeOnboardingPanel,
		getPermissionResult,
		apiCallComplete,
		isLocked,
	} = props;
	const [metricData, setMetricData] = useState<any>([]);
	const [openCalender, setOpenCalender] = useState<boolean>(false);
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [openMenu, setOpenMenu] = useState<boolean>(false);
	const [selectedCurrency, setSelectedCurrency] = useState<any>(null);
	const [isNoUnitOrBoolean, setIsNoUnitOrBoolean] = useState<any>(null);
	const [isBoolean, setIsBoolean] = useState<any>(null);
	const [contributorDetails, setContributorDetails] = useState<any>([]);
	const [isTargetValueUpdated, setIsTargetValueUpdated] = useState<Boolean>(false);
	const [isStartValueUpdated, setIsStartValueUpdated] = useState<Boolean>(false);
	const [isContributorUpdated, setIsContributorUpdated] = useState<Boolean>(false);
	const [isMetricChanged, setIsMetricChanged] = useState<Boolean>(false);
	const [krDesc, setKrDesc] = useState<boolean>(keyAttribute?.goalKeyId ? false : true);
	const loggedInUserDetail = getUserDetails();

	const staticModalProps = {
		open: false,
		type: '',
		message: '',
		module: '',
		event: null,
		data: { metricId: '', type: '', date: '' },
	};
	const [modalProps, setModalProps] = useState(staticModalProps);
	const [selectedMetricDetail, setSelectedMetricDetail] = useState<any>({});
	const [goalTypeId, setGoalTypeId] = useState<number>(0);
	const [krNatureData, setKrNatureData] = useState<any>({});
	const [okrCycleDetail, setOkrCycleDetail] = useState<any>({});
	const [changeMetricModalProps, setChangeMetricModalProps] = useState<any>({
		open: false,
		metricPopupDisplayed: false,
	});

	useEffect(() => {
		const { actionType } = goalDetail || {};
		if (actionType === 'editKR') {
			setOkrCycleDetail(props.userSelectedCycle);
		} else {
			setOkrCycleDetail(objectiveDetail.cycleDetailData ? objectiveDetail.cycleDetailData : props.userSelectedCycle);
		}
	}, []);

	useEffect(() => {
		const { showAttributeDrawer, actionType } = goalDetail || {};
		if (showAttributeDrawer && actionType === 'editKR') {
			loadKrNature(goalDetail.okr.goalNatureId);
			setGoalTypeId(goalDetail.okr.goalTypeId ? goalDetail.okr.goalTypeId : 2);
		}
	}, [goalDetail]);

	const loadKrNature = (natureId: any) => {
		if (natureId && okrMasterData.okrNature) {
			const result = okrMasterData.okrNature.filter((nature: any) => nature.okrNatureId === natureId);
			result.length > 0 && setKrNatureData(result[0]);
		}
	};

	useEffect(() => {
		const { actionType } = goalDetail || {};
		if (actionType !== 'editKR') {
			setGoalTypeId(objectiveDetail.goalTypeId ? objectiveDetail.goalTypeId : 2);
			loadKrNature(objectiveDetail.goalNatureId);
		}
	}, [objectiveDetail]);

	useEffect(() => {
		if (keyAttribute && keyAttribute.contributors) {
			setContributorDetails(keyAttribute.contributors);
		} else if (keyData && keyData.contributors) {
			setContributorDetails(keyData.contributors);
		}
		const { metricId } = keyAttribute;
		const { metricMasters } = okrMasterData;
		const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 0 : keyData?.metricId;
		const selectedCurrencyId = keyAttribute?.currencyId ? keyAttribute.currencyId : keyData?.currencyId;
		let selectedCurrencyDetail: any = {};
		const selectedMetric =
			metricMasters && metricMasters.length
				? metricMasters.find((item: any) => item.metricId === selectedMetricId)
				: null;
		if (selectedMetric && selectedMetric?.name === 'Currency') {
			const { metricDataMaster } = selectedMetric;
			const selectedCurrency =
				metricDataMaster && metricDataMaster.length
					? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
					: null;
			selectedCurrencyDetail = selectedCurrency;
		}
		setSelectedMetricDetail({ selectedMetric, selectedCurrencyDetail });
		if (!keyAttribute.metricId && keyData?.metricId) {
			setKeyAttribute({ ...keyAttribute, metricId: keyData.metricId });
		}
	}, [keyAttribute, keyData]);

	useEffect(() => {
		if (Boolean(okrMasterData)) {
			setMasterData();
			const { metricMasters } = okrMasterData;
			if (keyData.metricId === 2 || keyAttribute.metricId === 4) {
				const metricData =
					Boolean(metricMasters) && metricMasters.length
						? metricMasters.find((item: any) => item.metricId === keyData.metricId || keyAttribute.metricId)
						: '';
				const { metricDataMaster } = metricData;
				const currencyDetail =
					Boolean(metricDataMaster) && metricDataMaster.length
						? metricDataMaster.find((item: any) => item.dataId === keyData.currencyId || keyAttribute.currencyId)
						: '';
				handleCurrencySelection(currencyDetail);
			}
			if ([1, 2, 3].includes(keyData.metricId || keyAttribute.metricId)) {
				setIsNoUnitOrBoolean(
					metricMasters?.find((item: any) => item.metricId === keyData.metricId || keyAttribute.metricId)
				);
				setIsBoolean(null);
			} else if (keyData.metricId === 4 || keyAttribute.metricId === 4) {
				setIsBoolean(metricMasters?.find((item: any) => item.metricId === keyData.metricId || keyAttribute.metricId));
			} else {
				setIsNoUnitOrBoolean(null);
				setIsBoolean(null);
			}
		}
	}, [keyData, okrMasterData]);

	useEffect(() => {
		keyData.keyDescription && updateKeyAttrDetail('keyDescription', keyData.keyDescription);
	}, [keyData]);

	const setMasterData = async () => {
		try {
			const { metricMasters } = okrMasterData || {};
			const updatedData =
				Boolean(metricMasters) && metricMasters.length
					? metricMasters.map(async (item: any, index: number) => {
							let SvgIcon: any = `${item.name}Icon`;
							let Icon: any = await import(`../../../config/svg/MyGoalSvg`);
							item.Component = Icon[SvgIcon];
							item.activeButton = '';
							if (item.isDefault && keyData.metricId === Enums.ZERO && keyAttribute.metricId === Enums.ZERO) {
								//item.activeButton = Enums.ACTIVE;
								//item.isDisabled = true;
								//updateKeyAttrDetail('metricId', item.metricId);
							}
							return item;
					  })
					: [];
			setMetricData(await Promise.all(updatedData));
		} catch (e) {}
	};

	const handleMetricChangeCloseModal = (event: any, type: Number) => {
		if (type === 1) {
			setChangeMetricModalProps({
				...changeMetricModalProps,
				open: false,
				metricPopupDisplayed: true,
			});
			checkNumber(changeMetricModalProps.value, changeMetricModalProps.keyName);
		} else {
			setChangeMetricModalProps({
				...changeMetricModalProps,
				open: false,
			});
		}
		// handelCurrentValueChange(changeMetricModalProps.event);
	};

	const handleCloseModal = (event: any, type: Number) => {
		event.preventDefault();
		if (type === 1) {
			if (modalProps.type === 'AddKRContributor') {
				if (contributorDetails && contributorDetails.length) {
					const metricId =
						modalProps && modalProps?.data && modalProps?.data?.metricId ? modalProps.data.metricId : { metricId: '' };
					const { metricMasters } = okrMasterData;
					const selectedMetricId = metricId ? metricId : keyData?.metricId === 0 ? 0 : keyData?.metricId;
					const selectedCurrencyId = keyAttribute?.currencyId ? keyAttribute.currencyId : keyData?.currencyId;
					let selectedCurrencyDetail: any = {};
					const selectedMetric =
						metricMasters && metricMasters.length
							? metricMasters.find((item: any) => item.metricId === selectedMetricId)
							: null;
					if (selectedMetric && selectedMetric?.name === 'Currency') {
						const { metricDataMaster } = selectedMetric;
						const selectedCurrency =
							metricDataMaster && metricDataMaster.length
								? metricDataMaster.find((item: any) => item.dataId === selectedCurrencyId)
								: null;
						selectedCurrencyDetail = selectedCurrency;
					}
					const updatedContributorDetails = contributorDetails.map((item: any) => ({
						...item,
						startValue: Enums.ZERO,
						targetValue: Enums.ZERO,
						metricName: selectedMetric && selectedMetric?.name ? selectedMetric?.name : '',
						currencyName: selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '',
					}));

					if (goalDetail.actionType === 'editKR' && goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT) {
						let onlyOwner = fetchOnlyOwner(
							contributorDetails,
							selectedMetricId,
							selectedMetric,
							selectedCurrencyDetail
						);
						setKeyAttribute((prev: any) => ({
							...keyAttribute,
							contributors: onlyOwner,
						}));
						setIsMetricChanged(true);
						setContributorDetails(onlyOwner);
					} else {
						setContributorDetails(updatedContributorDetails);
						updateKeyAttrDetail('contributors', updatedContributorDetails);
						setIsMetricChanged(true);
					}
				}
				const target: HTMLElement | null = document.querySelector('.currency-btn');
				setAnchorEl(target);
				updateMetricDetails(target, modalProps.data, 2);
			} else if (modalProps.type === 'updateDateForKR') {
				setKRAttrDateError({ error: '', helperText: '', type: '' });
				let contri: any[] = [];
				if (goalDetail.actionType === 'editKR' && keyAttribute?.contributors?.length > 0) {
					contri = keyAttribute?.contributors;
				} else if (!Boolean(keyAttribute?.goalKeyId) && keyAttribute?.contributors?.length > 0) {
					contri = keyAttribute?.contributors;
				}
				contri.map((cc: any) => {
					if (modalProps.data.type === 'startDate') {
						cc.startDate = formatDate(new Date(modalProps.data.date));
					} else {
						cc.dueDate = formatDate(new Date(modalProps.data.date));
					}
				});
				updateKeyAttrDetail('contributors', contri);
				if (modalProps.data.type === 'startDate') {
					updateKeyAttrDetail('startDate', formatDate(new Date(modalProps.data.date)));
				} else if (modalProps.data.type === 'dueDate') {
					updateKeyAttrDetail('dueDate', formatDate(new Date(modalProps.data.date)));
				}
			}
			if (goalDetail.actionType === 'editKR') {
				checkNumber(0, 'startValue');
				checkNumber(0, 'targetValue');
			}
		}
		setModalProps(staticModalProps);
	};
	const fetchOnlyOwner = (
		contributorsList: any[],
		selectedMetricId: any,
		selectedMetric: any,
		selectedCurrencyDetail: any
	) => {
		let onlyOwner = contributorsList.filter((item: any) => item.isKrOwner);
		if (onlyOwner.length > 0) {
			onlyOwner[0].startValue = parseInt(onlyOwner[0].startValue);
			onlyOwner[0].targetValue = parseInt(onlyOwner[0].targetValue);
			onlyOwner[0].metricId = selectedMetricId;
			onlyOwner[0].metricName = selectedMetric && selectedMetric?.name ? selectedMetric?.name : '';
			onlyOwner[0].currencyName =
				selectedCurrencyDetail && selectedCurrencyDetail?.name ? selectedCurrencyDetail?.name : '';
		}
		return onlyOwner;
	};
	const handleMetricValidation = (contributorDetails: any) => {
		if (
			contributorDetails &&
			contributorDetails.filter((item: any) => item.employeeId !== loggedInUserDetail.employeeId).length
		) {
			return true;
		}

		if (goalDetail.actionType === 'editKR' && goalDetail.kr.currentValue !== goalDetail.kr.startValue) {
			return true;
		}
		return false;
	};

	const updateMetricDetails = (event: any, data: any, type: Number) => {
		if (goalDetail.actionType === 'editKR') {
			updateKeyAttrDetail('startValue', Enums.ZERO);
			updateKeyAttrDetail('targetValue', Enums.ZERO);
		}
		const { metricMasters } = okrMasterData;
		const updatedData =
			Boolean(metricMasters) && metricMasters.length
				? metricMasters.map((item: any) => {
						item.activeButton = '';
						item.isDisabled = false;
						if (item.metricId === data.metricId) {
							item.activeButton = 'active';
						}
						return item;
				  })
				: [];
		let onlyOwner: any[] = [];
		if (data.metricId === 2) {
			type === 1 ? setAnchorEl(event.currentTarget) : setAnchorEl(event);
			setOpenMenu(anchorEl ? false : true);
			const currencyList = Boolean(data) && data.metricDataMaster;
			const selectedCurrencyDetails = currencyList && currencyList.length > 0 ? currencyList[0] : null;
			onlyOwner = fetchOnlyOwner(contributorDetails, data.metricId, data, selectedCurrencyDetails);
			//setSelectedCurrency(selectedCurrencyDetails);
			//setIsNoUnitOrBoolean(selectedCurrencyDetails);
			setIsBoolean(null);
			//updateKeyAttrDetail('currencyId', selectedCurrencyDetails?.dataId);
			if (goalDetail.actionType === 'editKR' && goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT) {
				setKeyAttribute({ ...keyAttribute, contributors: onlyOwner, isMetricChanged: true });
			}
		} else {
			onlyOwner = fetchOnlyOwner(contributorDetails, data.metricId, data, null);
			setAnchorEl(null);
			setOpenMenu(false);
			if (goalDetail.actionType === 'editKR' && goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT) {
				setKeyAttribute({ ...keyAttribute, currencyId: 0, contributors: onlyOwner, isMetricChanged: true });
			} else {
				setKeyAttribute({ ...keyAttribute, currencyId: 0 });
			}
		}
		setMetricData(updatedData);
		updateKeyAttrDetail('metricId', data.metricId);
		setSelectedCurrency(selectedCurrency || null);
		if ([Enums.ONE, Enums.THREE].includes(data.metricId)) {
			setIsNoUnitOrBoolean(data);
			setIsBoolean(null);
		} else if (data.metricId === Enums.FOUR) {
			setIsBoolean(data);
		} else if (data.metricId === Enums.FIVE) {
			setIsNoUnitOrBoolean(null);
			setIsBoolean(null);
		}
		setIsUpdated && setIsUpdated(true);
	};
	/**
	 *
	 * @param _event
	 * @param data
	 */
	const handleMetric = (event: any, data: any) => {
		if (krAttrDateError && krAttrDateError.type === 'krUnit') {
			setKRAttrDateError({ error: '', helperText: '', type: '' });
		}
		try {
			if (handleMetricValidation(contributorDetails)) {
				setModalProps({
					open: true,
					type: 'AddKRContributor',
					message:
						goalDetail.actionType === 'editKR' && goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT
							? props.userSelectedCycle?.cycleStatus === 3 && contributorDetails.length
								? t('futureCycleMetricUpdate')
								: contributorDetails.length > 0
								? t('allContributors')
								: !contributorDetails.length && parseInt(goalDetail.kr.currentValue) > 0
								? t('contributionLost')
								: ''
							: t('metricChangeAlert'),
					module: 'user',
					event,
					data,
				});
			} else {
				setModalProps(staticModalProps);
				updateMetricDetails(event, data, 1);
				if (goalDetail.actionType === 'editKR') {
					checkNumber(0, 'startValue');
					checkNumber(0, 'targetValue');
				}
			}
		} catch (e) {
			console.error(e);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenMenu(false);
	};

	const checkNumber = (value: any, keyName: String) => {
		const regexp = /^[0-9\b]+$/;
		const regexpWithDecimal = /^(\d*\.)?\d+$/;
		if (Boolean(selectedCurrency)) {
			// this is for decimal
			if (value === '' || regexpWithDecimal.test(value)) {
				updateKeyAttrDetail(keyName, value);
			}
		} else {
			// this is for number only
			if (value === '' || regexp.test(value)) {
				updateKeyAttrDetail(keyName, value);
			}
		}
	};

	const handelCurrentValueChange = (event: any) => {
		const { value } = event.target;
		checkNumber(value, 'startValue');
		setIsUpdated && setIsUpdated(true);
		setIsStartValueUpdated(true);
		updateOwnerData('startValue', value);
	};

	const handelTargetValueChange = (event: any) => {
		let { value } = event.target;
		if (value === '0') {
			value = 0;
		}
		checkNumber(value, 'targetValue');
		setIsUpdated && setIsUpdated(true);
		setIsTargetValueUpdated(true);
		setKRAttrDateError({ error: '', helperText: '', type: '' });
		updateOwnerData('targetValue', value);
	};

	const handleCurrencySelection = (selectedCurrency: any) => {
		if (Boolean(selectedCurrency)) {
			setSelectedCurrency(selectedCurrency);
			setIsNoUnitOrBoolean(selectedCurrency);
			setIsBoolean(null);
			updateKeyAttrDetail('currencyId', selectedCurrency.dataId);
			setAnchorEl(null);
			setOpenMenu(false);
		}
	};

	const getCurrencyIcon = (currencyName: String) => {
		switch (currencyName) {
			case 'Dollar':
				return <DollarIcon />;
			case 'Euro':
				return <EuroIcon />;
			case 'Rupee':
				return <RupeeIcon />;
			case 'Yen':
				return <YenIcon />;
			case 'Pound':
				return <PoundIcon />;
			default:
				break;
		}
	};

	const setActiveMetric = (item: any) => {
		let metricId = keyData.metricId;
		if (keyAttribute.metricId) {
			metricId = keyAttribute.metricId;
		}
		if (metricId === item.metricId) {
			return 'active';
		} else if (metricId === Enums.ZERO && item.metricId === Enums.FIVE) {
			//return 'active';
		}

		return item.activeButton;
	};

	const CurrencyList: React.FC<any> = ({ open }) => {
		const { metricMasters } = okrMasterData;
		const currencyMetric =
			Boolean(metricMasters) && metricMasters.length && metricMasters.find((item: any) => item.metricId === 2);
		const currencyList = Boolean(currencyMetric) && currencyMetric.metricDataMaster;

		return (
			<MenuList autoFocusItem={open}>
				{Boolean(currencyList) && currencyList.length ? (
					currencyList.map((item: any) => (
						<MenuItem onClick={() => handleCurrencySelection(item)}>
							{getCurrencyIcon(item.name)}
							{item.name}
						</MenuItem>
					))
				) : (
					<></>
				)}
			</MenuList>
		);
	};
	const updateKeyAttrDetail = useCallback((key: any, data: any) => {
		setOpenCalender(() => false);
		setKeyAttribute((attr: any) => {
			return { ...attr, [key]: data };
		});
	}, []);

	const checkObjectiveIsPrivate = () => {
		if (!checkPermission(getPermissionResult?.employeePermissions, assignOkr)) {
			return true;
		}
		if (goalDetail.actionType === 'editKR' && goalDetail?.okr.isPrivate) {
			return true;
		}
		if (goalDetail?.okr?.goalTypeId === 1 || objectiveDetail.goalTypeId === 1) {
			let ownerId = null;
			if (goalDetail && goalDetail?.okr?.goalTypeId) {
				ownerId = goalDetail.okr.owner;
			} else {
				ownerId = objectiveDetail.owner;
			}
			if (ownerId !== null && ownerId !== loggedInUserDetail.employeeId && goalDetail?.kr?.isContributorOnly !== true) {
				return true;
			}
		}
		if (objectiveDetail?.isPrivate === true) {
			return true;
		}
		return false;
	};
	const getMinDate = (date: any, okrDate: any) => {
		const datesDifferenceInDays = moment(new Date(okrDate)).isAfter(new Date(formatDate(date) || new Date()), 'days');
		return datesDifferenceInDays ? okrDate : date;
	};
	const handleDescChange = () => {
		setKrDesc((prev) => !prev);
	};

	const getTeamData = () => {
		let goalTypeID = 2;
		let teamData: any = '';
		if (goalDetail && goalDetail?.okr?.goalTypeId) {
			goalTypeID = goalDetail.okr.goalTypeId;
			if (goalTypeID === 1) {
				teamData = goalDetail.okr;
			}
		} else {
			goalTypeID = objectiveDetail.goalTypeId;
			if (goalTypeID === 1) {
				teamData = objectiveDetail;
			}
		}
		return teamData;
	};
	//callout popup
	const [showCallouts, setShowCallouts] = useState<boolean>(false);
	useEffect(() => {
		if (getLocalStorageItem('showCallouts') && props.activeOnboardingPanel) {
			setShowCallouts(true);
		}
	}, [props.onboardingCount, props.activeOnboardingPanel]);
	const initialCallout = defaultCalloutProps;
	const calloutsData = calloutsDataForKeyResult;
	const [callOut, setCallOut] = useState(initialCallout);
	const [anchorElCallouts, setAnchorElCallouts] = useState(null);
	const inputRefKrTitle: any = useRef();
	const inputRefKrDescription: any = useRef();
	const inputRefKrDate: any = useRef();
	const okrTeamDetailsRef: any = useRef();
	const porgressMeasureRef: any = useRef();
	const metricsFieldRef: any = useRef();
	const inputRefAssign: any = useRef();
	const [showAssignKrTitleCallouts, setShowAssignKrTitleCallouts] = useState(false);
	const [showAssignKrTargetCallouts, setShowAssignKrTargetCallouts] = useState(false);
	const [showAssignKrDateCallouts, setShowAssignKrDateCallouts] = useState(false);

	useEffect(() => {
		let teamData = getTeamData();
		if (
			activeOnboardingPanel === 'krDrawer' &&
			getLocalStorageItem('krDrawercalloutDone') === null &&
			getLocalStorageItem('showCallouts') === 'true'
		) {
			setShowCallouts(true);
			if (teamData === '') {
				const timeout = setTimeout(() => {
					if (inputRefKrTitle && inputRefKrTitle.current) {
						inputRefKrTitle.current.focus();
					}
					setAnchorElCallouts && setAnchorElCallouts(inputRefKrTitle.current);
					setCallOut({ ...calloutsData[1], prev: false });
				}, 1000);
				return () => {
					clearTimeout(timeout);
				};
			} else {
				//setKrDesc(true);
				const timeout = setTimeout(() => {
					//Callout for KR Title Field
					setAnchorElCallouts && setAnchorElCallouts(okrTeamDetailsRef.current);
					setCallOut(calloutsData[0]);
				}, 1500);
				return () => {
					clearTimeout(timeout);
				};
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeOnboardingPanel]);
	const nextCallout = (currentCalloutIndex: Number) => {
		if (currentCalloutIndex === 0) {
			if (inputRefKrTitle && inputRefKrTitle.current) {
				inputRefKrTitle.current.focus();
			}
			setAnchorElCallouts && setAnchorElCallouts(inputRefKrTitle.current);
			setCallOut(calloutsData[1]);
		} else if (currentCalloutIndex === 1) {
			const element = document.getElementById('inputRefAssignContributor');
			if (element && getLocalStorageItem('showCallouts')) {
				element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
			//Callout for Duedate Field
			if (inputRefKrDate.current) {
				setAnchorElCallouts && setAnchorElCallouts(inputRefKrDate.current);
				setCallOut && setCallOut(calloutsData[2]);
			}
		} else if (currentCalloutIndex === 2) {
			//Callout for KR measurement
			if (porgressMeasureRef.current) {
				setAnchorElCallouts && setAnchorElCallouts(porgressMeasureRef.current);
				setCallOut && setCallOut(calloutsData[3]);
			}
		} else if (currentCalloutIndex === 3) {
			//Callout for KR Contributors
			if (inputRefAssign.current) {
				const element = document.getElementById('inputRefAssignContributor');
				if (element && getLocalStorageItem('showCallouts')) {
					element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
				}
				setAnchorElCallouts && setAnchorElCallouts(inputRefAssign.current);
				setCallOut && setCallOut(calloutsData[4]);
			}
		} else if (currentCalloutIndex === 6) {
			setShowAssignKrTargetCallouts(true);
		} else if (currentCalloutIndex === 7) {
			setShowAssignKrDateCallouts(true);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};
	const prevCallout = (currentCalloutIndex: Number) => {
		if (currentCalloutIndex === 0) {
		} else if (currentCalloutIndex === 1) {
			setAnchorElCallouts && setAnchorElCallouts(okrTeamDetailsRef.current);
			setCallOut(calloutsData[0]);
		} else if (currentCalloutIndex === 2) {
			const element = document.getElementById('inputKrTitle');
			if (element && getLocalStorageItem('showCallouts')) {
				element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
			}
			setAnchorElCallouts && setAnchorElCallouts(inputRefKrTitle.current);
			let teamData = getTeamData();
			if (teamData === '') {
				setCallOut({ ...calloutsData[1], prev: false });
			} else {
				setCallOut(calloutsData[1]);
			}
		} else if (currentCalloutIndex === 3) {
			setAnchorElCallouts && setAnchorElCallouts(inputRefKrDate.current);
			setCallOut && setCallOut(calloutsData[2]);
		} else if (currentCalloutIndex === 4) {
			setAnchorElCallouts && setAnchorElCallouts(porgressMeasureRef.current);
			setCallOut && setCallOut(calloutsData[3]);
		} else if (currentCalloutIndex === 7) {
			setShowAssignKrTitleCallouts(true);
			setShowAssignKrTargetCallouts(false);
		} else if (currentCalloutIndex === 8) {
			setShowAssignKrTargetCallouts(true);
		} else {
			setAnchorElCallouts(null);
			setCallOut(initialCallout);
		}
	};

	useEffect(() => {
		const startDateValue = getMinDate(
			keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
			goalDetail.actionType === 'editKR' ? goalDetail?.okr.startDate : objectiveStartDate
		);
		const endDateValue = keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate || objectiveEndDate;
		updateDueDays(startDateValue, endDateValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
		// setApiCallComplete && setApiCallComplete(false);
	}, []);

	useEffect(() => {
		if (goalDetail?.okr?.startDate && goalDetail?.okr?.dueDate) {
			let startDate = getMinDate(
				keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
				goalDetail.actionType === 'editKR' ? goalDetail?.okr.startDate : objectiveStartDate
			);
			let endDate = keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate || objectiveEndDate;
			setSelectedDateRange(getDateRange(startDate, endDate));
			updateDueDays(startDate, endDate);
		}
	}, [goalDetail?.okr?.startDate, goalDetail?.okr?.dueDate]);

	const updateDueDays = (startDt: any, endDt: any) => {
		const diffInDays = getDueDays(
			startDt,
			endDt,
			Object.keys(okrCycleDetail).length !== 0 ? okrCycleDetail : props.userSelectedCycle
		);
		setDueDaysDiff(diffInDays);
	};

	const dateChangeHandler = (range: any, start: any, end: any, selectionType: any) => {
		updateKeyAttrDetail('startDate', formatDate(new Date(start)));
		updateKeyAttrDetail('dueDate', formatDate(new Date(end)));
		updateOwnerData('startDate', formatDate(new Date(start)));
		updateOwnerData('dueDate', formatDate(new Date(end)));
	};

	const updateOwnerData = (key: string, value: any) => {
		if (contributorDetails.length > 0 && contributorDetails[0].isKrOwner === true) {
			let ownerContrib = Object.assign({}, contributorDetails[0]);
			ownerContrib[key] = value;
			switch (key) {
				case 'startValue':
					ownerContrib['ownStartValue'] = value;
					break;
				case 'targetValue':
					ownerContrib['ownTargetValue'] = value;
					break;
				default:
					break;
			}
			contributorDetails[0] = ownerContrib;
			updateKeyAttrDetail('contributors', contributorDetails);
		}
	};

	const [selectedDateRange, setSelectedDateRange] = useState<any>();
	const [dueDaysDiff, setDueDaysDiff] = useState<any>(0);
	const cycleDetail = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const [openWeeklyTrendDrawer, setOpenWeeklyTrendDrawer] = useState<any>({ open: false });

	const handleOpenWeeklyTrend = (event: any, details: any, type: number, confidenceValue: number, goalData: any) => {
		let krData = { ...goalData };
		if (goalData?.okr?.employeeId) {
			krData = { ...goalData?.okr };
		} else if (krData) {
			krData.employeeId = objectiveDetail?.employeeId || 0;
		}
		setOpenWeeklyTrendDrawer({ open: true, data: { okr: details, type, confidenceValue, rootDetail: krData } });
	};

	const handleWeightChange = (event: any) => {
		const { value } = event.target;
		updateKeyAttrDetail('weightId', parseInt(value));
		// setIsUpdated(true);
	};
	const checkTargetValue = () => {
		let targetValue = keyAttribute.targetValue
			? keyAttribute.targetValue
			: isTargetValueUpdated
			? keyAttribute.targetValue || keyAttribute.targetValue === 0
				? keyAttribute.targetValue
				: ''
			: keyAttribute.targetValue || '';

		let startValue = keyAttribute.startValue
			? keyAttribute.startValue
			: isStartValueUpdated
			? keyAttribute.startValue
				? keyAttribute.startValue
				: ''
			: keyAttribute.startValue || '';

		//return keyAttribute.targetValue === 0 ? true : targetValue;
		return (startValue > 0 || targetValue > 0) && startValue !== targetValue ? true : false;
	};

	const updateGroupTargetValue = (checked: boolean) => {
		updateKeyAttrDetail('isGroupTarget', checked);
	};

	return (
		<Fragment>
			<OKRDrawer
				apiCallComplete={apiCallComplete}
				headerTitle={t('KrAttributeText')}
				drawerClassName={'main-drawer-panel main-drawer-titlepanel'}
				open={open}
				transitionDuration={{ enter: 500, exit: 1000 }}
				moreButton={<></>}
				handleDrawerClose={(event: any) => {
					props.updateCalloutPanel('');
					setCallOut && setCallOut(initialCallout);
					handleDrawerClose(event, 'close');
				}}
				handleSaveClick={(event: any) => {
					isTrialVersion() &&
						GAevent(
							'SaveIndividualKeyResultFromDrawer',
							'Clicked Save Individual Key Result From Drawer',
							'Save key result'
						);
					props.updateCalloutPanel('');
					handleDrawerClose(event, 'save');
				}}
				handleCancelClick={(event: any) => {
					props.updateCalloutPanel('');
					setCallOut && setCallOut(initialCallout);
					handleDrawerClose(event, 'close');
				}}
				hideDefaultButton={false}
				//isSaveButtonDisabled={isKRAttributeUpdated() && !isContributorUpdated}
				isSaveButtonDisabled={apiCallComplete || isLocked}
				loader={loader}
				isAnyDrawerOpened={props.isAnyDrawerOpened}
				appDrawerInfo={props.appDrawerInfo}
			>
				<Box className='drawer-inner-content'>
					<Box className='drawer-content-panel'>
						<form className='drawer-form-fields' noValidate autoComplete='off'>
							{keyAttribute.isDefaultGoalKey && (
								<>
									<InformationMessage
										messageText={t('defaultKrAttributeText')}
										icon={true}
										className={'information-message-user information-message-user-type1'}
									/>
								</>
							)}
							<Box className='drawer-input-field key-result-head'>
								{krNatureData && krNatureData.name && (
									<Box id={`kr-nature`} className='okr-nature-type-tag kr-nature-type'>
										{krNatureData.okrNatureId === 1 ? <AspirationalIcon /> : <CommittedIcon />}
										<Typography variant='subtitle2'>{krNatureData.name}</Typography>
									</Box>
								)}
							</Box>
							<Box className='drawer-input-field helperText-bottom'>
								<TextField
									label={t('keyResult')}
									value={keyAttribute?.keyDescription}
									onChange={(event: any) => {
										updateKeyAttrDetail('keyDescription', event.target.value);
									}}
									fullWidth
									multiline
									error={(krAttrDateError.type === 'keyDescription' && krAttrDateError?.error) || ''}
									helperText={
										(krAttrDateError.type === 'keyDescription' && krAttrDateError?.helperText && (
											<>
												<ErrorIcon />
												{krAttrDateError?.helperText}
											</>
										)) ||
										''
									}
									rows={2}
									placeholder={t('KeyResultPlaceholderText')}
									InputProps={{
										'aria-label': t('keyResult'),
										disableUnderline: true,
										disabled: keyAttribute.isDefaultGoalKey || isLocked,
									}}
									inputRef={inputRefKrTitle}
									//onBlur={callOutOwnerType}
									id='inputKrTitle'
								/>
							</Box>
							{keyAttribute.weightId && (
								<Box
									className={`drawer-input-field drawer-date-field helperText-bottom ${
										keyAttribute.isDefaultGoalKey || isLocked ? 'disabled-weight' : ''
									}`}
								>
									<KrWeight
										{...props}
										handleWeightChange={handleWeightChange}
										viewOnly={goalDetail?.kr?.importedId > 0 || keyAttribute.isDefaultGoalKey || isLocked}
									/>
								</Box>
							)}
							<Box
								className={`drawer-input-field drawer-date-field helperText-bottom ${
									keyAttribute.isDefaultGoalKey || isLocked ? 'disabled-virtual' : ''
								}`}
							>
								<FormLabel id='kr-set-timeframe' component='legend'>
									{t('setATimeframe')}
								</FormLabel>
								<ReactDateRangePicker
									type={'KRAttribute'}
									currentCycleEndDate={
										new Date(
											goalDetail.actionType === 'editKR' ? goalDetail?.okr.dueDate : objectiveEndDate || new Date()
										)
									}
									durationCycleDetail={durationCycleDetail}
									startDateValue={getMinDate(
										keyAttribute.startDate ? keyAttribute.startDate : keyData.startDate,
										goalDetail.actionType === 'editKR' ? goalDetail?.okr.startDate : objectiveStartDate
									)}
									endDateValue={keyAttribute.dueDate ? keyAttribute.dueDate : keyData.dueDate || objectiveEndDate}
									minimumDate={goalDetail.actionType === 'editKR' ? goalDetail?.okr.startDate : objectiveStartDate}
									maximumDate={
										new Date(
											goalDetail.actionType === 'editKR' ? goalDetail?.okr.dueDate : objectiveEndDate || new Date()
										)
									}
									isDateRangeTextFieldVisible={true}
									handleDateSelection={(
										range: any,
										start: any,
										end: any,
										selectionType: String,
										isCycleChanged: Boolean
									) => {
										updateDueDays(start, end);
										setSelectedDateRange(range);
										dateChangeHandler(range, start, end, selectionType);
									}}
									bottomChildComponent={<></>}
									allCycleDetails={cycleDetail}
									dueDaysDiff={dueDaysDiff}
									setDueDaysDiff={setDueDaysDiff}
									isCurrentCycle={okrCycleDetail.organisationCycleId}
									isCycleVisible={true}
									isCycleDisable={true}
									isEndOfMonthVisible={false}
									numberOfCalendars={Enums.TWO}
									selectedDateRange={selectedDateRange}
									setSelectedDateRange={setSelectedDateRange}
									selectedCycleDetails={okrCycleDetail}
									showBottomText={true}
									isDatePickerDisable={keyAttribute.isDefaultGoalKey || isLocked}
								/>
							</Box>
							<Box className='drawer-input-field drawer-date-field helperText-bottom'>
								<KrConfidence
									{...props}
									confidenceLabel={true}
									handleOpenWeeklyTrend={(event: any) =>
										handleOpenWeeklyTrend(event, keyAttribute, props?.type || 2, keyAttribute.confidenceId, goalDetail)
									}
									handleConfidenceClick={(confidenceValue: number) => {
										if (!isLocked) {
											updateKeyAttrDetail('confidenceId', confidenceValue);
										}
									}}
									confidenceValue={keyAttribute.confidenceId}
									isLocked={isLocked}
									showWeekelyTrend={
										goalDetail.actionType === 'editKR' &&
										goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT &&
										!props.isFutureCycle
									}
								/>
							</Box>
							<Box
								className={`drawer-input-field  kr-measure-unit choose-metric-field ${
									keyAttribute.isDefaultGoalKey || isLocked ? 'disabled-virtual' : ''
								}`}
							>
								<FormLabel id='metric-label' className='label-des' component='legend' ref={porgressMeasureRef}>
									{t('porgressMeasure')}
								</FormLabel>
								<Box className='choose-metric-msg'>
									<Typography id='metric-helptext' className='short-des-text'>
										{t('chooseMetricMessage')}
									</Typography>
								</Box>
								<Box className='display-flex'>
									{metricData.map((item: any, index: number) => {
										const { Component } = item;
										return (
											<OKRButton
												className={`measure-btn ${item.name?.toLowerCase()}-btn ${setActiveMetric(item)}`}
												handleClick={(event: any) => {
													//setCallOut && setCallOut(initialCallout);
													handleMetric(event, item);
													//callOutMetricsField(item);
												}}
												key={index}
												id={`measure-btn-${index}`}
												icon={<Component />}
												text={
													item.name === 'Currency' ? (
														<>
															{item.description}
															<KababIcon />
														</>
													) : (
														item.description
													)
												}
												disabled={
													// goalDetail?.actionType === 'editKR' &&
													// goalDetail?.okr?.owner !== loggedInUserDetail.employeeId &&
													goalDetail?.kr?.importedId > 0 || keyAttribute.isDefaultGoalKey || isLocked
												}
												// disabled={
												// 	goalDetail?.actionType === 'editKR' && goalDetail?.okr?.goalStatusId !== Enums.ONE
												// 		? true
												// 		: item.isDisabled
												// }
												title={
													!getLocalStorageItem('showCallouts') || anchorElCallouts === null ? (
														item.name === 'Percentage' ? (
															t('percentageMetricText')
														) : item.name === 'Numbers' ? (
															t('numberMetricText')
														) : item.name === 'Currency' ? (
															t('currencyMetricText')
														) : item.name === 'Boolean' ? (
															t('booleanMetricText')
														) : item.name === 'NoUnits' ? (
															t('noUnitsMetricText')
														) : (
															<></>
														)
													) : (
														''
													)
												}
												tooltipClassName='tooltip-layout4'
											/>
										);
									})}
									<Popper className='popper-menu-list' open={openMenu} anchorEl={anchorEl} transition placement='right'>
										{({ TransitionProps }) => (
											<Grow {...TransitionProps}>
												<Paper>
													<ClickAwayListener onClickAway={handleClose}>
														<CurrencyList open={openMenu} />
													</ClickAwayListener>
												</Paper>
											</Grow>
										)}
									</Popper>
								</Box>
								{/* {selectedMetricDetail?.selectedMetric && selectedMetricDetail?.selectedMetric?.metricId === 5 ? (
									<Box className='msg'>{t('trackingProgress')}</Box>
								) : (
									<></>
								)} */}
								<Box className='helperText-bottom'>
									{krAttrDateError.type === 'krUnit' && krAttrDateError?.error && (
										<span className='error-field'>
											<ErrorIcon />
											{krAttrDateError?.helperText}
										</span>
									)}
								</Box>
							</Box>

							{Boolean(isNoUnitOrBoolean) && !Boolean(isBoolean) ? (
								<Box className='drawer-input-field'>
									<Box className='kr-metrics-fileds' {...({ ref: metricsFieldRef } as any)}>
										<Box className='kr-metrics-fileds-inner'>
											<TextField
												// disabled={goalDetail?.actionType === 'editKR' && goalDetail?.okr?.goalStatusId !== Enums.ONE}
												// disabled={goalDetail?.okr?.teamId > 0 && !goalDetail?.kr?.isAssigned}
												value={
													keyAttribute.startValue
														? keyAttribute.startValue
														: isStartValueUpdated
														? keyAttribute.startValue
															? keyAttribute.startValue
															: ''
														: keyAttribute.startValue || ''
												}
												disabled={
													(keyAttribute?.importedId > Enums.ZERO &&
														keyAttribute?.goalKeyId &&
														keyAttribute?.isGroupTarget) ||
													keyAttribute.isDefaultGoalKey || isLocked
														? true
														: false
												}
												className='current-value helperText-bottom'
												id={'kr-start-date'}
												label={t('start')}
												placeholder={selectedCurrency ? '0' : '0'}
												fullWidth
												error={(krAttrDateError.type === 'startValue' && krAttrDateError?.error) || ''}
												helperText={
													(krAttrDateError.type === 'startValue' && krAttrDateError?.helperText && (
														<>
															<ErrorIcon />
															{krAttrDateError?.helperText}
														</>
													)) ||
													''
												}
												onChange={(event: any) => {
													if (
														!keyAttribute?.isMetricChanged &&
														goalDetail?.actionType === 'editKR' &&
														goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT &&
														!changeMetricModalProps.metricPopupDisplayed &&
														keyAttribute?.contributors.length > 0
													) {
														if (!changeMetricModalProps.open) {
															setChangeMetricModalProps({
																open: true,
																event: event,
																value: event.target.value,
																keyName: 'startValue',
																message: keyAttribute?.isGroupTarget
																	? t('changeTargetValueGroupTarget')
																	: t('changeTargetValue'),
															});
														}
													} else {
														handelCurrentValueChange(event);
													}
												}}
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
												InputProps={{
													...(selectedMetricDetail &&
														selectedMetricDetail?.selectedMetric?.name !== 'Percentage' && {
															inputComponent: NumberFormatComponent,
														}),
													startAdornment: (
														<InputAdornment position='start'>
															{selectedMetricDetail && selectedMetricDetail?.selectedMetric?.name === 'Percentage' ? (
																<PercentageIcon />
															) : selectedMetricDetail && selectedMetricDetail?.selectedMetric?.name === 'Numbers' ? (
																<NumbersIcon />
															) : (
																getCurrencyIcon(
																	selectedMetricDetail?.selectedCurrencyDetail?.name
																		? selectedMetricDetail?.selectedCurrencyDetail.name
																		: 'Dollar'
																)
															)}
														</InputAdornment>
													),
												}}
											/>
											<Box className='matrics-field-seprator' component='span'>
												{'/'}
											</Box>
											<TextField
												// disabled={goalDetail?.actionType === 'editKR' && goalDetail?.okr?.goalStatusId !== Enums.ONE}
												// disabled={goalDetail?.actionType === 'editKR' && goalDetail?.kr?.isAssigned}
												value={
													keyAttribute.targetValue
														? keyAttribute.targetValue
														: isTargetValueUpdated
														? keyAttribute.targetValue || keyAttribute.targetValue === 0
															? keyAttribute.targetValue
															: ''
														: keyAttribute.targetValue || ''
												}
												disabled={
													(keyAttribute?.importedId > Enums.ZERO &&
														keyAttribute?.goalKeyId &&
														keyAttribute?.isGroupTarget) ||
													keyAttribute.isDefaultGoalKey || isLocked
														? true
														: false
												}
												className='target-value helperText-bottom'
												id={'kr-target-date'}
												label={t('target')}
												placeholder={selectedCurrency ? '0' : '0'}
												fullWidth
												error={(krAttrDateError.type === 'targetValue' && krAttrDateError?.error) || ''}
												helperText={
													(krAttrDateError.type === 'targetValue' && krAttrDateError?.helperText && (
														<>
															<ErrorIcon />
															{krAttrDateError?.helperText}
														</>
													)) ||
													''
												}
												// onChange={handelTargetValueChange}
												onChange={(event: any) => {
													if (
														!keyAttribute?.isMetricChanged &&
														goalDetail?.actionType === 'editKR' &&
														goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT &&
														!changeMetricModalProps.metricPopupDisplayed &&
														keyAttribute?.contributors.length > 0
													) {
														if (!changeMetricModalProps.open) {
															setChangeMetricModalProps({
																open: true,
																event: event,
																message: keyAttribute?.isGroupTarget
																	? t('changeTargetValueGroupTarget')
																	: t('changeTargetValue'),
																value: event.target.value,
																keyName: 'targetValue',
															});
														}
													} else {
														handelTargetValueChange(event);
													}
												}}
												InputLabelProps={{
													shrink: true,
												}}
												inputProps={{ maxLength: Enums.MAX_LENGTH_CURRENT_TARGET }}
												name={selectedMetricDetail?.selectedMetric?.name !== 'Percentage' ? 'currency' : ''}
												InputProps={{
													...(selectedMetricDetail &&
														selectedMetricDetail?.selectedMetric?.name !== 'Percentage' && {
															inputComponent: NumberFormatComponent,
														}),
													startAdornment: (
														<InputAdornment position='start'>
															{selectedMetricDetail && selectedMetricDetail?.selectedMetric?.name === 'Percentage' ? (
																<PercentageIcon />
															) : selectedMetricDetail && selectedMetricDetail?.selectedMetric?.name === 'Numbers' ? (
																<NumbersIcon />
															) : (
																getCurrencyIcon(
																	selectedMetricDetail?.selectedCurrencyDetail?.name
																		? selectedMetricDetail?.selectedCurrencyDetail.name
																		: 'Dollar'
																)
															)}
														</InputAdornment>
													),
												}}
											/>
										</Box>
									</Box>
								</Box>
							) : Boolean(isBoolean) ? (
								<></>
							) : (
								<> </>
							)}

							{checkObjectiveIsPrivate() ? (
								<></>
							) : (
								<>
									{(goalDetail?.okr?.goalTypeId === 0 && goalDetail.actionType === 'editKR') ||
									(objectiveDetail.goalTypeId === 0 && goalDetail.actionType !== 'editKR') ? (
										<Box className={`drawer-input-field ${keyAttribute.isDefaultGoalKey || isLocked ? 'disabled-virtual' : ''}`}>
											<Box className='kr-assign-contributor assignee-data'>
												<Box className='assign-contributor-label'>
													<Box display='flex' alignItems='center'>
														<Fragment>
															<OKRButton
																className={`add-btn2 no-pointer grey-out-section`}
																icon={<AddIconSvg />}
																color='primary'
																disabled={keyAttribute.isDefaultGoalKey || isLocked}
																id={'add-contributor-btn'}
															/>
															<FormLabel component='legend'>{t('contributorsLabel')}</FormLabel>
														</Fragment>
													</Box>
													<Box>
														<OKRButton
															className={`btn-link-type1 disabled no-pointer`}
															disabled={true}
															icon={<EditIcon />}
															text={t('manageContributor')}
															id={'mng-contributor-btn'}
														/>
													</Box>
												</Box>
												<Typography className='short-des-text'>{t('noGoalTypeContributor')}</Typography>
											</Box>
										</Box>
									) : objectiveDetail.owner !== loggedInUserDetail.employeeId &&
									  goalDetail?.actionType !== 'addNewKr' &&
									  goalDetail?.kr?.isContributorOnly !== true ? (
										''
									) : (
										<Box
											className='drawer-input-field'
											{...({ ref: inputRefAssign } as any)}
											id='inputRefAssignContributor'
										>
											{selectedMetricDetail?.selectedMetric && selectedMetricDetail?.selectedMetric?.metricId ? (
												<AssignContributors
													underAssign={props.underAssign}
													updatePage={props.updatePage}
													getOwnerTeamsData={props.getOwnerTeamsData}
													globalSearchAPIWithTeam={props.globalSearchAPIWithTeam}
													checkAlignStatus={props.checkAlignStatus}
													keyData={keyData}
													kr={goalDetail.kr}
													okr={goalDetail && goalDetail.okr ? goalDetail.okr : objectiveDetail}
													okrMasterData={okrMasterData}
													durationCycleDetail={durationCycleDetail}
													currentUser={currentUser}
													keyAttribute={keyAttribute}
													objectiveDetail={objectiveDetail}
													year={year}
													goalTypeId={goalTypeId}
													isContributorOnly={goalDetail?.kr?.isContributorOnly}
													actionType={goalDetail.actionType}
													selectedMetricDetail={selectedMetricDetail}
													objectiveStartDate={objectiveStartDate}
													objectiveEndDate={objectiveEndDate}
													updateKeyAttrDetail={updateKeyAttrDetail}
													updateMetricDetails={updateMetricDetails} // addtarget
													setIsContributorUpdated={setIsContributorUpdated}
													deleteContributor={deleteContributor}
													isNoUnitOrBoolean={isNoUnitOrBoolean}
													isBoolean={isBoolean}
													isStartValueUpdated={isStartValueUpdated}
													isTargetValueUpdated={isTargetValueUpdated}
													showAttributeDrawer={goalDetail.showAttributeDrawer}
													contributorDetails={contributorDetails}
													setContributorDetails={setContributorDetails}
													setCallOut={setCallOut}
													setAnchorElCallouts={setAnchorElCallouts}
													anchorElCallouts={anchorElCallouts}
													initialCallout={initialCallout}
													setShowAssignKrDateCallouts={setShowAssignKrDateCallouts}
													showAssignKrDateCallouts={showAssignKrDateCallouts}
													setShowAssignKrTargetCallouts={setShowAssignKrTargetCallouts}
													showAssignKrTargetCallouts={showAssignKrTargetCallouts}
													showAssignKrTitleCallouts={showAssignKrTitleCallouts}
													setShowAssignKrTitleCallouts={setShowAssignKrTitleCallouts}
													calloutsData={calloutsData}
													updateCalloutPanel={props.updateCalloutPanel}
													activeOnboardingPanel={activeOnboardingPanel}
													getPermissionResult={getPermissionResult}
													userSelectedCycle={okrCycleDetail}
													isKeyResultDrawer={
														goalDetail.actionType === 'editKR' &&
														goalDetail?.okr?.goalStatusId !== Enums.GOAL_STATUS_DRAFT
															? true
															: false
													}
													checkTargetValue={checkTargetValue}
													updateGroupTargetValue={updateGroupTargetValue}
													isAddContributor={props.isAddContributor}
													setIsAddContributor={props.setIsAddContributor}
													isLocked={isLocked}
												/>
											) : (
												<Box className='kr-assign-contributor assignee-data'>
													<Box className='assign-contributor-label'>
														<Box display='flex' alignItems='center'>
															<Fragment>
																<OKRButton
																	className={`add-btn2 no-pointer  grey-out-section`}
																	icon={<AddIconSvg />}
																	color='primary'
																	disabled={false}
																	id={'add-contributor-btn'}
																/>
																<FormLabel component='legend'>{t('contributorsLabel')}</FormLabel>
															</Fragment>
														</Box>
														<Box>
															<OKRButton
																className={`btn-link-type1 disabled no-pointer`}
																disabled={isLocked}
																icon={<EditIcon />}
																text={t('manageContributor')}
																id={'mng-contributor-btn'}
															/>
														</Box>
													</Box>
													<Typography className='short-des-text'>{t('noMetricContributor')}</Typography>
												</Box>
											)}
										</Box>
									)}
								</>
							)}
						</form>
					</Box>
				</Box>
			</OKRDrawer>
			{modalProps.open && (
				<AlertDialog
					module={modalProps.module ? modalProps.module : 'user'}
					message={modalProps.message}
					handleCloseModal={handleCloseModal}
					modalOpen={modalProps.open}
					isCancel={false}
				/>
			)}
			{changeMetricModalProps.open && (
				<AlertDialog
					module={changeMetricModalProps.module ? changeMetricModalProps.module : 'user'}
					message={changeMetricModalProps.message}
					handleCloseModal={handleMetricChangeCloseModal}
					modalOpen={changeMetricModalProps.open}
					isCancel={false}
				/>
			)}
			{showCallouts && (
				<CallOutTooltip
					open={callOut.open}
					anchorEl={anchorElCallouts}
					setAnchorEl={setAnchorElCallouts}
					setCallOut={setCallOut}
					text={t(callOut.text)}
					placement={callOut.placement}
					classname={callOut.classname}
					handlePrev={() => prevCallout(callOut.currentCalloutIndex)}
					handleNext={() => nextCallout(callOut.currentCalloutIndex)}
					showNext={callOut.next}
					showPrevious={callOut.prev}
					showDone={callOut.done}
					activeOnboardingPanel={activeOnboardingPanel}
				/>
			)}
			{openWeeklyTrendDrawer.open && (
				<WeeklyTrendDetails
					{...props}
					openWeeklyTrendDrawer={openWeeklyTrendDrawer}
					setOpenWeeklyTrendDrawer={setOpenWeeklyTrendDrawer}
				/>
			)}
		</Fragment>
	);
};
