import {
	Avatar,
	Box,
	FormControlLabel,
	FormLabel,
	List,
	ListItem,
	RadioGroup,
	Radio,
	Tooltip,
	Typography,
	ButtonGroup,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OKRDrawer } from '../../Common/OKRDrawer';
import { CheckInVisibilitySettings } from './CheckInVisibilitySettings';
import { getUserDetails, sortArrayByOrder } from '../../../config/utils';
import { useSnackbar } from 'notistack';
import AlertDialog from '../../Common/Dialog';

export const CheckInVisibilitySettingsDrawer: React.FC<any> = (props) => {
	const { openSettingDrawer, setOpenSettingDrawer } = props;
	const { t } = useTranslation();
	const [addUserAnchorEl, setAddUserAnchorEl] = useState<HTMLElement | null>(null);
	const [addUserOpen, setAddUserOpen] = React.useState<boolean>(false);
	const [customStyle, setCustomStyle] = useState<Boolean>(false);
	const userDetail: any = getUserDetails();
	const [checkinSettingList, setCheckinSettingList] = React.useState<any>([]);
	const [sortOrder, setSortOrder] = useState<string>('asc');
	const [sortingColumn, setSortingColumn] = useState<string>('');

	const { enqueueSnackbar } = useSnackbar();
	const [selectedTab, setSelectedTab] = React.useState<Number>(2);
	const [isVisibilitySettingEdited, setIsVisibilitySettingEdited] = useState<boolean>(false);
	const [unSaveChangeModalProps, setUnSaveChangeModalProps] = useState<any>({ open: false, message: '', details: '' });
	const [pagingInfo, setPagingInfo] = useState<any>({
		pageIndex: 1,
		pageSize: 10,
		totalPages: 1,
		totalRecords: 0,
	});
	const handleAddUsersClick = (event: any) => {
		setAddUserAnchorEl(addUserAnchorEl ? null : event.currentTarget);
		setAddUserOpen(true);
	};
	useEffect(() => {
		checkinSettingData();
	}, []);

	const checkinSettingData = async (pageIndex = 1, pageSize = 10) => {
		const response: any = await props.getCheckInSettingList(
			`employeeId=${userDetail.employeeId}&pageIndex=${pageIndex}&pageSize=${pageSize}`
		);
		if (Boolean(response) && response.data.status === 200) {
			setPagingInfo(response.data.pagingInfo);
			if (response.data.pagingInfo?.pageIndex === 1) {
				setSelectedTab(response.data.entity.checkInVisibilty || 2);
				setCheckinSettingList(response.data.entity.employeeCheckInVisibles);
			} else {
				if (response.data.entity.employeeCheckInVisibles && response.data.pagingInfo?.pageIndex > 1) {
					setCheckinSettingList([...checkinSettingList, ...response.data.entity.employeeCheckInVisibles]);
				} else if (
					response.data.entity.employeeCheckInVisibles === null ||
					response.data.entity.employeeCheckInVisibles.length === 0
				) {
					setCheckinSettingList([]);
				}
			}
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleSearchUserInputChange = (input: any, _searchUserRef: any) => {
		if (input === '') {
			setCustomStyle(false);
		} else {
			setCustomStyle(true);
		}
	};

	const saveCheckin = async () => {
		let postData: any = {
			employeeIds: [],
			checkInVisibilty: selectedTab,
		};
		let list: any[] = checkinSettingList.forEach((data: any) => {
			if (data.isNew) {
				postData.employeeIds.push({ employeeId: data.employeeId });
			}
		});
		const response: any = await props.saveCheckInSettingList(postData);
		if (Boolean(response) && response.data.status === 200) {
			checkinSettingData();
			setOpenSettingDrawer(false);
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(messages, {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleSettingDrawerAction = (event: any, type: string) => {
		if (type === 'save') {
			saveCheckin();
		} else {
			if (isVisibilitySettingEdited) {
				setUnSaveChangeModalProps({ open: true, message: t('taskUnsavedChanges'), details: '' });
			} else {
				setOpenSettingDrawer(false);
			}
		}
	};
	const handlePerformanceCloseModal = async (e: any, type: any) => {
		e.preventDefault();
		if (type === 1) {
			setOpenSettingDrawer(false);

			setUnSaveChangeModalProps({ open: false, message: '', details: '' });
		} else {
			setUnSaveChangeModalProps({ open: false, message: '', details: '' });
		}
	};

	const deleteCheckin = async (data: any) => {
		if (data.isNew) {
			let selectedIds = [...checkinSettingList];
			const index = selectedIds.findIndex((item) => item.employeeId === data.employeeId);
			if (index > -1) {
				selectedIds.splice(index, 1);
			}
			setCheckinSettingList(selectedIds);
			setIsVisibilitySettingEdited(true);
			return;
		}
		const response: any = await props.deleteCheckInSettingList(`employeeId=${data.employeeId}`);
		if (Boolean(response) && response.data.status === 200) {
			checkinSettingData();
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(messages, {
				variant: 'success',
				autoHideDuration: 5000,
			});
		} else {
			enqueueSnackbar(t('someErrorOccurred'), {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};
	const sortUserList = (e: any, column: any) => {
		let newSortOrder = 'desc';
		let copyCheckinSettingList = checkinSettingList && checkinSettingList?.length ? [...checkinSettingList] : [];
		if (column === sortingColumn) {
			setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
			newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
		} else {
			setSortOrder('desc');
		}
		copyCheckinSettingList = sortArrayByOrder(copyCheckinSettingList, column, newSortOrder);
		setCheckinSettingList([...copyCheckinSettingList]);
		setSortingColumn(column);
	};

	return (
		<>
			<OKRDrawer
				open={openSettingDrawer}
				//loader={loader}
				transitionDuration={{ enter: 500, exit: 1000 }}
				drawerClassName={'main-drawer-panel main-drawer-panel-space'}
				headerTitle={<>{t('checkInsVisibilitySettings')}</>}
				children={
					<Box className='drawer-inner-content'>
						<CheckInVisibilitySettings
							{...props}
							checkinSettingList={checkinSettingList}
							setCheckinSettingList={setCheckinSettingList}
							pagingInfo={pagingInfo}
							checkinSettingData={checkinSettingData}
							deleteCheckin={deleteCheckin}
							selectedTab={selectedTab}
							setSelectedTab={setSelectedTab}
							setIsVisibilitySettingEdited={setIsVisibilitySettingEdited}
							sortOrder={sortOrder}
							sortingColumn={sortingColumn}
							sortUserList={sortUserList}
						/>
					</Box>
				}
				moreButton={false}
				handleDrawerClose={(event: any) => handleSettingDrawerAction(event, 'cancel')}
				handleSaveClick={(event: any) => handleSettingDrawerAction(event, 'save')}
				handleCancelClick={(event: any) => handleSettingDrawerAction(event, 'cancel')}
			/>
			{unSaveChangeModalProps && unSaveChangeModalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={unSaveChangeModalProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={unSaveChangeModalProps.open}
				/>
			)}
		</>
	);
};
