import React, { Fragment, useEffect, useState } from 'react';
import { Box, FormControlLabel, FormLabel, InputAdornment, Switch, TextField, Typography } from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { MultiSelect } from '../../CommonComponent/MultiSelect';
import { ErrorIcon, TimerIcon } from '../../../../config/svg/formElementIcons';
import { OKRButton } from '../../../Common/OKRButton';
import { CheckInsIcon } from '../../../../config/svg/MyGoalSvg';
import { NoSearchRecord } from '../../CommonComponent/NoSearchRecord';
import { EnumEmailModule, Enums } from '../../../../config/enums';
import LinearLoader from '../../../Common/Loader';
import { getUserDetails } from '../../../../config/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
	getFeedbackOneToOneSetting,
	saveFeedbackOneToOneSetting,
} from '../../../../action/adminPerformanceDevelopment';
import { EmailRecipientSetting } from '../AdminPerformanceDevelopment/Common/EmailRecipientSetting';

export const EngageSetting: React.FC<any> = (props: any) => {
	const { openEditOrgDrawer, handleClose, getCyclesData, cycleMasterData, parentOrganisation, getCheckinMasterData } =
		props;
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const meetingsNotesTakenBy: any[] = [
		{ id: 1, label: 'Initiator only' },
		{ id: 2, label: 'Both participants' },
	];
	const whoCanInitiateMeeting: any[] = [
		{ id: 1, label: 'Anyone' },
		{ id: 2, label: 'RM Only' },
	];
	const withWhomCanMeetingInitiated: any[] = [
		{ id: 1, label: 'Anyone' },
		{ id: 2, label: 'DR only' },
		{ id: 3, label: 'DR and downwards' },
	];
	const loggedInUserDetail = getUserDetails();
	const [showLoader, setShowLoader] = useState(false);
	const [checkinVisiblityList, setCheckinVisiblityList] = useState([]);
	const [selectedCheckinVisiblityOption, setSelectedCheckinVisiblityOption] = useState<any>({});
	const [checkinsVisible, setCheckinsVisible] = useState(false);
	const [organisationDetails, setOrganisationDetails] = useState<any>({});
	const [orgOriginalData, setOrgOriginalData] = useState<any>({});
	const [zeroNotAllowed, setZeroNotAllowed] = useState<boolean>(false);
	const [formEdited, setFormEdited] = useState<boolean>(false);
	const [isOneToOneAgendaRequired, setIsOneToOneAgendaRequired] = useState<boolean>(true);
	const [selectedMeetingNotesTakenByOption, setSelectedMeetingNotesTakenByOption] = useState<any>([
		{ ...meetingsNotesTakenBy[0] },
	]);
	const [selectedWhoCanInitiateMeetingOption, setSelectedWhoCanInitiateMeetingOption] = useState<any>([
		{ ...whoCanInitiateMeeting[0] },
	]);
	const [selectedWithWhomCanMeetingInitiatedOption, setSelectedWithWhomCanMeetingInitiatedOption] = useState<any>([
		{ ...withWhomCanMeetingInitiated[0] },
	]);
	const [meetingDefaultSettings, setMeetingDefaultSettings] = useState<any>({});
	const dispatch = useDispatch();
	const [cadenceDaysError, setCadenceDaysError] = useState<any>({
		error: '',
		helperText: '',
		type: '',
	});
	const { oneToOneSettings } = useSelector((state: any) => state?.adminPerformanceDevelopmentReducer);
	useEffect(() => {
		props.getParentOrganization();
		if (!oneToOneSettings) {
			dispatch(getFeedbackOneToOneSetting());
		}
	}, []);

	useEffect(() => {
		if (oneToOneSettings) {
			setOneToOneFormData(oneToOneSettings);
			setMeetingDefaultSettings(oneToOneSettings);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [oneToOneSettings]);

	useEffect(() => {
		if (!cycleMasterData.length || !props.checkinMasterData.length) {
			getCheckinMasterData();
			setShowLoader(false);
		} else {
			setShowLoader(false);
		}
	}, []);

	useEffect(() => {
		if (parentOrganisation.teamId && props.checkinMasterData.length) {
			let data = props.checkinMasterData.map((data: any) => {
				return {
					...data,
					label: data.checkInVisibilityName,
					value: data.checkInVisibilityId,
				};
			});
			let selectedCheckinOption = data.filter(
				(item: any) => item.checkInVisibilityId === parentOrganisation.checkInVisibilty
			);
			setCheckinVisiblityList(data);
			setSelectedCheckinVisiblityOption(selectedCheckinOption[0]);
		}
	}, [props.checkinMasterData, parentOrganisation]);

	useEffect(() => {
		if (parentOrganisation && parentOrganisation.teamId) {
			setOrganisationDetails(parentOrganisation);
			setOrgOriginalData(parentOrganisation);
			setCheckinsVisible(parentOrganisation.isChangeCheckInVisibilty);
		}
	}, [parentOrganisation]);

	const onCheckinSettingChange = (value: any) => {
		setSelectedCheckinVisiblityOption(value);
		setOrganisationDetails({
			...organisationDetails,
			checkInVisibilty: value[0].value,
			isChangeCheckInVisibilty:
				organisationDetails.checkInVisibilty === value[0].value ? organisationDetails.isChangeCheckInVisibilty : false,
		});
		setFormEdited(true);
	};

	const handleOrganisationToggleChange = (e: any) => {
		const { name, checked } = e.target;
		let organisation = { ...organisationDetails };
		organisation[name] = checked;
		setOrganisationDetails(organisation);
		setFormEdited(true);
	};

	const handleOrganisationFieldsBlur = (e: any) => {
		const { name, value } = e.target;
		let organisation = { ...organisationDetails };
		if (!value) {
			organisation[name] = 0;
		}
		setOrganisationDetails(organisation);
		setFormEdited(true);
	};

	const handleOrganisationFieldsChange = (e: any) => {
		const { name, value } = e.target;
		if (e.target.value !== 0) {
			setZeroNotAllowed(false);
		}
		let organisation = { ...organisationDetails };
		organisation[name] = parseInt(value);
		setOrganisationDetails(organisation);
		setFormEdited(true);
	};

	const showApiMsgs = (msg: string, variant: any) => {
		enqueueSnackbar(msg, {
			variant: variant,
			autoHideDuration: 5000,
		});
	};

	const handleEngageSettingsSave = async () => {
		if (organisationDetails?.cadenceDays === 0) {
			setZeroNotAllowed(true);
			setCadenceDaysError({
				error: t('zeroNotAllowedText'),
				helperText: t('zeroNotAllowedText'),
				type: 'cadenceDays',
			});
			return false;
		}
		setShowLoader(true);

		let orgData = {
			teamId: organisationDetails.teamId,
			cadenceDays: organisationDetails.cadenceDays,
			checkInVisibilty: organisationDetails.checkInVisibilty,
			// isChangeCheckInVisibilty: organisationDetails?.isChangeCheckInVisibilty,
		};

		const response = await props.updateEngageSettings(orgData);
		if (response && response.data.status === 200) {
			setShowLoader(false);
			showApiMsgs(t('engageSettingsUpdatedSuccess'), 'success');
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages}`, 'error');
			setShowLoader(false);
		}
	};

	const handleEngageSettingReset = async () => {
		setOrganisationDetails(orgOriginalData);
	};

	const handleOneOnOneSettingsSave = async () => {
		setShowLoader(true);
		let orgData = {
			isOneToOneAgendaRequired: isOneToOneAgendaRequired,
			meetingNotesTakenBy: selectedMeetingNotesTakenByOption.length > 0 ? selectedMeetingNotesTakenByOption[0]?.id : 1,
			initiateMeetingPermission:
				selectedWhoCanInitiateMeetingOption.length > 0 ? selectedWhoCanInitiateMeetingOption[0]?.id : 1,
			whomMeetingCanBeInitiated:
				selectedWithWhomCanMeetingInitiatedOption.length > 0 ? selectedWithWhomCanMeetingInitiatedOption[0]?.id : 1,
		};

		const response: any = await dispatch(saveFeedbackOneToOneSetting(orgData));
		if (response && response.data.status === 200) {
			setShowLoader(false);
			setOneToOneFormData({ ...orgData });
			dispatch(getFeedbackOneToOneSetting());
			showApiMsgs(t('engageSettingsUpdatedSuccess'), 'success');
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			showApiMsgs(`${messages}`, 'error');
			setShowLoader(false);
		}
	};

	const setOneToOneFormData = (data: any) => {
		setIsOneToOneAgendaRequired(data?.isOneToOneAgendaRequired);
		setSelectedMeetingNotesTakenByOption(
			data?.meetingNotesTakenBy ? [{ ...meetingsNotesTakenBy[data?.meetingNotesTakenBy - 1] }] : []
		);
		setSelectedWhoCanInitiateMeetingOption(
			data?.initiateMeetingPermission ? [{ ...whoCanInitiateMeeting[data?.initiateMeetingPermission - 1] }] : []
		);
		setSelectedWithWhomCanMeetingInitiatedOption(
			data?.whomMeetingCanBeInitiated ? [{ ...withWhomCanMeetingInitiated[data?.whomMeetingCanBeInitiated - 1] }] : []
		);
	};
	const handleOneToOneSettingReset = async () => {
		setOneToOneFormData(meetingDefaultSettings);
	};
	return (
		<>
			{showLoader && (
				<Box className='loader-wrap'>
					<LinearLoader />
				</Box>
			)}
			<Box className='align-settings'>
				<Box className='admin-tabpanel-inner'>
					<Box className='admin-tabpanel-head'>
						<Box className='admin-tabpanel-head-left'>
							<Typography variant='h2'>{t('engageSettingsLabel')}</Typography>
						</Box>
						<Box className='admin-tabpanel-head-right'></Box>
					</Box>
					<Box className='engage-setting-card full-rectangular-card'>
						<Box className='so-setting-head'>
							<Box>
								<Typography variant='h4' className='font-weight-normal'>
									{t('checkInsLabel')}
								</Typography>
							</Box>
						</Box>
						<Box className='so-setting-card-row'>
							<Box className='so-setting-card-col'>
								<Box className='settings-fields'>
									<Box className='settings-fields-col'>
										<TextField
											id='cadencsNotification'
											className=''
											fullWidth
											label={
												<Fragment>
													<TimerIcon />
													{t('cadencsNotificationLabel')}
												</Fragment>
											}
											placeholder={t('cadencsNotificationPlaceholdar')}
											value={organisationDetails?.cadenceDays}
											name='cadenceDays'
											InputLabelProps={{
												shrink: true,
											}}
											type='number'
											InputProps={{
												endAdornment: (
													<InputAdornment position='end'>
														{
															<>
																{t('daysLabel')}
																<span className='days-short-text'>{t('max30Days')}</span>
															</>
														}
													</InputAdornment>
												),
											}}
											onChange={(e) => {
												let value = parseInt(e.target.value) || 0;
												value >= 0 &&
													value <= Enums.MAX_CADENCE_FOR_CHECKIN_NOTIFICATON &&
													handleOrganisationFieldsChange(e);
											}}
											onBlur={(e) => {
												handleOrganisationFieldsBlur(e);
											}}
											error={
												(cadenceDaysError?.type === 'cadenceDays' &&
													zeroNotAllowed === true &&
													cadenceDaysError?.error) ||
												''
											}
											helperText={
												(cadenceDaysError?.type === 'cadenceDays' &&
													zeroNotAllowed === true &&
													cadenceDaysError?.helperText && (
														<>
															<ErrorIcon />
															{cadenceDaysError?.helperText}
														</>
													)) ||
												''
											}
										/>
										<Typography variant='h6' className='cadencs-notes-txt'>
											{t('cadencsNotificationShortText')}
										</Typography>
									</Box>
									<Box className='settings-fields-col'>
										<FormLabel>
											<CheckInsIcon />
											{t('checkinVisibilitySetting')}
										</FormLabel>
										<MultiSelect
											key={'checkInVisibility'}
											id='checkInVisibility'
											selectedOptions={selectedCheckinVisiblityOption}
											optionsList={checkinVisiblityList}
											onSelectOption={(value: any) => {
												onCheckinSettingChange(value);
											}}
											customFilter={(option: any, searchText: any) => {
												if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
													return true;
												}
											}}
											placeHolder={t('timeFramePlaceholdar')}
											noOptionsMessage={<NoSearchRecord />}
											fetchAsyncData={false}
											isSingleSelection={false}
											performSearch={() => {}}
											closeMenuOnSelect={true}
											labelTemplate={'onlyLabel'}
											isMulti={false}
											selectClassName={`dropdown-default-wrapper checkin-visibility-dropdown`}
											selectClassNamePrefix={'dropdown-default'}
											disableSearch={true}
										/>
									</Box>
								</Box>
								{/* <Box className='engage-setting-switch'>
									<FormControlLabel
										control={
											<Switch
												id='isChangeCheckInVisibilty'
												name='isChangeCheckInVisibilty'
												checked={Boolean(organisationDetails?.isChangeCheckInVisibilty)}
												onChange={handleOrganisationToggleChange}
												inputProps={{ 'aria-label': 'controlled' }}
												disabled={organisationDetails.checkInVisibilty === 1}
											/>
										}
										label={t('checkinsVisibilitySwitch')}
										labelPlacement='end'
									/>
								</Box> */}
							</Box>
						</Box>
					</Box>
					<Box className='so-setting-card-actions'>
						<Box className='so-setting-card-actions-left'></Box>
						<Box className='so-setting-card-actions-right'>
							<OKRButton className='btn-link' handleClick={handleEngageSettingReset} text={t('cancelBtn')} />
							<OKRButton className='btn-primary' handleClick={handleEngageSettingsSave} text={t('saveBtn')} />
						</Box>
					</Box>
					<Box className='engage-setting-card full-rectangular-card meeting-setting-card'>
						<Box className='so-setting-head'>
							<Box>
								<Typography variant='h4' className='font-weight-normal'>
									{t('meetingsLabel')}
								</Typography>
							</Box>
						</Box>
						<Box className='so-setting-card-row'>
							<Box className='so-setting-card-col'>
								<Box className='settings-fields'>
									<Box className='settings-fields-col'>
										<Box className='engage-setting-switch'>
											<FormControlLabel
												control={
													<Switch
														id='isChangeCheckInVisibilty'
														name='isChangeCheckInVisibilty'
														checked={Boolean(isOneToOneAgendaRequired)}
														onChange={(e: any) => {
															const { checked } = e.target;
															setIsOneToOneAgendaRequired(checked);
														}}
														inputProps={{ 'aria-label': 'controlled' }}
													/>
												}
												label={t('agendaMandatory')}
												labelPlacement='end'
											/>
										</Box>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box className='so-setting-card-row'>
							<Box className='so-setting-card-col engage-setting-card-col-3'>
								<Box className='settings-fields'>
									<Box className='settings-fields-col'>
										<FormLabel>{t('whoCanInitiate')}</FormLabel>
										<MultiSelect
											key={'checkInVisibility'}
											id='checkInVisibility'
											selectedOptions={selectedWhoCanInitiateMeetingOption}
											optionsList={whoCanInitiateMeeting}
											onSelectOption={(value: any) => {
												setSelectedWhoCanInitiateMeetingOption(value);
											}}
											customFilter={(option: any, searchText: any) => {
												if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
													return true;
												}
											}}
											placeHolder={t('timeFramePlaceholdar')}
											noOptionsMessage={<NoSearchRecord />}
											fetchAsyncData={false}
											isSingleSelection={false}
											performSearch={() => {}}
											closeMenuOnSelect={true}
											labelTemplate={'onlyLabel'}
											isMulti={false}
											selectClassName={`dropdown-default-wrapper checkin-visibility-dropdown`}
											selectClassNamePrefix={'dropdown-default'}
											disableSearch={true}
										/>
									</Box>
									<Box className='settings-fields-col'>
										<FormLabel>{t('meetingIntiated')}</FormLabel>
										<MultiSelect
											key={'checkInVisibility'}
											id='checkInVisibility'
											selectedOptions={selectedWithWhomCanMeetingInitiatedOption}
											optionsList={withWhomCanMeetingInitiated}
											onSelectOption={(value: any) => {
												setSelectedWithWhomCanMeetingInitiatedOption(value);
											}}
											customFilter={(option: any, searchText: any) => {
												if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
													return true;
												}
											}}
											placeHolder={t('timeFramePlaceholdar')}
											noOptionsMessage={<NoSearchRecord />}
											fetchAsyncData={false}
											isSingleSelection={false}
											performSearch={() => {}}
											closeMenuOnSelect={true}
											labelTemplate={'onlyLabel'}
											isMulti={false}
											selectClassName={`dropdown-default-wrapper checkin-visibility-dropdown`}
											selectClassNamePrefix={'dropdown-default'}
											disableSearch={true}
										/>
									</Box>
									<Box className='settings-fields-col'>
										<FormLabel>{t('meetingsNotesToBeTaken')}</FormLabel>
										<MultiSelect
											key={'checkInVisibility'}
											id='checkInVisibility'
											selectedOptions={selectedMeetingNotesTakenByOption}
											optionsList={meetingsNotesTakenBy}
											onSelectOption={(value: any) => {
												setSelectedMeetingNotesTakenByOption(value);
											}}
											customFilter={(option: any, searchText: any) => {
												if (option.label.toLowerCase().includes(searchText.toLowerCase())) {
													return true;
												}
											}}
											placeHolder={t('timeFramePlaceholdar')}
											noOptionsMessage={<NoSearchRecord />}
											fetchAsyncData={false}
											isSingleSelection={false}
											performSearch={() => {}}
											closeMenuOnSelect={true}
											labelTemplate={'onlyLabel'}
											isMulti={false}
											selectClassName={`dropdown-default-wrapper checkin-visibility-dropdown`}
											selectClassNamePrefix={'dropdown-default'}
											disableSearch={true}
										/>
									</Box>
								</Box>
							</Box>
						</Box>
						<Box className='so-setting-card-actions'>
							<Box className='so-setting-card-actions-left'></Box>
							<Box className='so-setting-card-actions-right'>
								<OKRButton className='btn-link' handleClick={handleOneToOneSettingReset} text={t('cancelBtn')} />
								<OKRButton className='btn-primary' handleClick={handleOneOnOneSettingsSave} text={t('saveBtn')} />
							</Box>
						</Box>
						<Box className='so-setting-card-separator'></Box>
						<Box className='engage-send-email-panel'>
							<EmailRecipientSetting
								t={t}
								loader={showLoader}
								messageNote={t('sendUpdateEmailsToNote1on1')}
								emailModuleId={EnumEmailModule.ONEonONE}
								setLoader={setShowLoader}
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</>
	);
};
