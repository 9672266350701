import { Avatar, Box, Chip, ClickAwayListener, Drawer, List, ListItem, Typography, Badge } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrgChartIcon, SwitchUserIcon, UserMgtIcon, UserProfileIcon } from '../../config/svg/CommonSvg';
import { LogoutIcon } from '../../config/svg/ProfileSvg';
import { getUserName, getUserDetails, checkSupportPermission } from '../../config/utils';
import GlobalSearch from './GlobalSearch';
import LinearLoader from './Loader';
import { useSelector } from 'react-redux';
import { showOnCreatePopup } from '../../action/common';
import { FaqIcon, GlossaryIcon, HayHandIcon, OKRExamIcon, SupportIcon } from '../../config/svg/CommonSvg';
import { OKRButton } from '../Common/OKRButton';
import { OpinionIcon, WhatsNewIcon } from '../../config/svg/DrawerSvg';
import {
	clearLocalStorage,
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from '../../services/StorageService';
import { adminTabPermission, checkPermission } from '../../config/rolePermission';
import {
	impersonateUser,
	supportModuleAlignOkr,
	viewOrganizationManagementPage,
	viewRoleManagement,
	viewUserManagementPage,
} from '../Admin/AdminConstant';
import { useSnackbar } from 'notistack';
import { ADMIN, DASHBOARD, HOME, LOGOUT_INITIATOR_URL } from '../../config/app-url';
import { useHistory } from 'react-router-dom';
import { UnlockOkrIcon } from '../../config/svg/DrawerSvg';
import Logo from '../../images/okr-logo.svg';
import { RoleIcon } from '../../config/svg/formElementIcons';
import { SettingIcon } from '../../config/svg/CommonSvgIcon';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { currentOrgTabs, currentSettingTabs } from '../../action/common';
import { currentDashboardTabForSO } from '../../action/common';
import LeftBottomMenu from '../Header/LeftBottomMenu';
import { updateGetUserVersion } from '../../action/common';

export const ProfileInfoDrawer: React.FC<any> = (props) => {
	const { open, handleClose, setProfileOpen, getPermissionResult } = props;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loader, setLoader] = useState(false);
	const [showGlobalSearch, setShowGlobalSearch] = useState<boolean>(false);
	const userDetail = getUserDetails();
	const { enqueueSnackbar } = useSnackbar();
	const history = useHistory();
	const auth = useAuth();
	const { okrCreationInProgress } = useSelector((state: any) => state.commonReducer);
	const { userVersion } = useSelector((state: any) => state?.commonReducer);
	useEffect(() => {
		if (!open) {
			setShowGlobalSearch(false);
			setLoader(false);
		}
	}, [open]);

	const logoutUser = () => {
		const isSSO = getLocalStorageItem('isSSO');
		const idpId = getLocalStorageItem('idpId');
		if (isSSO === 'false') {
			window.location.href = window.location.origin + LOGOUT_INITIATOR_URL;
		} else if (idpId === '3') {
			//Google Login
			clearLocalStorage();
			sessionStorage.clear();
			window.open('https://accounts.google.com/logout', '', 'width=100,height=100');
			window.location.href = window.location.origin + LOGOUT_INITIATOR_URL;
		} else {
			auth.removeUser();
			auth.signoutRedirect();
		}
		clearLocalStorage();
		sessionStorage.clear();
	};

	const onSelectedUser = (selectedUser: any) => {
		props.showLoader(true);
		handleImpersonateCall(selectedUser[0]);
	};

	const handleImpersonateCall = async (user: any) => {
		let data = {
			isImpersonate: true,
			impersonateUser: user.emailId,
			impersonatedByUser: userDetail.emailId,
			impersonatedById: userDetail.employeeId,
			impersonatedByUserName: `${userDetail?.firstName} ${userDetail?.lastName}`,
		};
		setLocalStorageItem('LoginAs', 'user');
		let response = await props.impersonateUser(data);
		if (response.data.status === 200) {
			props.showLoader(false);
			setLocalStorageItem('userDetail', JSON.stringify(response.data.entity));
			setLocalStorageItem('currentRoute', DASHBOARD);
			removeLocalStorageItem('currentUser');
			removeLocalStorageItem('globalBackUrl');
			props.getEmployeePermission(undefined, history);
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(0);
			props.addAlignmentFilter([]);
			props.resetOkr();
			history.push(DASHBOARD);
			props.updateRoute(DASHBOARD);
			handleClose();
			props.isImpersonated({
				redirection: true,
				tab: 0,
				userDetails: response.data.entity,
			});
			props.updateImpersonateToastStatus(false);
		} else {
			const responseAPI = response.data.messageList;
			const keys = Object.keys(responseAPI);
			const messages = keys.map((item) => responseAPI[item]);
			enqueueSnackbar(`${messages} `, {
				variant: 'error',
				autoHideDuration: 5000,
			});
		}
	};

	const handleSwitchBack = async (e: any) => {
		let data: any = {
			isImpersonate: false,
			impersonateUser: userDetail?.impersonatedBy,
			impersonatedByUser: userDetail?.impersonatedBy,
			impersonatedById: userDetail?.impersonatedById,
			impersonatedByUserName: userDetail.impersonatedByUserName,
		};
		props.showLoader(true);
		let response = await props.impersonateUser(data);
		if (response.data && response.data.status === 200) {
			props.showLoader(false);
			setLocalStorageItem('userDetail', JSON.stringify(response.data.entity));
			removeLocalStorageItem('currentUser');
			removeLocalStorageItem('globalBackUrl');
			props.getEmployeePermission(undefined, history);
			handleClose();
			props.resetOkr();
			props.addAlignmentFilter([]);
			setLocalStorageItem('currentRoute', DASHBOARD);
			dispatch(currentDashboardTabForSO(false));
			props.currentDashboardTab(0);
			history.push(DASHBOARD);
			props.updateRoute(DASHBOARD);
			props.switchUser(e, 'user', DASHBOARD);
			props.isImpersonated({
				redirection: true,
				tab: 0,
				userDetails: response.data.entity,
			});
		}
	};

	const handleClickAway = () => {
		setShowGlobalSearch(false);
	};

	let employeePermissions = [];
	if (
		props.getPermissionStatus === 'success' &&
		props.getPermissionResult &&
		props.getPermissionResult.employeePermissions
	) {
		employeePermissions = props.getPermissionResult.employeePermissions;
	}

	const handleAdminMenu = (e: any, tabToOpen: number) => {
		dispatch(currentDashboardTabForSO(false));
		props.currentDashboardTab(tabToOpen);
		props.updateRoute('/admin');
		props.switchUser(e, 'admin', ADMIN);
		handleClose(e);
	};

	const handleActiveAdminClass = (tab: any) => {
		if (props.currentRoute === ADMIN) {
			if (props.dashboardCurrentTab === tab) {
				return 'active';
			} else {
				return '';
			}
		} else {
			return '';
		}
	};

	const checkEditOkr = (event: any) => {
		if (okrCreationInProgress) {
			event.preventDefault();
			event.stopPropagation();
			dispatch(showOnCreatePopup({ open: true, type: 'tab', data: {}, event: { ...event } }));
			return false;
		}
		return true;
	};

	const handleWhatsNewOnClick = () => {
		const showTooltip = false;
		const requestParam = `versionId=${userVersion.versionId}&isRead=${showTooltip}`;
		dispatch(updateGetUserVersion(requestParam, showTooltip));
		window.open(process.env.REACT_APP_WHATS_NEW, '_blank');
	};

	return (
		<Box className={`profile-sub-panel ${!adminTabPermission(employeePermissions) ? 'no-admin-settings' : ''}`}>
			<Box className='profile-inner-wrapper'>
				{loader && <LinearLoader />}

				<Box className='user-info user-info-account'>
					<Box className='user-img'>
						<Avatar
							id='profile-avatar'
							src={userDetail.imagePath ? userDetail.imagePath : ''}
							alt={`${userDetail.firstName} ${userDetail.lastName}`}
						>
							{userDetail.imagePath
								? ''
								: getUserName({
										firstName: userDetail?.firstName,
										lastName: userDetail?.lastName,
										fullName: null,
								  })}
						</Avatar>
					</Box>
					<Box className='user-info-details'>
						<Typography variant='body1'>
							{userDetail?.firstName} {userDetail?.lastName}
						</Typography>
						{/* {userDetail?.designation && <Typography variant='h6'>{userDetail?.designation}</Typography>} */}
						{/* <Box className='chip-wrapper'>
							<Chip label={`${userDetail?.roleName}`} />
						</Box> */}
					</Box>
				</Box>

				<Box className='user-settings'>
					<Box className='user-account-setting'>
						<Typography variant='h6' className='account-head'>
							{t('accountSettings')}
						</Typography>
						<List>
							{checkPermission(getPermissionResult?.employeePermissions, impersonateUser) &&
							!userDetail?.isImpersonatedUser ? (
								<>
									<ClickAwayListener onClickAway={handleClickAway}>
										<ListItem
											onClick={(e) => setShowGlobalSearch(!showGlobalSearch)}
											className={showGlobalSearch ? 'activeMenu' : ''}
										>
											<Box className='link-txt'>
												<SwitchUserIcon />
												<Typography>{t('switchUser')}</Typography>
											</Box>
											<Box
												onClick={(e) => {
													e.preventDefault();
													e.stopPropagation();
												}}
											>
												{showGlobalSearch && (
													<GlobalSearch
														{...props}
														module={'profile'}
														customClass={'user-quick-search-type2'}
														placeHolderText={t('searchText')}
														customId={'profile-user-src'}
														onSelectedUser={onSelectedUser}
														fetchCycleUsers={true}
													/>
												)}
											</Box>
										</ListItem>
									</ClickAwayListener>
								</>
							) : (
								<></>
							)}
							{userDetail?.isImpersonatedUser && (
								<ListItem onClick={(e) => handleSwitchBack(e)}>
									<Box className='link-txt'>
										<SwitchUserIcon />
										<Typography>{t('switchBack')}</Typography>
									</Box>
								</ListItem>
							)}
							<ListItem
								onClick={(e) => {
									handleClose(e);
									setProfileOpen(true);
								}}
							>
								<Box className='link-txt'>
									<UserProfileIcon />
									<Typography>{t('myProfile')}</Typography>
								</Box>
							</ListItem>

							{/* <ListItem className='extra_menu'>
								<Box>
									<LeftBottomMenu {...props} />
								</Box>
							</ListItem> */}

							{!userDetail?.isImpersonatedUser && (
								<ListItem onClick={logoutUser}>
									<Box className='link-txt'>
										<LogoutIcon />
										<Typography>{t('logout')}</Typography>
									</Box>
								</ListItem>
							)}
						</List>
					</Box>
					{adminTabPermission(employeePermissions) ? (
						<Box className='user-account-setting admin-settings'>
							<Typography variant='h6' className='account-head'>
								{t('adminSettings')}
							</Typography>
							<List>
								{checkPermission(getPermissionResult?.employeePermissions, viewUserManagementPage) ? (
									<ListItem className={handleActiveAdminClass(0)} onClick={(e) => handleAdminMenu(e, 0)}>
										<Box className='link-txt'>
											<UserMgtIcon />
											<Typography>{t('manageUserLabel')}</Typography>
										</Box>
									</ListItem>
								) : (
									<></>
								)}
								{checkPermission(getPermissionResult?.employeePermissions, viewRoleManagement) ? (
									<ListItem className={handleActiveAdminClass(1)} onClick={(e) => handleAdminMenu(e, 1)}>
										<Box className='link-txt'>
											<RoleIcon />
											<Typography>{t('role&PermissionLabel')}</Typography>
										</Box>
									</ListItem>
								) : (
									<></>
								)}
								{checkPermission(getPermissionResult?.employeePermissions, viewOrganizationManagementPage) ? (
									<ListItem
										className={handleActiveAdminClass(2)}
										onClick={(e) => {
											handleAdminMenu(e, 2);
											dispatch(currentOrgTabs(0));
										}}
									>
										<Box className='link-txt'>
											<OrgChartIcon />
											<Typography>{t('organizationLabel')}</Typography>
										</Box>
									</ListItem>
								) : (
									<></>
								)}
								<ListItem
									className={handleActiveAdminClass(3)}
									onClick={(e) => {
										handleAdminMenu(e, 3);
										if (checkSupportPermission(supportModuleAlignOkr)) {
											dispatch(currentSettingTabs('strategicObj'));
										} else {
											dispatch(currentSettingTabs('alignGoals'));
										}
									}}
								>
									<Box className='link-txt'>
										<SettingIcon />
										<Typography>{t('settingsLabel')}</Typography>
									</Box>
								</ListItem>
							</List>
						</Box>
					) : (
						<></>
					)}

					{/* Help & Support Column */}
					<Box className='user-account-setting help-support-col'>
						<Typography variant='h6' className='account-head'>
							Help & Support
						</Typography>
						<List>
							<ListItem id='faq-link'>
								<a href='https://www.unlocku.com/faqs/' target='_blank' rel='noreferrer noopener'>
									<Box className='link-txt'>
										<FaqIcon />
										<Typography>{t('productFaqLabel')}</Typography>
									</Box>
								</a>
							</ListItem>

							<ListItem
								id='whats-new'
								onClick={(e: any) => {
									handleWhatsNewOnClick();
								}}
							>
								<Box className='link-txt'>
									{/* <a rel='noreferrer' target='_blank' href={process.env.REACT_APP_WHATS_NEW}> */}
									<WhatsNewIcon />
									<Typography>
										{userVersion?.isRead ? (
											<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
										) : (
											<></>
										)}
										{/* <Tooltip
												placement='bottom'
												arrow
												classes={{ popper: 'custom-tooltip-icon' }}
												title={
													<Box className='tooltip-text'>
														<HayHandIcon />
														<Typography dangerouslySetInnerHTML={{ __html: t('whatsNewTooltip') }}></Typography>
													</Box>
												}
											>
												<Badge variant='dot' className='dot-bubble' overlap='circular'></Badge>
											</Tooltip> */}
										{t('whatsNew')}
									</Typography>
									{/* </a> */}
								</Box>
							</ListItem>
						</List>
					</Box>

					{/* End of Help & Support Column */}
				</Box>
				<Box className='okr-links about-link-inner'>
					<Box className='about-links'>
						<List>
							<ListItem>
								{/* <Box className='link-txt'>
									<OKRButton
										id='improvements-link'
										icon={<OpinionIcon />}
										title={t('productFeedback')}
										tooltipPlacement={'right'}
										handleClick={(e) => openJiraIssueCollector(e)}
									/>
									<Typography>{t('productFeedback')}</Typography>
								</Box> */}
								<LeftBottomMenu {...props} checkEditOkr={checkEditOkr} />
							</ListItem>
							<ListItem>
								<Box className='link-txt' onClick={(e) => props.openAboutUs(e, true)}>
									<UnlockOkrIcon />
									<Typography>{t('aboutUnlockOKR')}</Typography>
								</Box>
							</ListItem>
						</List>
					</Box>
					<List>
						<ListItem>
							<div className='logo-img'>
								<img src={Logo} alt={t('HeaderDrawerListLogo')} />
							</div>
						</ListItem>
					</List>
				</Box>
			</Box>
		</Box>
	);
};
