import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorIcon } from '../../../config/svg/formElementIcons';
import { getUserDetails } from '../../../config/utils';
import { MultiSelect } from '../../Admin/CommonComponent/MultiSelect';
import { NoSearchRecord } from '../../Admin/CommonComponent/NoSearchRecord';
import { useDispatch } from 'react-redux';
import { recognitionSearchWithTeam } from '../../../action/search';

export const EmployeeSearch = (props: any) => {
	const { formErrors, onInputFocus, customSearch = false, searchCustomEmployee } = props;
	const { t } = useTranslation();
	const [reportingToOptions, setReportingToOptions] = useState<any>([]);
	const dispatch = useDispatch();

	const [emptyRecords, setEmptyRecords] = useState<any>({
		reportingTo: false,
		team: false,
		roleId: false,
	});

	let userDetail = getUserDetails();

	const getTeamData = (data: any) => {
		console.log('getTeamData');
		return dispatch(recognitionSearchWithTeam(data));
	};
	const searchReportingTo = async (value: string) => {
		if (value.length >= 3) {
			const resp = await props.searchCustomEmployee(value, 1, 6);
			let list = resp.data.entityList;
			list = list && list.filter((item: any) => item.employeeId !== userDetail.employeeId);
			if (list && list.length > 0) {
				setReportingToOptions(
					list.map((userData: any) => {
						return {
							...userData,
							label: userData.searchType === 2 ? userData.teamName : userData.firstName + ' ' + userData.lastName,
							value: userData.searchType === 2 ? userData.teamId : userData.employeeId,
							secondaryText: userData.searchType === 2 ? 'Leader Name: ' + userData.teamHeadName : userData.emailId,
							imagePath: userData.searchType === 2 ? userData.logoImagePath : userData.imagePath,
							emailId: userData.emailId,
							classList: userData.searchType === 2 ? 'user-team-chip' : '',
						};
					})
				);
			} else {
				setEmptyRecords({ ...emptyRecords, reportingTo: true });
				setReportingToOptions([]);
			}
		}
	};
	return (
		<>
			<MultiSelect
				key={'reportingTo'}
				id='recognize-to-dropdown'
				selectedOptions={props.selectedUser}
				optionsList={reportingToOptions}
				onSelectOption={(value: any) => {
					props.setSelectedUser(value);
					setReportingToOptions([]);
					setEmptyRecords({ ...emptyRecords, reportingTo: false });
				}}
				blurCalled={(value: any) => {
					setReportingToOptions([]);
				}}
				setEmptyRecords={setEmptyRecords}
				blurInputOnSelect={true}
				isMulti={true}
				customFilter={() => {}}
				setOptionsList={setReportingToOptions}
				isLoadMore={true}
				placeHolder={t('recognizePostPlaceholder')}
				noOptionsMessage={<NoSearchRecord />}
				showNoOptions={emptyRecords.reportingTo === true}
				fetchAsyncData={true}
				isSingleSelection={false}
				closeMenuOnSelect={true}
				labelTemplate={'avatarLabel'}
				selectClassName={`select-search-dropdown select-search-icon ${
					props.selectedUser.length === 0 && props.focussed ? 'error' : ''
				}`}
				selectClassNamePrefix={'react-select'}
				error={(formErrors['receiver'] && formErrors['receiver']) || ''}
				helperText={
					formErrors['receiver'] &&
					formErrors['receiver'] && (
						<>
							<ErrorIcon />
							{(formErrors['receiver'] && formErrors['receiver']) || ''}
						</>
					)
				}
				onInputFocus={onInputFocus}
				disabled={props.disabled}
				performSearch={customSearch ? searchCustomEmployee : getTeamData}
				hideLoggedIn={true}
				loggedInUserIds={[userDetail.employeeId]}
			/>
		</>
	);
};
