import React, { Fragment, useEffect, useState } from 'react';
import { Box, ClickAwayListener, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Enums } from '../../../config/enums';
import { HOME } from '../../../config/app-url';
import { UserSnapshot } from '../../Common/UserSnapshot';
import { OKRButton } from '../../Common/OKRButton';
import { BottomArrowIcon } from '../../../config/svg/CommonSvgIcon';
import WelcomeImage from '../../../images/decline.svg';
import '../../../styles/pages/people-progress/people-progress.scss';
import '../../../styles/pages/admin/bulkUserEdit.scss';
import { PeopleProgressCard } from './PeopleProgressCard';
import { useDispatch, useSelector } from 'react-redux';
import {
	getDrApiDetails,
	getOkrMasterData,
	setPeoplefiltersList,
	setPeopleSelectedOption,
} from '../../../action/myGoal';
import { TaskSearch } from '../../GlobalTask/TaskList/TaskSearch';
import { ProgressSorting } from './ProgressSorting';
import { TeamProgressDrawer } from './TeamProgressDrawer';
import { AnimatedIcon } from '../../Common/AnimatedIcon';

export const PeopleProgress: React.FC<any> = (props) => {
	const { userDetail, cycleId, t, pastCycles } = props;
	const [drUserData, setDrUserData] = useState<any>([]);
	const [loadData, setLoadData] = useState<any>([]);

	const [filterData, setFilterData] = useState<any>({ pageIndex: 1, pageSize: 99999, totalPages: 1 });
	const [pageIndex, setPageIndex] = useState(1);
	const [searchText, setSearchText] = useState<string>('');

	const [showDrawer, setShowDrawer] = useState<boolean>(false);
	const [openSnapshot, setOpenSnapshot] = useState(false);
	const [selectedUser, setSelectedUser] = useState<any>(null);
	const [open, setOpen] = React.useState<Boolean>(false);
	const [selectedOption, setSelectedOption] = useState(0);
	const [openPeopleProgress, setOpenPeopleProgress] = useState<Boolean>(false);
	const [teamToFilter, setTeamToFilter] = useState<any[]>([]);
	const peopleProgressType: any = { 0: t('myDirectReports'), 1: t('otherTeams') };
	const dispatch = useDispatch();
	const [directReportResultStatus, setDirectReportResultStatus] = React.useState<Boolean>(false);
	const [searchTextArray, setSearchTextArray] = useState<boolean>(false);
	const staticFilterObj = {
		sortingText: 'aToZ',
		order: '',
	};
	const { okrMasterData, peopleProgressFilters, peopleSelectedOption } = useSelector((state: any) => state?.okrReducer);
	const [showLoadMore, setShowLoadMore] = useState(false);
	const [listLoading, setListLoading] = useState(false);
	const [filterDetails, setFilterDetails] = useState<any>(staticFilterObj);
	const [selectedUsers, setSelectedUsers] = useState<any>({});
	useEffect(() => {
		setShowLoadMore(false);
		dispatch(getOkrMasterData());
	}, []);
	useEffect(() => {
		setSelectedOption(peopleSelectedOption);
	}, [peopleSelectedOption]);
	/*useEffect(() => {
	}, [searchText, pageIndex, filterDetails, teamToFilter, props.userSelectedCycle?.organisationCycleId]);*/
	useEffect(() => {
		getDrDetails();
	}, [peopleProgressFilters, props.userSelectedCycle?.organisationCycleId]);

	const getDrDetails = async () => {
		try {
			//setLoader(true);
			setListLoading(true);
			let requestParam: any = {
				cycleId: props.userSelectedCycle?.organisationCycleId || cycleId,
				employeeId: userDetail.employeeId,
				pageIndex: peopleProgressFilters.pageIndex,
				pageSize: 99999,
				sortingType: peopleProgressFilters.filterDetails.id || 5,
				finder: peopleProgressFilters.searchText ? [peopleProgressFilters.searchText] : [],
				teamToFilter: peopleProgressFilters.teamToFilter,
			};
			const response: any = await dispatch(getDrApiDetails(requestParam));
			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				setDirectReportResultStatus(true);
				if (pageIndex !== 1) {
					let currentRecords = [...drUserData, ...response?.data.entity.teamAndDrEmployees];
					setDrUserData(currentRecords);
				} else {
					setDrUserData(response?.data.entity.teamAndDrEmployees);
				}

				/*dispatch(
					setPeoplefiltersList({
						...peopleProgressFilters,
						filterData: {
							pageIndex: response?.data.entity.pageIndex,
							pageSize: response?.data.entity.pageSize,
							totalPages: response?.data.entity.totalPages,
							totalRecords: response?.data.entity.totalRecords,
						},
					})
				);*/
				setFilterData({
					pageIndex: response?.data.entity.pageIndex,
					pageSize: response?.data.entity.pageSize,
					totalPages: response?.data.entity.totalPages,
					totalRecords: response?.data.entity.totalRecords,
				});
				if (pageIndex < response?.data?.entity.totalPages) {
					setShowLoadMore(true);
				} else {
					setShowLoadMore(false);
				}
				setListLoading(false);
				//setLoader(false);
			} else {
				setDirectReportResultStatus(false);
				setListLoading(false);
				//setLoader(false);
			}
		} catch (error) {}
	};

	const handleGiveFeedBack = () => {
		setShowDrawer(!showDrawer);
	};

	const handleOnClickPerformanceSnapshot = (e: any, data: any) => {
		setSelectedUser(data);
		setOpenSnapshot(true);
	};
	const handleDrawerClose = (e: any, type: any) => {
		setOpenSnapshot(false);
		setSelectedUser(null);
	};
	const handleClick = () => {
		setOpen((prev: Boolean) => !prev);
	};
	const handleClickAway = () => {
		setOpen(false);
	};
	const handleOptionClick = (option: any) => {
		setSelectedOption(option);
		dispatch(setPeopleSelectedOption(option));
		if (option === 0) {
			//setTeamToFilter([]);
			dispatch(setPeoplefiltersList({ ...peopleProgressFilters, teamToFilter: [] }));
		}
		setOpen(false);
	};
	const handleSearchText = (searchString = '') => {
		dispatch(setPeoplefiltersList({ ...peopleProgressFilters, searchText: searchString, pageIndex: 1 }));
	};
	const loadMore = () => {
		dispatch(setPeoplefiltersList({ ...peopleProgressFilters, pageIndex: peopleProgressFilters.pageIndex + 1 }));
	};
	const handleSortingFilterClick = (sortingText: string, order: string, id: any) => {
		/*setFilterDetails({
			sortingText: sortingText,
			order: order,
			id: id,
		});*/
		dispatch(
			setPeoplefiltersList({
				...peopleProgressFilters,
				filterDetails: {
					sortingText: sortingText,
					order: order,
					id: id,
				},
			})
		);
	};
	const handlePeopleProgressClose = (event: any, type: any) => {
		if (type !== 'save') {
			handleOptionClick(0);
		}
		setOpenPeopleProgress(false);
	};
	return (
		<Fragment>
			<Box className='okr-tabpanel-actions people-progress-actions'>
				<>
					<Box className={`people-progress-type ${open ? 'active' : ''} `}>
						{/* <TabsFilter showAll={allSelected} filters={filters} handleFilterClick={handleFilterClick} /> */}
						<OKRButton
							className={'dropdown-button placeholder-button'}
							icon={<BottomArrowIcon />}
							handleClick={handleClick}
							text={
								<span className='dropdown-btn-txt'>
									{peopleProgressType[selectedOption]}{' '}
									{selectedOption === 0 && okrMasterData?.totalDirectReportees
										? `(${okrMasterData?.totalDirectReportees})`
										: ''}
								</span>
							}
						/>
						{open && (
							<ClickAwayListener onClickAway={handleClickAway}>
								<Box className='people-progress-dropdown-list'>
									<Box className='people-progress-dropdown-list-inner'>
										<List component='div' disablePadding>
											<ListItem
												component='div'
												onClick={(e) => {
													handleClickAway();
													handleOptionClick(0);
												}}
												className={`${selectedOption === 0 ? 'active' : ''} `}
											>
												<ListItemText>
													{`${peopleProgressType[Enums.ZERO]} ${
														selectedOption === 0 ? `(${filterData?.totalRecords})` : ''
													}`}
												</ListItemText>
											</ListItem>
											{/* {props?.userSelectedCycle?.cycleStatus !== Enums.PAST_CYCLE_ID && ( */}
											<ListItem
												component='div'
												onClick={(e) => {
													handleClickAway();
													handleOptionClick(1);
													setOpenPeopleProgress(true);
												}}
												className={`${selectedOption === 1 ? 'active' : ''} `}
											>
												<ListItemText>{peopleProgressType[Enums.ONE]}</ListItemText>
											</ListItem>
											{/* )} */}
										</List>
									</Box>
								</Box>
							</ClickAwayListener>
						)}
					</Box>
				</>
				{selectedOption === 1 && (
					<TeamProgressDrawer
						{...props}
						userDetail={userDetail}
						openPeopleProgress={openPeopleProgress}
						handlePeopleProgressClose={handlePeopleProgressClose}
						setTeamToFilter={(teamValue: any[]) => {
							//setTeamToFilter(teamValue);
							dispatch(setPeoplefiltersList({ ...peopleProgressFilters, teamToFilter: teamValue }));
						}}
						selectedUsers={selectedUsers}
						setSelectedUsers={setSelectedUsers}
					/>
				)}
				{directReportResultStatus && drUserData && drUserData.length > 0 ? (
					<Box className='pp-sort-search-area'>
						{' '}
						<ProgressSorting filterDetails={filterDetails} handleSortingFilterClick={handleSortingFilterClick} />
						<TaskSearch
							searchTextArray={searchTextArray}
							setSearchTextArray={setSearchTextArray}
							handleSearchText={handleSearchText}
						/>
					</Box>
				) : (
					<></>
				)}
			</Box>
			<Box className='people-progress-panel-area'>
				{/* Cards listing */}
				{listLoading ? (
					<Box className='loading-center-global'>
						<Typography>Loading...</Typography>
					</Box>
				) : directReportResultStatus && drUserData && drUserData.length > 0 ? (
					<Box className='people-progress-listing-area'>
						{drUserData.map((item: any, index: any) => (
							<>
								<PeopleProgressCard
									handleGiveFeedBack={handleGiveFeedBack}
									panelUsed={HOME}
									{...props}
									data={item}
									userDetail={userDetail}
									t={t}
									cardId={`card-${index}`}
									handleOnClickPerformanceSnapshot={(e: any) => handleOnClickPerformanceSnapshot(e, item)}
								/>
							</>
						))}
					</Box>
				) : (
					<Box className='welcome-content'>
						<Box textAlign='center'>
							{props?.userSelectedCycle?.cycleStatus !== Enums.PAST_CYCLE_ID ? (
								<>
									<Typography variant='h2'>{t('noDirectReportTitle')}</Typography>
									<Typography>{t('noDirectReportSubTitle')}</Typography>
								</>
							) : (
								<>
									<Typography variant='h2'>{t('pastNoDirectReportTitle')}</Typography>
									<Typography>{t('pastNoDirectReportSubTitle')}</Typography>
								</>
							)}

							<AnimatedIcon className='' width='516' type='image/svg+xml' alt='WelcomeImage' imagePath={WelcomeImage} />
						</Box>
					</Box>
				)}
				{showLoadMore && (
					<Box className='alignCenter' display={'flex'} justifyContent={'center'}>
						<OKRButton id='load-more' className='btn-link' handleClick={loadMore} text={'Load More...'}></OKRButton>
					</Box>
				)}
			</Box>
			{openSnapshot ? (
				<UserSnapshot openSnapshot={openSnapshot} selectedUser={selectedUser} handleDrawerClose={handleDrawerClose} />
			) : (
				<></>
			)}
		</Fragment>
	);
};
