import React, { Fragment, useEffect, useState } from 'react';
import { Box, Typography, Button, Tooltip, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SoListing } from './SOListing';
import { AddIconSvg, DeleteIcon, EditIcon } from '../../config/svg/CommonSvg';
import { MainDrawer } from '../Admin/CommonComponent/MainDrawer';
import '../../styles/pages/strategic-objective/strategic-objective.scss';
import { SoCreate } from './SOCreate';
import { BackArrow } from '../../config/svg/GlobalSvg';
import { MissionIcon, VisionIcon } from '../../config/svg/MyGoalSvg';
import AlertDialog from '../Common/Dialog';
import {
	getAllPerspective,
	getAllSoOkrList,
	searchObjectiveForSO,
	landingMissionVision,
	getAllSoTimeFrame,
	createSo,
	getSOLinkedOKRList,
	deleteSo,
	deleteSoTimeFrame,
	openSoCreateDrawer,
	getCycleDetailForSo,
	editSoTimeFrame,
} from '../../action/strategicObjective';
import { useDispatch, useSelector } from 'react-redux';
import { Enums } from '../../config/enums';
import {
	formatFeedbackDate,
	formatOneOnOneRequestDate,
	getCycleDefaultSelection,
	getMonthDateYear,
	getUserDetails,
} from '../../config/utils';
import { getLocalStorageItem } from '../../services/StorageService';
import { TimeFrameDropdown } from './TimeFrameDropdown';
import LinearLoader from '../Common/Loader';
import { useSnackbar } from 'notistack';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { SoOkrNotCreated } from './SOListing/SOOkrNotCreated';
import { DialogComponent } from '../Admin/CommonComponent/DialogComponent';
import { updateSo } from '../../action/strategicObjective';
import { currentDashboardTabForSONotificationRedirection } from '../../action/common';

export const StrategicObjectives: React.FC<any> = (props: any) => {
	const { handleBackButtonOnClick } = props;
	const moment = extendMoment(Moment);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { enqueueSnackbar } = useSnackbar();
	const { okrMasterData } = useSelector((state: any) => state?.okrReducer);
	const { isSoDrawerOpen, soPermissionDetails } = useSelector((state: any) => state.strategicObjectiveReducer);
	const { dashboardCurrentTabForSO, dashboardCurrentTabForSORedirectionSoDetailsId } = useSelector(
		(state: any) => state.commonReducer
	);

	const startDateValue = new Date();
	const userDetail: any = getUserDetails();
	const cycleId = getLocalStorageItem('cycleId') && parseInt(getLocalStorageItem('cycleId') || '0');
	const globalCycleDetail = getLocalStorageItem('cycleDetail') && JSON.parse(getLocalStorageItem('cycleDetail') || '');
	const currentCycleYear = getLocalStorageItem('year') || null;
	const cycleDuration = getLocalStorageItem('cycleDuration') || null;
	const [loader, setLoader] = useState<any>(false);

	startDateValue.setMonth(startDateValue.getMonth() - 60);
	const maxDateValue = new Date();
	maxDateValue.setMonth(maxDateValue.getMonth() + 240);
	const staticFormDetails = {
		timeFrameStartDate: '',
		timeFrameEndDate: '',
		strategicObjectiveName: '',
	};

	const [perspectiveList, setPerspectiveList] = useState<any>(null);
	const [showDrawerLoader, setShowDrawerLoader] = useState<boolean>(false);
	const [soDetailsDrawer, setSODetailsDrawer] = useState<any>({ open: false, type: '' });
	const [selectedDateRange, setSelectedDateRange] = useState<any>(null);
	const [selectedEditTimeFrameDateRange, setSelectedEditTimeFrameDateRange] = useState<any>(null);
	const [isEditTimeFrameCalenderVisible, setIsEditTimeFrameCalenderVisible] = useState<boolean>(false);
	const [soAttributeDetails, setSOAttributeDetails] = useState<any>({ ...staticFormDetails });
	const [modalProps, setModalProps] = useState<any>({ open: false, message: '', details: '', type: '' });
	const [deleteModalProps, setDeleteModalProps] = useState<any>({ open: false, message: '', details: '', type: '' });
	const [objectiveListMyTab, setObjectiveListMyTab] = useState<any>(null);
	const [objectiveListPeopleTab, setObjectiveListPeopleTab] = useState<any>(null);
	const [isDetailsEdited, setIsDetailsEdited] = useState<boolean>(false);
	const [editableSoLinkedDetails, setEditableSoLinkedDetails] = useState<any>(null);
	const [cycleDetail, setCycleDetail] = useState<any>(null);
	const [existingSoStatus, setExistingSoStatus] = useState<any>(0);
	// const [linkedKREdit, seLinkedKREdit] = useState<any>(null);

	const [soObjCreateTabSelected, setSoObjCreateTabSelected] = useState<Number>(0);
	const [searchSoTabSelected, setSearchSoTabSelected] = React.useState<Number>(0);
	const [missionVisionDetails, setMissionVisionDetails] = useState<any>(null);
	const [soOkrTimeframeList, setSoOkrTimeFrameList] = useState<any>(null);
	const [selectedTimeFrame, setSelectedTimeFrame] = useState<any>(null);
	const [showPastTimeFrameLink, setShowPastTimeFrameLink] = useState<boolean>(false);
	const [soList, setSoList] = useState<any>(null);
	const [soPermission, setSoPermission] = useState<any>(null);
	const [disableLinkOkr, setDisableLinkOkr] = useState<boolean>(false);
	const [totalSelectedCount, setTotalSelectedCount] = useState<any>(null);
	const [showDialog, setShowDialog] = useState<any>({
		open: false,
		message: '',
		isCancel: true,
		tagData: null,
		type: '',
	});

	useEffect(() => {
		if (isSoDrawerOpen) {
			handleDrawerOpen({});
			dispatch(openSoCreateDrawer(false));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSoDrawerOpen]);
	useEffect(() => {
		if (soPermissionDetails && soPermissionDetails?.isSoVisibilityRights) {
			getSoTimeFrameList();
		}
		if (soPermissionDetails && soPermissionDetails?.isMissionVissionAdded) {
			getLandingMissionVisionList();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	useEffect(() => {
		if (
			soPermissionDetails &&
			soPermissionDetails?.isSoVisibilityRights &&
			soAttributeDetails.timeFrameStartDate &&
			soAttributeDetails.timeFrameEndDate
		) {
			getCycleDetailForSoDetails();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [soAttributeDetails]);
	useEffect(() => {
		if (selectedTimeFrame) {
			getSoOkrList(0, 'direct');
			setIsEditTimeFrameCalenderVisible(true);
		} else {
			setSoList([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTimeFrame]);
	useEffect(() => {
		if (soObjCreateTabSelected === 1) {
			setShowDrawerLoader(true);
			let currentQuarterDetails: any = null;
			if (cycleDetail && cycleDetail.cycleDetails && cycleDetail.cycleDetails.length) {
				currentQuarterDetails = getCycleDefaultSelection(cycleDetail.cycleDetails, cycleId);
			}
			const requestPayload = {
				employeeId: userDetail?.employeeId || 0,
				soStartDate: soAttributeDetails.timeFrameStartDate
					? formatFeedbackDate(soAttributeDetails.timeFrameStartDate)
					: '',
				soEndDate: soAttributeDetails.timeFrameEndDate ? formatFeedbackDate(soAttributeDetails.timeFrameEndDate) : '',
				cycleId:
					currentQuarterDetails && currentQuarterDetails?.organisationCycleId
						? currentQuarterDetails?.organisationCycleId
						: cycleId || 0,
			};
			getObjectiveList(requestPayload, 'MyTab');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [soObjCreateTabSelected]);
	useEffect(() => {
		if (soDetailsDrawer && soDetailsDrawer?.type === 'Edit') {
			if (
				editableSoLinkedDetails &&
				editableSoLinkedDetails?.getSoOkr &&
				editableSoLinkedDetails?.linkedIndividualKrResponses
			) {
				const { getSoOkr, linkedIndividualKrResponses } = editableSoLinkedDetails;
				renderAlreadyLinkedObjectiveAndKR('direct', getSoOkr, linkedIndividualKrResponses);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [editableSoLinkedDetails]);
	useEffect(() => {
		if (
			(objectiveListMyTab && objectiveListMyTab.length) ||
			(objectiveListPeopleTab && objectiveListPeopleTab.length)
		) {
			const finalList =
				objectiveListMyTab && objectiveListMyTab.length && objectiveListPeopleTab && objectiveListPeopleTab.length
					? [...objectiveListMyTab, ...objectiveListPeopleTab]
					: objectiveListMyTab && objectiveListMyTab.length
					? [...objectiveListMyTab]
					: objectiveListPeopleTab && objectiveListPeopleTab.length
					? [...objectiveListPeopleTab]
					: [];
			checkAttributesOkrOrKr(finalList);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [objectiveListMyTab, objectiveListPeopleTab]);
	useEffect(() => {
		if (dashboardCurrentTabForSO && dashboardCurrentTabForSORedirectionSoDetailsId && selectedTimeFrame) {
			dispatch(currentDashboardTabForSONotificationRedirection(null));
			getNotificationRedirectionSoDetail();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dashboardCurrentTabForSORedirectionSoDetailsId, selectedTimeFrame]);

	const getNotificationRedirectionSoDetail = () => {
		const { soDetailsId } = dashboardCurrentTabForSORedirectionSoDetailsId || {};
		// const ssd = dashboardCurrentTabForSORedirectionSoDetailsId && dashboardCurrentTabForSORedirectionSoDetailsId?.timeFrameId ? {soTimeframeId : dashboardCurrentTabForSORedirectionSoDetailsId?.timeFrameId} : {}
		getSoOkrList(soDetailsId || 0, 'redirect');
	};
	const renderAlreadyLinkedObjectiveAndKR = (callType: string, objList: any, krList: any) => {
		try {
			let myLinkedObjList: any[] = [];
			let peopleLinkedObjList: any[] = [];

			if (objList && objList.length) {
				objList?.forEach((item: any) => {
					item.customDetails = { cycleId: item?.objectiveCycleId || 0, employeeId: item?.employeeId || 0 };
					item.isSelected = true;
					item.linkedKrResponses = item?.linkedKrResponses.map((rec: any) => {
						const data = { ...rec };
						data.isSelected = true;

						return data;
					});
					if (item.employeeId === userDetail?.employeeId) {
						myLinkedObjList.push(item);
					} else {
						peopleLinkedObjList.push(item);
					}
				});
			}
			if (krList && krList.length) {
				krList?.forEach((item: any) => {
					item.customDetails = { cycleId: item?.objectiveCycleId || 0, employeeId: item?.employeeId || 0 };
					item.linkedKrResponses = item?.linkedKrResponses.map((rec: any) => {
						const data = { ...rec };
						data.customDetails = { cycleId: data?.objectiveCycleId || 0, employeeId: data?.employeeId || 0 };

						return data;
					});
					if (item.employeeId === userDetail?.employeeId) {
						myLinkedObjList.push(item);
					} else {
						peopleLinkedObjList.push(item);
					}
				});
			}
			// seLinkedKREdit(krList && krList.length ? krList : []);
			setObjectiveListMyTab([...myLinkedObjList]);
			setObjectiveListPeopleTab([...peopleLinkedObjList]);
		} catch (error) {
			console.error(error);
		}
	};
	const getCycleDetailForSoDetails = async () => {
		try {
			const soStartDate = soAttributeDetails.timeFrameStartDate
				? formatFeedbackDate(soAttributeDetails.timeFrameStartDate)
				: '';
			const soEndDate = soAttributeDetails.timeFrameEndDate
				? formatFeedbackDate(soAttributeDetails.timeFrameEndDate)
				: '';
			if (soStartDate && soEndDate) {
				const requestParam = { timeFrameStartDate: soStartDate, timeFrameEndDate: soEndDate };
				const response: any = await dispatch(getCycleDetailForSo(requestParam));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data || {};
					if (entity && entity?.cycleDetails) {
						setCycleDetail(entity);
					} else {
						setCycleDetail(globalCycleDetail);
					}
				} else {
					setCycleDetail(globalCycleDetail);
				}
			}
		} catch (error) {
			console.error(error);
		}
	};
	const checkAttributesValidation = (type?: string | null) => {
		if (
			soAttributeDetails.timeFrameStartDate &&
			soAttributeDetails.timeFrameEndDate &&
			soAttributeDetails.strategicObjectiveName &&
			soAttributeDetails.strategicObjectiveName.trim()
		) {
			if (
				perspectiveList &&
				perspectiveList.length &&
				perspectiveList.find((item: any) => item?.perspectiveNameValue)
			) {
				// if (type === 'update') {
				// 	// const { okrList, krList } = getLinkedObjectiveAndKeyResultList(objectiveListMyTab, objectiveListPeopleTab);
				// 	// if ((okrList && okrList.length) || (krList && krList.length)) {
				// 	// 	return true;
				// 	// } else {
				// 	return false;
				// 	// }
				// } else {
				return false;
				// }
			} else {
				return true;
			}
		} else {
			return true;
		}
	};

	const checkAttributesOkrOrKr = (list: any) => {
		try {
			let selectedKRCount = 0;
			let selectedOKRCount = 0;
			if (list && list.length) {
				list
					?.filter((itm: any) => !itm.isSelected)
					.forEach((item: any) => {
						if (item?.linkedKrResponses && item?.linkedKrResponses.length) {
							const count = item?.linkedKrResponses?.filter((rec: any) => rec.isSelected);
							selectedKRCount = selectedKRCount + count?.length;
						}
					});
				selectedOKRCount = list?.filter((itm: any) => itm.isSelected)?.length;
			}
			setTotalSelectedCount(selectedOKRCount + selectedKRCount);
			selectedOKRCount || selectedKRCount ? setDisableLinkOkr(true) : setDisableLinkOkr(false);
		} catch (error) {
			console.error(error);
		}
	};

	const getLandingMissionVisionList = async () => {
		try {
			const response: any = await dispatch(landingMissionVision());
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response.data || {};
				setMissionVisionDetails(entity);
			} else {
				setMissionVisionDetails([]);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const getAllPerspectiveList = async (selectedSOData?: any) => {
		try {
			const requestParam = {
				pageIndex: Enums.ZERO,
				pageSize: Enums.MAX_USER_SIZE,
				sortingText: '',
				order: '',
				finder: [],
			};
			const response: any = await dispatch(getAllPerspective(requestParam));
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response.data;
				const { records } = entity || {};
				let list = records && records.length ? [...records] : [];
				if (selectedSOData) {
					const { perspectiveTags } = selectedSOData || {};
					if (perspectiveTags && perspectiveTags.length) {
						list = list?.map((record: any) => {
							const rec = { ...record };
							const isExist = perspectiveTags.find((item: any) => item?.perspectiveTagId === rec?.perspectiveTagId);
							if (isExist) {
								rec.perspectiveNameValue = true;
							} else {
								rec.perspectiveNameValue = false;
							}
							return rec;
						});
					}
				}
				setPerspectiveList(list);
			} else {
				setPerspectiveList([]);
			}
			setShowDrawerLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	const getObjectiveList = async (requestPayload: any, selectedTabName: string) => {
		try {
			const response: any = await dispatch(searchObjectiveForSO(requestPayload));
			if (response && response?.data && response?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response.data;

				const updatedEntityList =
					entityList && entityList.length
						? entityList.map((item: any) => ({
								...item,
								customDetails: { cycleId: requestPayload?.cycleId || 0, employeeId: requestPayload?.employeeId || 0 },
						  }))
						: [];

				if (selectedTabName === 'MyTab') {
					if (objectiveListMyTab && objectiveListMyTab.length) {
						const updatedObjList = removeDuplicateObjective(objectiveListMyTab, updatedEntityList);
						let updatedListForEdit = updatedObjList;
						// if (soDetailsDrawer && soDetailsDrawer?.type === 'Edit') {
						// 	if (linkedKREdit && linkedKREdit.length) {
						// 		updatedListForEdit = linkedAlreadyLinkKR(updatedListForEdit, linkedKREdit);
						// 	}
						// }
						setObjectiveListMyTab([...updatedListForEdit]);
					} else {
						if (updatedEntityList && updatedEntityList.length) {
							let updatedListForEdit = updatedEntityList;
							// if (soDetailsDrawer && soDetailsDrawer?.type === 'Edit') {
							// 	if (linkedKREdit && linkedKREdit.length) {
							// 		updatedListForEdit = linkedAlreadyLinkKR(updatedListForEdit, linkedKREdit);
							// 	}
							// }
							setObjectiveListMyTab([...updatedListForEdit]);
						} else {
							setObjectiveListMyTab([]);
						}
					}
				} else {
					if (objectiveListPeopleTab && objectiveListPeopleTab.length) {
						const updatedObjList = removeDuplicateObjective(objectiveListPeopleTab, updatedEntityList);
						let updatedListForEdit = updatedObjList;
						// if (soDetailsDrawer && soDetailsDrawer?.type === 'Edit') {
						// 	if (linkedKREdit && linkedKREdit.length) {
						// 		updatedListForEdit = linkedAlreadyLinkKR(updatedListForEdit, linkedKREdit);
						// 	}
						// }
						setObjectiveListPeopleTab([...updatedListForEdit]);
					} else {
						if (updatedEntityList && updatedEntityList.length) {
							let updatedListForEdit = updatedEntityList;
							// if (soDetailsDrawer && soDetailsDrawer?.type === 'Edit') {
							// 	if (linkedKREdit && linkedKREdit.length) {
							// 		updatedListForEdit = linkedAlreadyLinkKR(updatedListForEdit, linkedKREdit);
							// 	}
							// }
							setObjectiveListPeopleTab([...updatedListForEdit]);
						} else {
							setObjectiveListPeopleTab([]);
						}
					}
				}
			} else {
				//
			}
			setShowDrawerLoader(false);
		} catch (error) {
			console.error(error);
		}
	};
	/*
	const linkedAlreadyLinkKR = (completeList: any, linkedKRDetail: any) => {
		let mappedKR: any = [];
		const updatedCompleteList = [...completeList].map((objItem: any) => {
			const item = { ...objItem };
			if (item.linkedKrResponses && item.linkedKrResponses.length && linkedKRDetail && linkedKRDetail.length) {
				item.linkedKrResponses = item.linkedKrResponses.map((rec: any) => {
					const isExist = [...linkedKRDetail].find((itm: any) => itm.goalkeyId === rec.goalkeyId);
					if (isExist) {
						rec.isSelected = true;
						mappedKR.push({ ...isExist });
					}
					return rec;
				});
			}
			return item;
		});
		const pendingKR = removeDuplicateKR(linkedKRDetail, mappedKR);
		seLinkedKREdit([...pendingKR]);

		return updatedCompleteList;
	};
	const removeDuplicateKR = (currentList: any, newList: any) => {
		const arrayUniqueByKey: any = [];
		currentList.forEach((krItem: any) => {
			const item = { ...krItem };
			const isExist = newList.find((rec: any) => rec.goalkeyId === item.goalkeyId);
			if (!isExist) {
				arrayUniqueByKey.push({ ...item });
			}
		});

		return [...arrayUniqueByKey];
	};
	*/
	const removeDuplicateObjective = (currentList: any, newList: any) => {
		const key = 'goalObjectiveId';
		const combineList = [...newList, ...currentList];
		let arrayUniqueByKey: any = new Map(combineList.map((item: any) => [item[key], item])).values();
		arrayUniqueByKey = [...arrayUniqueByKey];

		return [...arrayUniqueByKey];
	};
	const getObjectiveListForPeopleSearch = (selectedCycleDetail: any, tabName: string, selectedEmployeeId: any) => {
		try {
			setShowDrawerLoader(true);
			const requestPayload = {
				employeeId: selectedEmployeeId || 0,
				soStartDate: soAttributeDetails.timeFrameStartDate
					? formatFeedbackDate(soAttributeDetails.timeFrameStartDate)
					: '',
				soEndDate: soAttributeDetails.timeFrameEndDate ? formatFeedbackDate(soAttributeDetails.timeFrameEndDate) : '',
				cycleId: selectedCycleDetail?.organisationCycleId || cycleId || 0,
			};
			getObjectiveList(requestPayload, tabName);
		} catch (error) {
			console.error(error);
		}
	};
	const getObjectiveListBasedOnCycleChange = (selectedCycleDetail: any, tabName: string) => {
		try {
			// console.log('getObjectiveListBasedOnCycleChange ===> ', tabName);
			setShowDrawerLoader(true);
			const requestPayload = {
				employeeId: userDetail?.employeeId || 0,
				soStartDate: soAttributeDetails.timeFrameStartDate
					? formatFeedbackDate(soAttributeDetails.timeFrameStartDate)
					: '',
				soEndDate: soAttributeDetails.timeFrameEndDate ? formatFeedbackDate(soAttributeDetails.timeFrameEndDate) : '',
				cycleId: selectedCycleDetail?.organisationCycleId || cycleId || 0,
			};
			// if (objectiveListMyTab && objectiveListMyTab.length && tabName === 'MyTab') {
			// Already have list
			// } else {
			getObjectiveList(requestPayload, tabName);
			// }
		} catch (error) {
			console.error(error);
		}
	};
	const handleDrawerOpen = (event: any) => {
		setShowDrawerLoader(true);
		getAllPerspectiveList();
		setSODetailsDrawer({ open: true, type: 'Add' });
		setExistingSoStatus(0);
		setDisableLinkOkr(false);
		setTotalSelectedCount(null);
	};
	const handleDrawerClose = (event: any, actionType: string) => {
		event.preventDefault();
		event.stopPropagation();
		if (actionType === 'save') {
			handleSaveClick();
		} else if (actionType === 'draft') {
			handleDraftClick();
		} else if (actionType === 'close' || actionType === 'cancel') {
			handleCancelClick();
		}
	};
	const handleSaveClick = async () => {
		if (soDetailsDrawer.type === 'Add') {
			const nextTabValue: any =
				soObjCreateTabSelected === 0 ? 1 : soObjCreateTabSelected === 1 ? 2 : soObjCreateTabSelected;
			nextTabValue === 1 && setShowDrawerLoader(true);
			if (soObjCreateTabSelected === 2) {
				const { okrList, krList } = getLinkedObjectiveAndKeyResultList(objectiveListMyTab, objectiveListPeopleTab);
				if ((okrList && okrList.length) || (krList && krList.length)) {
					callStrategicObjectives(okrList, krList, 'save', soAttributeDetails?.status || Enums.TWO);
				} else {
					enqueueSnackbar('Please link atleast one objective or key result', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				setSoObjCreateTabSelected(nextTabValue);
				setSearchSoTabSelected(0);
			}
		} else if (soDetailsDrawer.type === 'Edit') {
			const nextTabValue: any =
				soObjCreateTabSelected === 0 ? 1 : soObjCreateTabSelected === 1 ? 2 : soObjCreateTabSelected;
			nextTabValue === 1 && setShowDrawerLoader(true);
			if (soObjCreateTabSelected === 2) {
				const { okrList, krList } = getLinkedObjectiveAndKeyResultList(objectiveListMyTab, objectiveListPeopleTab);
				if ((okrList && okrList.length) || (krList && krList.length)) {
					callStrategicObjectives(okrList, krList, 'update', Enums.TWO);
				} else {
					enqueueSnackbar('Please link atleast one objective or key result', {
						variant: 'error',
						autoHideDuration: 3000,
					});
				}
			} else {
				setSoObjCreateTabSelected(nextTabValue);
				setSearchSoTabSelected(0);
			}
		}
	};
	const checkNewLinked = (item: any, type: string) => {
		if (type === 'OKR') {
			if (editableSoLinkedDetails) {
				const { getSoOkr } = editableSoLinkedDetails;
				const isExist =
					getSoOkr && getSoOkr.length
						? getSoOkr.find((rec: any) => rec?.goalObjectiveId === item?.goalObjectiveId)
						: null;
				return isExist ? false : true;
			} else {
				return true;
			}
		} else if (type === 'KR') {
			if (editableSoLinkedDetails) {
				const { linkedIndividualKrResponses } = editableSoLinkedDetails;
				let isExist = null;

				if (linkedIndividualKrResponses && linkedIndividualKrResponses.length) {
					linkedIndividualKrResponses.forEach((objItem: any) => {
						const obj = { ...objItem };
						const isKRSelected = obj?.linkedKrResponses.find(
							(rec: any) => rec.isSelected && rec?.goalkeyId === item?.goalkeyId
						);
						if (isKRSelected) {
							isExist = isKRSelected;
						}
					});
					// linkedKrResponses.find((rec: any) => rec?.goalkeyId === item?.goalkeyId)
				}
				return isExist ? false : true;
			} else {
				return true;
			}
		}
	};
	const getGoalMappingList = (list: any, type: string, soStatus: any) => {
		let refinedList: any[] = [];
		if (type === 'OKR') {
			list &&
				list.length &&
				list.forEach((item: any) => {
					if (item.isSelected) {
						refinedList.push({
							goalTypeId: Enums.ONE,
							goalId: item?.goalObjectiveId || 0,
							employeeId: item?.customDetails?.employeeId || 0,
							isNewlyAdded:
								existingSoStatus !== soStatus || existingSoStatus === Enums.ONE ? true : checkNewLinked(item, type),
						});
					}
				});
		} else if (type === 'KR') {
			list &&
				list.length &&
				list.forEach((item: any) => {
					if (item.isSelected) {
						refinedList.push({
							goalTypeId: Enums.TWO,
							goalId: item?.goalkeyId || 0,
							employeeId: item?.employeeId || item?.customDetails?.employeeId || 0,
							isNewlyAdded:
								existingSoStatus !== soStatus || existingSoStatus === Enums.ONE ? true : checkNewLinked(item, type),
						});
					}
				});
		}
		return [...refinedList];
	};
	const getLinkedObjectiveAndKeyResultList = (myOkrList: any, peopleOkrList: any) => {
		try {
			let completeList: any = [];
			if (myOkrList && myOkrList.length) {
				completeList = [...completeList, ...myOkrList];
			}
			if (peopleOkrList && peopleOkrList.length) {
				completeList = [...completeList, ...peopleOkrList];
			}
			const selectedIndividualKRList = getSelectedIndividualKRList(
				completeList && completeList.length ? [...completeList] : []
			);
			const filterData =
				completeList && completeList.length ? [...completeList].filter((item: any) => item?.isSelected) : [];

			return { okrList: filterData, krList: selectedIndividualKRList };
		} catch (error) {
			console.error(error);
			return { okrList: [], krList: [] };
		}
	};
	const getSelectedIndividualKRList = (list: any) => {
		let selectedKRList: any = [];
		list.forEach((item: any) => {
			if (!item?.isSelected) {
				const krItems =
					item?.linkedKrResponses && item?.linkedKrResponses.length
						? item?.linkedKrResponses
								.filter((kr: any) => kr?.isSelected)
								.map((record: any) => {
									return { ...record, okrDetails: item };
								})
						: [];
				selectedKRList = [...selectedKRList, ...krItems];
			}
		});

		return selectedKRList;
	};
	const handleDraftClick = () => {
		if (soDetailsDrawer.type === 'Edit') {
			const { okrList, krList } = getLinkedObjectiveAndKeyResultList(objectiveListMyTab, objectiveListPeopleTab);
			if ((okrList && okrList.length) || (krList && krList.length)) {
				callStrategicObjectives(okrList, krList, 'update', soAttributeDetails?.status || Enums.TWO);
			} else if (soAttributeDetails?.status === Enums.ONE) {
				callStrategicObjectives(okrList, krList, 'update', soAttributeDetails?.status || Enums.TWO);
			} else {
				enqueueSnackbar('Please link atleast one objective or key result', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		} else {
			const { okrList, krList } = getLinkedObjectiveAndKeyResultList(objectiveListMyTab, objectiveListPeopleTab);
			callStrategicObjectives(okrList, krList, 'draft', Enums.ONE);
		}
	};
	const handleCancelClick = () => {
		if (isDetailsEdited) {
			setModalProps({ open: true, message: t('taskUnsavedChanges'), details: '', type: 'unSaved' });
		} else {
			hideResetAndAttributeDrawer();
		}
	};
	const hideResetAndAttributeDrawer = () => {
		setSoObjCreateTabSelected(0);
		setSearchSoTabSelected(0);
		setSelectedDateRange(null);
		setSOAttributeDetails(staticFormDetails);
		setSODetailsDrawer({ open: false, type: '' });
		setExistingSoStatus(0);
		setObjectiveListMyTab(null);
		setObjectiveListPeopleTab(null);
		setIsDetailsEdited(false);
	};
	const handlePerformanceCloseModal = async (e: any, type: any) => {
		try {
			e.preventDefault();
			if (type === 1) {
				if (modalProps?.type === 'unSaved') {
					hideResetAndAttributeDrawer();
					setModalProps({ open: false, message: '', details: '', type: '' });
					setIsDetailsEdited(false);
				} else if (modalProps?.type === 'timeFrameChange') {
					const { range, startDate, endDate, soTimeframeId } = modalProps?.details;
					range && !soTimeframeId ? setSelectedDateRange(range) : setSelectedDateRange(null);
					setSOAttributeDetails({
						...soAttributeDetails,
						timeFrameStartDate: startDate,
						timeFrameEndDate: endDate,
						soTimeframeId: soTimeframeId || 0,
					});

					setObjectiveListMyTab([]);
					setObjectiveListPeopleTab([]);
					setTotalSelectedCount(null);

					setModalProps({ open: false, message: '', details: '', type: '' });
				} else if (modalProps?.type === 'editTimeFrame') {
					editTimeFrameAPICall(modalProps?.details || {});
				}
			} else {
				setModalProps({ open: false, message: '', details: '' });
			}
		} catch (error) {
			console.error(error);
			setModalProps({ open: false, message: '', details: '' });
		}
	};
	const callStrategicObjectives = async (okrList: any, krList: any, saveType: string, soStatus?: any) => {
		try {
			setShowDrawerLoader(true);
			const soPerspectiveMappingRequests: any[] = [];
			perspectiveList &&
				perspectiveList.length &&
				perspectiveList.forEach((rec: any) => {
					if (rec?.perspectiveNameValue) {
						soPerspectiveMappingRequests.push({
							perspectiveTagId: rec?.perspectiveTagId,
						});
					}
				});
			const requestPayload = {
				soDetailsId: soAttributeDetails?.soDetailsId || 0,
				soTimeframeId: soAttributeDetails?.soTimeframeId || 0,
				timeFrameStartDate: soAttributeDetails?.timeFrameStartDate
					? formatFeedbackDate(soAttributeDetails?.timeFrameStartDate)
					: '',
				timeFrameEndDate: soAttributeDetails?.timeFrameEndDate
					? formatFeedbackDate(soAttributeDetails?.timeFrameEndDate)
					: '',
				status: soStatus ? soStatus : Enums.ONE,
				soName: soAttributeDetails?.strategicObjectiveName || '',
				score: soAttributeDetails?.score || 0,
				soPerspectiveMappingRequests: [...soPerspectiveMappingRequests],
				soGoalMappingRequests: [
					...getGoalMappingList(okrList, 'OKR', soStatus),
					...getGoalMappingList(krList, 'KR', soStatus),
				],
			};
			let response: any = null;
			if (saveType === 'update') {
				response = await dispatch(updateSo(requestPayload));
			} else {
				response = await dispatch(createSo(requestPayload));
			}

			if (response && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(
					messages || `Strategic Objective ${saveType === 'update' ? 'updated' : 'created'} successfully`,
					{
						variant: 'success',
						autoHideDuration: 3000,
					}
				);

				setSOAttributeDetails({ ...staticFormDetails });
				setObjectiveListMyTab(null);
				setObjectiveListPeopleTab(null);
				setPerspectiveList(null);

				setSoObjCreateTabSelected(0);
				setSearchSoTabSelected(0);
				setSODetailsDrawer({ open: false, type: '' });
				setExistingSoStatus(0);
				setIsDetailsEdited(false);

				getSoOkrList(0, 'direct');
				getSoTimeFrameList('direct');
			} else {
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(messages || `Error occurred while ${saveType === 'update' ? 'updating' : 'creating'} SO`, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setIsDetailsEdited(false);
			setShowDrawerLoader(false);
		} catch (error) {
			console.error(error);
			setIsDetailsEdited(false);
			setShowDrawerLoader(false);
		}
	};
	const handleTimeFrameSelection = (range: any, start: any, end: any) => {
		if (start && end) {
			if (soAttributeDetails?.timeFrameStartDate && soAttributeDetails?.timeFrameEndDate && totalSelectedCount) {
				const details = {
					range: range,
					startDate: start,
					endDate: end,
					soTimeframeId: 0,
				};
				setModalProps({
					open: true,
					message: `${t('timeFrameChange')}<br /> ${t('soDeleteMsg2')}`,
					details: details,
					type: 'timeFrameChange',
				});
			} else {
				range && setSelectedDateRange(range);
				setSOAttributeDetails({
					...soAttributeDetails,
					timeFrameStartDate: start,
					timeFrameEndDate: end,
					soTimeframeId: 0,
				});
			}
		}
		setIsDetailsEdited(true);
	};
	const handleTimeFrameChangeFromDrawer = (item: any, event: any) => {
		const { startDate, endDate, soTimeframeId } = item;
		if (startDate && endDate) {
			if (soAttributeDetails?.timeFrameStartDate && soAttributeDetails?.timeFrameEndDate && totalSelectedCount) {
				const range = startDate && endDate ? moment().range(new Date(startDate), new Date(endDate)) : null;
				const details = {
					range: range,
					startDate: startDate,
					endDate: endDate,
					soTimeframeId: soTimeframeId | 0,
				};
				setModalProps({
					open: true,
					message: `${t('timeFrameChange')}<br /> ${t('soDeleteMsg2')}`,
					details: details,
					type: 'timeFrameChange',
				});
			} else {
				// const range = startDate && endDate ? moment().range(new Date(startDate), new Date(endDate)) : null;
				setSelectedDateRange(null);
				setSOAttributeDetails({
					...soAttributeDetails,
					timeFrameStartDate: startDate,
					timeFrameEndDate: endDate,
					soTimeframeId: soTimeframeId || 0,
				});
			}
		}
		setIsDetailsEdited(true);
	};
	const handleSONameChange = (event: any) => {
		try {
			const { name, value } = event.target;
			setSOAttributeDetails({ ...soAttributeDetails, [name]: value });
			setIsDetailsEdited(true);
		} catch (error) {
			console.error(error);
		}
	};
	const handlePerspectiveOfSOChange = (event: any, rec: any) => {
		try {
			const { checked } = event.target;
			let updatedPerspectiveList = [...perspectiveList];
			updatedPerspectiveList = [...updatedPerspectiveList].map((item: any) => {
				if (item.perspectiveTagId === rec.perspectiveTagId) {
					item.perspectiveNameValue = checked;
				}
				return item;
			});
			setPerspectiveList(updatedPerspectiveList);
			setIsDetailsEdited(true);
		} catch (error) {
			console.error(error);
		}
	};
	const getSoOkrList = async (soDetailId: number, type: string) => {
		try {
			setLoader(true);
			const queryParams = `timeFrameId=${
				type === 'redirect' ? 1 : selectedTimeFrame ? selectedTimeFrame?.soTimeframeId : 0
			}&pageIndex=${Enums.ONE}&pageSize=${Enums.MAX_USER_SIZE}&soDetailsId=${soDetailId || 0}`;
			const response: any = await dispatch(getAllSoOkrList(queryParams));
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entity } = response.data || {};
				const {
					soResponses,
					calculationTechnique,
					isCreatorRights,
					isSoProgressVisibilityRights,
					isSoVisibilityRights,
				} = entity || 0;
				if (type === 'redirect') {
					if (soResponses && soResponses.length) {
						clickSOAttributeClick(null, soResponses[0], type);
					} else {
						enqueueSnackbar('Error occurred while fetching SO Details', {
							variant: 'error',
							autoHideDuration: 3000,
						});
					}
				} else {
					setSoList(soResponses && soResponses.length ? soResponses : []);
					setSoPermission({
						calculationTechnique: calculationTechnique || 0,
						isCreatorRights: isCreatorRights || false,
						isSoProgressVisibilityRights: isSoProgressVisibilityRights || false,
						isSoVisibilityRights: isSoVisibilityRights || false,
					});
				}
			} else {
				setSoList([]);
				setSoPermission({
					calculationTechnique: 0,
					isCreatorRights: false,
					isSoProgressVisibilityRights: false,
					isSoVisibilityRights: false,
				});
				const { messageList } = response.data;
				const keys = Object.keys(messageList);
				const messages = keys.map((item) => messageList[item]);
				enqueueSnackbar(messages || 'Error occurred while fetching SO', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const getSoTimeFrameList = async (callType = '') => {
		try {
			const response: any = await dispatch(getAllSoTimeFrame());
			if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
				const { entityList } = response?.data || {};
				if (entityList) {
					setSoOkrTimeFrameList(entityList);
					const currentTimeFrame =
						entityList && entityList.length > 0 ? entityList.find((item: any) => item.isSelectedTimeFrame) : {};
					if (callType === 'direct' && selectedTimeFrame && selectedTimeFrame?.soTimeframeId) {
						//
					} else {
						setSelectedTimeFrame(currentTimeFrame);
					}
					if (entityList && entityList.length > 0 && entityList.find((item: any) => item.status === 1)) {
						setShowPastTimeFrameLink(true);
					} else {
						setShowPastTimeFrameLink(false);
					}
				} else {
					setSoOkrTimeFrameList([]);
					setSelectedTimeFrame({});
				}
			} else {
				setSoOkrTimeFrameList([]);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleTimeFrameChange = (timeFrame: any, event: any) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			setSelectedTimeFrame(timeFrame);
		} catch (error) {
			console.info(error);
		}
	};
	const clickSOAttributeClick = (event: any, selectedSODetail: any, type?: string | '') => {
		try {
			if (event) {
				event.preventDefault();
				event.stopPropagation();
			}
			setTotalSelectedCount(null);
			setShowDrawerLoader(true);
			getAllPerspectiveList(selectedSODetail);
			getSOLinkedOKRListDetails(selectedSODetail?.soDetailsId || 0);
			const { startDate, endDate, soTimeframeId } = selectedTimeFrame || {};
			setSOAttributeDetails({
				soDetailsId: selectedSODetail?.soDetailsId || 0,
				soTimeframeId: selectedSODetail?.soTimeframeId || soTimeframeId || 0,
				strategicObjectiveName: selectedSODetail?.soName || '',
				timeFrameStartDate: startDate || '',
				timeFrameEndDate: endDate || '',
				perspectiveTags:
					selectedSODetail?.perspectiveTags && selectedSODetail?.perspectiveTags.length
						? selectedSODetail?.perspectiveTags?.filter((item: any) => !item?.isDeleted)
						: [],
				score: selectedSODetail?.score || 0,
				status: selectedSODetail?.status || 0,
			});
			setExistingSoStatus(selectedSODetail?.status || 0);
			// if (selectedSODetail === Enums.GOAL_STATUS_DRAFT) {
			// }

			setSODetailsDrawer({ open: true, type: 'Edit' });
			if (type === 'redirect') {
				setTimeout(() => {
					setSoObjCreateTabSelected(2);
				}, 2000);
			}
		} catch (error) {
			console.info(error);
		}
	};
	const getSOLinkedOKRListDetails = async (soDetailsId: any) => {
		try {
			if (soDetailsId) {
				setShowDrawerLoader(true);
				const requestParam = `soDetailsId=${soDetailsId}`;
				const response: any = await dispatch(getSOLinkedOKRList(requestParam));
				if (response && response?.data && response.status === Enums.STATUS_SUCCESS) {
					const { entity } = response?.data || {};
					const { linkedIndividualKrResponses, getSoOkr } = entity || {};
					setEditableSoLinkedDetails({
						linkedIndividualKrResponses: linkedIndividualKrResponses ? linkedIndividualKrResponses : [],
						getSoOkr: getSoOkr ? getSoOkr : [],
					});
				}
				setShowDrawerLoader(false);
			} else {
				setObjectiveListMyTab([]);
				setObjectiveListPeopleTab([]);
				setEditableSoLinkedDetails(null);
				// enqueueSnackbar('Error occurred while fetching linked objective', {
				// 	variant: 'error',
				// 	autoHideDuration: 3000,
				// });
				setShowDrawerLoader(false);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const deleteSOAttributeClick = (event: any, selectedSODetail: any) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			if (selectedSODetail?.soDetailsId) {
				setDeleteModalProps({
					open: true,
					message: {
						msg1: t('soDeleteMsg1'),
						msg2: t('soDeleteMsg2'),
					},
					details: selectedSODetail,
					type: 'SO',
				});
			}
		} catch (error) {
			console.info(error);
		}
	};
	const handleCloseModal = async (e: any, type: any) => {
		if (type === 1) {
			if (deleteModalProps?.type === 'SO') {
				callDeleteSOAPI();
			} else if (deleteModalProps?.type === 'TimeFrame') {
				callDeleteTimeFrameAPI();
			}
		} else {
			setDeleteModalProps({ open: false, message: '', details: '', type: '' });
		}
	};
	const callDeleteSOAPI = async () => {
		if (deleteModalProps?.details && deleteModalProps?.details?.soDetailsId) {
			setLoader(true);
			const requestParam = `soDetailsId=${deleteModalProps?.details?.soDetailsId}`;
			const response: any = await dispatch(deleteSo(requestParam));

			if (response && response?.data && response.data.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response.data;
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys.map((item: any) => messageList[item]);
				enqueueSnackbar(messages || 'Strategic Objectives deleted successfully', {
					variant: 'success',
					autoHideDuration: 3000,
				});

				getSoOkrList(0, 'direct');
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys.map((item: any) => messageList[item]);
				enqueueSnackbar(messages || 'Error occurred while deleting Strategic Objectives', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
			setDeleteModalProps({ open: false, message: '', details: '', type: '' });
		}
	};
	const callDeleteTimeFrameAPI = async () => {
		if (deleteModalProps?.details && deleteModalProps?.details?.soTimeframeId) {
			setLoader(true);
			const requestParam = `soTimeframeId=${deleteModalProps?.details?.soTimeframeId}`;
			const response: any = await dispatch(deleteSoTimeFrame(requestParam));

			if (response && response?.data && response.data.status === Enums.STATUS_SUCCESS) {
				const { messageList } = response.data;
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys.map((item: any) => messageList[item]);
				enqueueSnackbar(messages || 'Timeframe deleted successfully', {
					variant: 'success',
					autoHideDuration: 3000,
				});

				getSoTimeFrameList();
			} else {
				const { messageList } = response?.data || {};
				const keys = messageList ? Object.keys(messageList) : [];
				const messages = keys.map((item: any) => messageList[item]);
				enqueueSnackbar(messages || 'Error occurred while deleting Timeframe', {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
			setLoader(false);
			setDeleteModalProps({ open: false, message: '', details: '', type: '' });
		}
	};
	const handleTimeFrameEditClick = (event: any, selectedTimeFrameDetail: any) => {
		try {
			event.preventDefault();
			event.stopPropagation();
		} catch (error) {
			console.info(error);
		}
	};
	const handleEditTieFrameDateRangeSelection = (range: any, start: any, end: any, selectedTimeFrameDetail: any) => {
		if (start && end) {
			const details = {
				range: range,
				startDate: start,
				endDate: end,
				soTimeframeId: selectedTimeFrameDetail?.soTimeframeId || 0,
			};
			setModalProps({
				open: true,
				message: `All the OKRs falling outside the timeframe will be unlinked and strategic objectives without any OKRs linked will be moved to draft state! Are you sure you want to proceed?`,
				details: details,
				type: 'editTimeFrame',
			});
		}
	};
	const editTimeFrameAPICall = async (record: any) => {
		try {
			if (record?.soTimeframeId && record?.startDate && record?.endDate) {
				setLoader(true);
				const requestPayload = {
					soTimeframeId: record?.soTimeframeId || 0,
					timeFrameStartDate: formatOneOnOneRequestDate(record?.startDate),
					timeFrameEndDate: formatOneOnOneRequestDate(record?.endDate),
				};
				setModalProps({ open: false, message: '', details: '' });
				const response: any = await dispatch(editSoTimeFrame(requestPayload));
				if (response && response?.data && response?.data?.status === Enums.STATUS_SUCCESS) {
					const { entity, messageList } = response?.data || {};
					if (entity) {
						const keys = messageList ? Object.keys(messageList) : [];
						const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
						enqueueSnackbar(messages || 'Timeframe updated successfully', {
							variant: 'success',
							autoHideDuration: 3000,
						});
						if (soPermissionDetails && soPermissionDetails?.isSoVisibilityRights) {
							getSoTimeFrameList();
						}
						setIsEditTimeFrameCalenderVisible(true);
						setSelectedEditTimeFrameDateRange(null);
						setLoader(false);
					} else {
						setShowDialog({
							open: true,
							type: 'NoTimeframeChange',
							message:
								'A timeline already exists for the new dates selected, hence editing this timeline to the same date is not possible! You can select that timeframe from the dropdown and create the  Strategic Objective in that existing timeline instead.',
							isCancel: true,
							tagData: null,
						});
					}
				} else {
					const { messageList } = response?.data || {};
					const keys = messageList ? Object.keys(messageList) : [];
					const messages = keys && keys.length ? keys.map((item) => messageList[item]) : '';
					enqueueSnackbar(messages || `Error occurred while updating timeframe`, {
						variant: 'error',
						autoHideDuration: 3000,
					});
					setIsEditTimeFrameCalenderVisible(true);
					setSelectedEditTimeFrameDateRange(null);
					setLoader(false);
				}
			}
		} catch (error) {
			console.error(error);
			setLoader(false);
		}
	};
	const handleTimeFrameDeleteClick = (event: any, selectedTimeFrameDetail: any) => {
		try {
			event.preventDefault();
			event.stopPropagation();
			// console.log('handleTimeFrameDeleteClick ===> ', selectedTimeFrameDetail);
			if (selectedTimeFrameDetail?.soTimeframeId) {
				setDeleteModalProps({
					open: true,
					message: {
						msg1: t('soTimeFrameDeleteMsg1'),
						msg2: t('soTimeFrameDeleteMsg2'),
					},
					details: selectedTimeFrameDetail,
					type: 'TimeFrame',
				});
			}
		} catch (error) {
			console.info(error);
		}
	};
	const handleTimeframeChangeFailCloseModal = (e: any, type: number) => {
		if (type === 1) {
			setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
		} else {
			setShowDialog({ open: false, message: '', isCancel: true, tagData: null, type: '' });
		}
		setIsEditTimeFrameCalenderVisible(true);
		setSelectedEditTimeFrameDateRange(null);
		setLoader(false);
	};

	return (
		<>
			{loader && (
				<Box className='loader-wrap'>
					<LinearLoader data={true} />
				</Box>
			)}
			<Box
				className={`strategic-obj-wrap ${
					missionVisionDetails && soPermissionDetails && soPermissionDetails?.isMissionVissionAdded
						? ''
						: 'no-mission-vision-wrap'
				} ${soPermissionDetails && soPermissionDetails?.isSoVisibilityRights ? '' : 'so-mv-fullscreen-area'}`}
			>
				<Box className='back-to-main pad-container'>
					<Typography variant='body2'>
						<Link id='back-btn' onClick={handleBackButtonOnClick}>
							<BackArrow /> {t('back')}
						</Link>
						{soPermissionDetails && soPermissionDetails?.isSoVisibilityRights
							? t('watchingOrgStrategicObj')
							: soPermissionDetails && soPermissionDetails?.isMissionVissionAdded
							? t('soMessageWithBackButton')
							: ''}
					</Typography>
				</Box>
				<Box className='so-mv-area pad-container'>
					{missionVisionDetails && soPermissionDetails && soPermissionDetails?.isMissionVissionAdded && (
						<Box>
							{missionVisionDetails?.missionDescription ? (
								<Box className='so-mv-tile'>
									<Box className='so-mv-tile-head'>
										<Box className='so-mv-icon'>
											<MissionIcon />
										</Box>
										<Typography>{t('missionLabel')}</Typography>
									</Box>
									<Box className='so-mv-tile-content long-text'>
										<Typography
											variant='body1'
											dangerouslySetInnerHTML={{ __html: missionVisionDetails?.missionDescription }}
										></Typography>
									</Box>
								</Box>
							) : (
								<></>
							)}
							{missionVisionDetails?.visionDescription ? (
								<Box className='so-mv-tile'>
									<Box className='so-mv-tile-head'>
										<Box className='so-mv-icon'>
											<VisionIcon />
										</Box>
										<Typography>{t('visionLabel')}</Typography>
									</Box>
									<Box className='so-mv-tile-content long-text'>
										<Typography
											variant='body1'
											dangerouslySetInnerHTML={{ __html: missionVisionDetails?.visionDescription }}
										></Typography>
									</Box>
								</Box>
							) : (
								<></>
							)}
						</Box>
					)}
				</Box>
				{soPermissionDetails && soPermissionDetails?.isSoVisibilityRights ? (
					<>
						<Box className='so-obj-list-head okr-listing-head pad-container'>
							<Box className='okr-listing-head-left'>
								<Typography className='sort-obj-title' variant='h2'>
									{t('strategicObjLabel')}{' '}
									{selectedTimeFrame && (
										<>
											({getMonthDateYear(selectedTimeFrame?.startDate)} - {getMonthDateYear(selectedTimeFrame?.endDate)}
											)
										</>
									)}
								</Typography>
								{soOkrTimeframeList &&
								soOkrTimeframeList.length > 1 &&
								soPermissionDetails &&
								soPermissionDetails?.isSoVisibilityRights ? (
									<TimeFrameDropdown
										{...props}
										startDateValue={startDateValue}
										maxDateValue={maxDateValue}
										isCalenderVisible={false}
										soOkrTimeframeList={soOkrTimeframeList}
										setSelectedTimeFrame={setSelectedTimeFrame}
										selectedTimeFrame={selectedTimeFrame}
										showPastTimeFrameLink={showPastTimeFrameLink}
										handleTimeFrameChange={handleTimeFrameChange}
										handleTimeFrameDeleteClick={handleTimeFrameDeleteClick}
										isTimeFrameEdit={true}
										isTimeFrameDelete={true}
										soPermissionDetails={soPermissionDetails}
										handleTimeFrameEditClick={handleTimeFrameEditClick}
										isEditTimeFrameCalenderVisible={isEditTimeFrameCalenderVisible}
										setIsEditTimeFrameCalenderVisible={setIsEditTimeFrameCalenderVisible}
										handleEditTieFrameDateRangeSelection={handleEditTieFrameDateRangeSelection}
										selectedEditTimeFrameDateRange={selectedEditTimeFrameDateRange}
										setSelectedEditTimeFrameDateRange={setSelectedEditTimeFrameDateRange}
									/>
								) : (
									<></>
								)}
							</Box>
							{soPermissionDetails &&
							soPermissionDetails?.isSoVisibilityRights &&
							soPermissionDetails?.isCreatorRights ? (
								<Box className='okr-listing-head-right'>
									<Tooltip
										classes={{ popper: 'add-button-tooltip' }}
										title={`${t('createStrategicObjLabel')}`}
										arrow
										placement='bottom'
									>
										<Button className={'add-btn'} id='so-create-okr-btn' onClick={handleDrawerOpen}>
											<AddIconSvg />
										</Button>
									</Tooltip>
								</Box>
							) : (
								<></>
							)}
						</Box>
						<Box className='so-obj-listing pad-container'>
							{soList && soList.length && soPermissionDetails && soPermissionDetails?.isSoVisibilityRights ? (
								<SoListing
									{...props}
									soList={soList}
									clickSOAttributeClick={clickSOAttributeClick}
									userDetail={userDetail}
									soPermission={soPermission}
									deleteSOAttributeClick={deleteSOAttributeClick}
									getSoOkrList={getSoOkrList}
									soPermissionDetails={soPermissionDetails}
								/>
							) : soList && soList.length === 0 ? (
								<SoOkrNotCreated />
							) : (
								<Box className='loading-center-global'>
									<Typography>Loading...</Typography>
								</Box>
							)}
						</Box>
					</>
				) : (
					<></>
				)}
				{soDetailsDrawer && soDetailsDrawer.open ? (
					<MainDrawer
						open={soDetailsDrawer?.open || false}
						drawerClassName={'main-drawer-panel so-create-drawer'}
						transitionDuration={{ enter: 500, exit: 1000 }}
						headerTitle={t('strategicObjLabel')}
						loader={showDrawerLoader}
						children={
							<Box className='drawer-inner-content'>
								<SoCreate
									soDetailsDrawer={soDetailsDrawer}
									startDateValue={startDateValue}
									maxDateValue={maxDateValue}
									selectedDateRange={selectedDateRange}
									setSelectedDateRange={setSelectedDateRange}
									soAttributeDetails={soAttributeDetails}
									handleTimeFrameSelection={handleTimeFrameSelection}
									handleSONameChange={handleSONameChange}
									handlePerspectiveOfSOChange={handlePerspectiveOfSOChange}
									perspectiveList={perspectiveList}
									checkAttributesValidation={checkAttributesValidation}
									soObjCreateTabSelected={soObjCreateTabSelected}
									setSoObjCreateTabSelected={setSoObjCreateTabSelected}
									searchSoTabSelected={searchSoTabSelected}
									setSearchSoTabSelected={setSearchSoTabSelected}
									cycleId={cycleId}
									cycleDetail={cycleDetail}
									currentCycleYear={currentCycleYear}
									cycleDuration={cycleDuration}
									getObjectiveListBasedOnCycleChange={getObjectiveListBasedOnCycleChange}
									objectiveListMyTab={objectiveListMyTab}
									setObjectiveListMyTab={setObjectiveListMyTab}
									objectiveListPeopleTab={objectiveListPeopleTab}
									setObjectiveListPeopleTab={setObjectiveListPeopleTab}
									okrMasterData={okrMasterData}
									getObjectiveListForPeopleSearch={getObjectiveListForPeopleSearch}
									soOkrTimeframeList={soOkrTimeframeList}
									setSelectedTimeFrame={setSelectedTimeFrame}
									selectedTimeFrame={
										dashboardCurrentTabForSORedirectionSoDetailsId &&
										dashboardCurrentTabForSORedirectionSoDetailsId?.timeFrameId
											? { soTimeframeId: dashboardCurrentTabForSORedirectionSoDetailsId?.timeFrameId }
											: selectedTimeFrame
									}
									showPastTimeFrameLink={showPastTimeFrameLink}
									handleTimeFrameChange={handleTimeFrameChange}
									handleTimeFrameChangeFromDrawer={handleTimeFrameChangeFromDrawer}
									isDetailsEdited={isDetailsEdited}
									setIsDetailsEdited={setIsDetailsEdited}
									disableLinkOkr={disableLinkOkr}
									totalSelectedCount={totalSelectedCount}
									soPermissionDetails={soPermissionDetails}
								/>
							</Box>
						}
						rightCloseBtn={false}
						isSaveButtonVisible={soPermissionDetails?.isCreatorRights ? true : false}
						isSaveButtonDisabled={
							checkAttributesValidation('save')
								? checkAttributesValidation('save')
								: soObjCreateTabSelected === 1
								? !disableLinkOkr
								: false
						}
						saveButtonText={soObjCreateTabSelected !== 2 ? 'Next' : 'Submit'}
						isSaveDraft={
							soObjCreateTabSelected === 2 &&
							soDetailsDrawer?.type === 'Edit' &&
							soAttributeDetails?.status !== Enums.GOAL_STATUS_DRAFT
								? false
								: soPermissionDetails?.isCreatorRights
								? true
								: false
						}
						isDraftButtonDisabled={checkAttributesValidation('draft')}
						draftButtonText={
							soDetailsDrawer?.type === 'Add' ||
							(soAttributeDetails?.status === Enums.GOAL_STATUS_DRAFT && soDetailsDrawer?.type === 'Edit')
								? 'Save as Draft'
								: 'Save SO'
						}
						isShowSeparator={
							soObjCreateTabSelected === 2 &&
							soDetailsDrawer?.type === 'Edit' &&
							soAttributeDetails?.status !== Enums.GOAL_STATUS_DRAFT
								? false
								: soPermissionDetails?.isCreatorRights
								? true
								: false
						}
						separatorContent={'or'}
						handleDrawerClose={(event: any) => handleDrawerClose(event, 'close')}
						handleSaveClick={(event: any) => handleDrawerClose(event, 'save')}
						handleCancelClick={(event: any) => handleDrawerClose(event, 'cancel')}
						handleDraftButtonClick={(event: any) => handleDrawerClose(event, 'draft')}
					/>
				) : (
					<></>
				)}
			</Box>
			{modalProps && modalProps.open && (
				<AlertDialog
					module='PerformanceReviewList'
					isCancel={false}
					message={modalProps?.message || ''}
					handleCloseModal={handlePerformanceCloseModal}
					modalOpen={modalProps.open}
				/>
			)}

			{showDialog && showDialog?.open ? (
				<AlertDialog
					message={showDialog?.message || ''}
					handleCloseModal={handleTimeframeChangeFailCloseModal}
					modalOpen={showDialog?.open}
					isCancel={showDialog?.isCancel}
					module={'soPerspective'}
				/>
			) : (
				<></>
			)}
			{deleteModalProps && deleteModalProps.open && (
				<DialogComponent
					module='confirmation'
					modalOpen={deleteModalProps.open}
					handleCloseModal={(e: any) => handleCloseModal(e, 2)}
					matchString={Enums.DELETE_TEXT}
					handleConfirmClick={(e: any) => handleCloseModal(e, 1)}
					headingText={deleteModalProps?.message?.msg1 || t('soDeleteMsg1')}
					subHeading={deleteModalProps?.message?.msg2 || t('soDeleteMsg2')}
				/>
			)}
		</>
	);
};
